import React,{useState,useEffect} from 'react'
import {Row,Col,Modal,Form,Button} from 'react-bootstrap'
import {Link,useHistory} from 'react-router-dom'
import Swal from 'sweetalert2'
import Card from '../../../components/Card'
import '../../../index.css'
import {bindActionCreators} from "redux"
import axios from 'axios'
import DataTable from 'react-data-table-component';

// store
import {getcustomizerprimaryMode, getDarkMode} from '../../../store/setting/setting'
import {connect} from "react-redux"

const mapStateToProps = (state) => {
   return {
       darkMode: getDarkMode(state),
       colorprimarymode: getcustomizerprimaryMode(state),
   };
}

const LogsActivity = (props) => {
   
    const [detail, modalDetail] = useState(false);
    const [data, setData] = useState('');
    const [APIData, setAPIData] = useState([]);
    const token = localStorage.getItem("token");
    const [pending, setPending] = useState(true);

    const [rows, setRows] = useState([
        { nomor: '', user: '', modul: '', activity: '', activity_date: ''}
    ])
    const [backup, setBackup] = useState([
        { nomor: '', user: '', modul: '', activity: '', activity_date: ''}
    ])

    const columns = [
        {
            name: 'No.',
            selector: row => row['nomor'],
            sortable: true,
        },
        {
            name: 'User',
            selector: row => row['user'],
            sortable: true,
        },
        {
            name: 'Modul',
            selector: row => row['modul'],
            sortable: true,
        },
        {
            name: 'Activity',
            selector: row => row['activity'],
            sortable: true,
        },
        {
            name: 'Activity Date',
            selector: row => row['activity_date'],
            sortable: true,
        },
        {
            name: 'Aksi',
            button: true,
            cell: row => (
                <span onClick={() => lihatDetail(row)} className="btn btn-sm btn-icon text-info" data-bs-toggle="tooltip" title="Lihat Detail">
                    <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.1614 12.0531C15.1614 13.7991 13.7454 15.2141 11.9994 15.2141C10.2534 15.2141 8.83838 13.7991 8.83838 12.0531C8.83838 10.3061 10.2534 8.89111 11.9994 8.89111C13.7454 8.89111 15.1614 10.3061 15.1614 12.0531Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M11.998 19.355C15.806 19.355 19.289 16.617 21.25 12.053C19.289 7.48898 15.806 4.75098 11.998 4.75098H12.002C8.194 4.75098 4.711 7.48898 2.75 12.053C4.711 16.617 8.194 19.355 12.002 19.355H11.998Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                </span>
            )
        },
    ];

    useEffect(() => {
        // Get Data
        getData();
    }, []);
    
    // Get Data
    const getData = async (e) => {
        const res = await axios.get('https://api.alazhar2.com/node/tools/log', { headers: {"Authorization" : `Bearer ${token}`}});
        const query = res.data.data.map((item,index) => ({...item, 
            nomor: index+1+".", 
            user: item.first_name + " " + item.last_name, 
            modul: item.modul.replace("|", " - "),
            activity: item.action,
            activity_date: item.createdtime
        }));
        setRows(query);
        setBackup(query);
        // const timeout = setTimeout(() => {
		// 	setRows(query);
		// 	setPending(false);
		// }, 2000);
		// return () => clearTimeout(timeout);
    }

    const lihatDetail = (data) => {
        modalDetail(true);
        setData(data.data);
    }
    
    const [filterText, setFilterText] = useState('');
    const cari = (val) => {
        var data = backup;
        var filteredItems = data.filter(
            item => {
                if (item.user && item.user.toLowerCase().includes(val.toLowerCase())) {
                    return true;
                } 
                else if (item.modul && item.modul.toLowerCase().includes(val.toLowerCase())) {
                    return true;
                }
                else if (item.activity && item.activity.toLowerCase().includes(val.toLowerCase())) {
                    return true;
                }
    
                return false;
            }
        )

        if (val == '') {
            setRows(backup);
        } else {
            setRows(filteredItems);
        }
    }

    const handleClear = () => {
        setFilterText('');
        setRows(backup);
    }
      
    return (
        <>
            <Row>
                <Col sm="12">
                <Card>
                    <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                            <h4 className="card-title">Logs Activity</h4>
                            <p>Overview Data Logs Activity</p>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Form.Group className='d-flex mb-3 w-100' style={{justifyContent: 'end', alignContent: 'center'}}>
                            <Form.Control
                            id="search"
                            type="text"
                            placeholder="Cari..."
                            aria-label="Search Input"
                            value={filterText}
                            onChange={(e) => {cari(e.target.value); setFilterText(e.target.value);}}
                            className='me-2 w-25'
                            />
                            <Button variant="secondary" type="button" title="Reset" onClick={handleClear}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" fill="currentColor" id="reload">
                                <path d="M21,11a1,1,0,0,0-1,1,8.05,8.05,0,1,1-2.22-5.5h-2.4a1,1,0,0,0,0,2h4.53a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4.77A10,10,0,1,0,22,12,1,1,0,0,0,21,11Z"></path>
                            </svg>
                            </Button>
                        </Form.Group>
                        <div className="table-responsive">
                            <DataTable
                                columns={columns}
                                data={rows}
                                // progressPending={pending}
                                pagination
                                highlightOnHover
                            />
                        </div>
                    </Card.Body>
                </Card>
                </Col>
            </Row>

            <Modal show={detail} size="lg" onHide={() => modalDetail(false)}>
                <Modal.Header closeButton className={props.darkMode}>
                <Modal.Title>Detail Log</Modal.Title>
                </Modal.Header>
                <Modal.Body className={props.darkMode}>
                <Form.Group className="mb-3">
                    <Form.Label>Data Histori</Form.Label>
                    <Form.Control type="text" as="textarea" value={data} rows={15} />
                </Form.Group>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default connect(mapStateToProps)(LogsActivity)

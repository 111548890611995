import React, {useEffect,useState, useContext} from 'react'
import {bindActionCreators} from "redux"
import useIdle from "../../hooks/useIdleTimeout"
import AuthContext from "../../context/AuthContext"
import {useHistory} from 'react-router-dom'

import { Container, Modal, Row, Col, Button } from "react-bootstrap";
//header
import Header from '../../components/partials/dashboard/HeaderStyle/header'
//subheader
import SubHeader from '../../components/partials/dashboard/HeaderStyle/sub-header'
//sidebar
import Sidebar from '../../components/partials/dashboard/SidebarStyle/sidebar'
//footer
import Footer from '../../components/partials/dashboard/FooterStyle/footer'
//default 
import DefaultRouter from '../../router/default-router'

import SettingOffcanvas from '../../components/partials/components/settingoffcanvas'
import Loader from '../../components/Loader'

// store
import {NavbarstyleAction, getDirMode, getcustomizerMode, getcustomizerprimaryMode, getcustomizerinfoMode,  SchemeDirAction, ColorCustomizerAction,  getNavbarStyleMode, getSidebarActiveMode, SidebarActiveStyleAction, getDarkMode, ModeAction,  SidebarColorAction, getSidebarColorMode, getSidebarTypeMode} from '../../store/setting/setting'
import {connect} from "react-redux"

const mapStateToProps = (state) => {
    return {
        darkMode: getDarkMode(state),
        customizerMode: getcustomizerMode(state),
        cololrinfomode: getcustomizerinfoMode(state),
        colorprimarymode: getcustomizerprimaryMode(state),
        schemeDirMode: getDirMode(state),
        sidebarcolorMode: getSidebarColorMode(state),
        sidebarTypeMode: getSidebarTypeMode(state),
        sidebaractivestyleMode: getSidebarActiveMode(state),
        navbarstylemode: getNavbarStyleMode(state),
    };
}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            ModeAction,
            SchemeDirAction,
            SidebarColorAction,
            SidebarActiveStyleAction,
            NavbarstyleAction,
            ColorCustomizerAction,
        },
        dispatch
    )
})

const Default = (props) => {
    const token = localStorage.getItem("token");
    //define history
    const history = useHistory();

    useEffect(() => {
        //   darkmode
        const colorMode = sessionStorage.getItem('color-mode');
        if(colorMode===null){
            props.ModeAction(props.darkMode);
        }
        else{
            props.ModeAction(colorMode);
        }

        // colocustomizermode
        const colorcustomizerMode = sessionStorage.getItem('color-customizer-mode');
        const colorcustomizerinfoMode = sessionStorage.getItem('colorcustominfo-mode');
        const colorcustomizerprimaryMode = sessionStorage.getItem('colorcustomprimary-mode');
        if(colorcustomizerMode===null){
            props.ColorCustomizerAction(props.customizerMode, props.cololrinfomode, props.colorprimarymode);
            document.documentElement.style.setProperty('--bs-info', props.cololrinfomode );
        }
        else{
            props.ColorCustomizerAction(colorcustomizerMode, colorcustomizerinfoMode, colorcustomizerprimaryMode);
            document.documentElement.style.setProperty('--bs-info', colorcustomizerinfoMode);
        }

        // rtlmode
        const rtlMode = sessionStorage.getItem('rtl-mode');
        if(rtlMode===null){
            props.SchemeDirAction(props.schemeDirMode)
        }
        else{
            props.SchemeDirAction(rtlMode);
        }   

        // alert('d '+token);
        if(!token || token == 'initoken') {
            //redirect login page
            history.push('/');
        }
    },[])

    const [openModal, setOpenModal] = useState(false)
    const { logout } = useContext(AuthContext);

    const handleIdle = () => {
        setOpenModal(true);
    }
    const { idleTimer } = useIdle({ onIdle: handleIdle, idleTime: 60 })

    const stay = () => {
        setOpenModal(false)
        idleTimer.reset()
    }

    const handleLogout = () => {
        localStorage.removeItem("token");
        history.push('/');
        setOpenModal(false)
    }
   
    return (
        <>
            <Loader/>
            <Sidebar />
            <main className="main-content">
                <div className="position-relative">
                    <Header />
                    <SubHeader />
                </div>
                <div className="py-0 content-inner mt-n5" style={{minHeight: "0px"}}>
                    <DefaultRouter />
                </div>

                {/* <Footer /> */}
            </main>
            {/* <SettingOffcanvas  /> */}

            <Modal show={openModal} onHide={stay}>
                <Modal.Header closeButton>
                    <Modal.Title>Your session is about to expire</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Your session is about to expire. You'll be automatically signed out.
                    </p>
                    <p>
                        Do you want to stay signed in ?
                    </p>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleLogout}>
                        Sign out now
                    </Button>
                    <Button variant="primary" onClick={stay}>
                        Stay signed in
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Default)

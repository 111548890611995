import React,{useState,useEffect} from 'react'
import {Row,Col,Modal,Form,Button} from 'react-bootstrap'
import {Link,useHistory} from 'react-router-dom'
import Swal from 'sweetalert2'
import Card from '../../../components/Card'
import '../../../index.css'
import {bindActionCreators} from "redux"

// Dropzone
import { useDropzone } from 'react-dropzone'
import { FileUploader } from 'react-drag-drop-files'
//circular
import Circularprogressbar from '../../../components/circularprogressbar.js'
// AOS
import AOS from 'aos'
import '../../../../node_modules/aos/dist/aos'
import '../../../../node_modules/aos/dist/aos.css'
//swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/components/navigation/navigation.scss';
//progressbar
import Progress from '../../../components/progress.js'
//Count-up
import CountUp from 'react-countup';
// store
import {NavbarstyleAction, getDirMode, getcustomizerMode, getcustomizerprimaryMode,  getcustomizerinfoMode,  SchemeDirAction, ColorCustomizerAction,  getNavbarStyleMode, getSidebarActiveMode, SidebarActiveStyleAction, getDarkMode, ModeAction,  SidebarColorAction, getSidebarColorMode, getSidebarTypeMode} from '../../../store/setting/setting'
import {connect} from "react-redux"

// install Swiper modules
SwiperCore.use([Navigation]);

const mapStateToProps = (state) => {
   return {
      darkMode: getDarkMode(state),
      colorprimarymode: getcustomizerprimaryMode(state),
   };
}

const Data_table = [
   {
      id_user: 'user001',
      nama_user: 'user 1',
      status_user: 'Aktif',
   },
   {
      id_user: 'user002',
      nama_user: 'user 2',
      status_user: 'Non Aktif',
   },
   {
      id_user: 'user003',
      nama_user: 'user 3',
      status_user: 'Aktif',
   },
   {
      id_user: 'user004',
      nama_user: 'user 4',
      status_user: 'Non Aktif',
   },
   {
      id_user: 'user005',
      nama_user: 'user 5',
      status_user: 'Non Aktif',
   },
   {
      id_user: 'user006',
      nama_user: 'user 6',
      status_user: 'Aktif',
   },
]

const ListUser = (props) => {
   const [importuser, showImport] = useState(false);
   const closeImport = () => showImport(false);
   const modalImport = () => showImport(true);
   
   const [adduser, showAdd] = useState(false);
   const closeAdd = () => showAdd(false);
   const modalAdd = () => showAdd(true);

   useEffect(() => {
      AOS.init({
         startEvent: 'DOMContentLoaded',
         disable:  function() {
            var maxWidth = 996;
            return window.innerWidth < maxWidth;
         },
         throttleDelay: 10,
         once: true,
         duration: 700,
         offset: 10
      });
   });

   const confirmDelete = () => {
      Swal.fire({
         title: 'Data Ingin Dihapus ?',
         text: "Data Yang Dihapus Tidak Dapat Dikembalikan Lagi !",
         icon: 'warning',
         showCancelButton: true,
         confirmButtonText: 'Ok',
         cancelButtonText: 'Cancel',
         reverseButtons: true
      }).then((result) => {
         if (result.isConfirmed) {
            Swal.fire(
               'Deleted!',
               'Your file has been deleted.',
               'success'
            )
         }
      });
   }

   const fileTypes = ["JPG", "PNG", "PDF"];
   const [file, setFile] = useState(null);
   const handleChange = (file) => {
      setFile(file);
   };
   
   const { getRootProps, getInputProps, acceptedFiles } = useDropzone({});
   const files = acceptedFiles.map((file) => (
      <p key={file.path}>
         {file.path} - {file.size} bytes
      </p>
   ));

   return (
      <>
         <Row>
            <Col md="12" lg="12">
               <Row className="row-cols-1">
                  <div className="overflow-hidden d-slider1">
                     <Swiper className="p-0 m-0 mb-2 list-inline"
                        slidesPerView={3}
                        spaceBetween={32}
                        navigation={{
                           nextEl: '.swiper-button-next',
                           prevEl: '.swiper-button-prev'
                        }}
                        data-aos="fade-up" data-aos-delay="700"
                     >
                        <SwiperSlide className="card card-slide" >
                           <div className="card-body">
                              <div className="progress-widget" >
                                 <Circularprogressbar stroke={props.colorprimarymode} width="60px" height="60px" Linecap='rounded' trailstroke='#ddd' strokewidth="4px" style={{width:60, height:60,}} value={100} id="circle-progress-01" >
                                    <h3>U</h3>
                                 </Circularprogressbar>
                                 <div className="progress-detail">
                                    <h2 className="counter"><CountUp start={1} end={6} duration={3}/></h2>
                                    <p  className="mb-2">Total User</p>
                                 </div>
                              </div>
                           </div>
                        </SwiperSlide>
                        <SwiperSlide className="card card-slide" >
                           <div className="card-body">
                              <div className="progress-widget" >
                                 <Circularprogressbar stroke={props.colorprimarymode} width="60px" height="60px" Linecap='rounded' trailstroke='#ddd' strokewidth="4px" style={{width:60, height:60,}} value={50} id="circle-progress-02" >
                                    <h3>A</h3>
                                 </Circularprogressbar>
                                 <div className="progress-detail">
                                    <h2 className="counter"><CountUp start={1} end={3} duration={3}/></h2>
                                    <p  className="mb-2">User Aktif</p>
                                 </div>
                              </div>
                           </div>
                        </SwiperSlide>
                        <SwiperSlide className="card card-slide" >
                           <div className="card-body">
                              <div className="progress-widget" >
                                 <Circularprogressbar stroke={props.colorprimarymode} width="60px" height="60px" Linecap='rounded' trailstroke='#ddd' strokewidth="4px" style={{width:60, height:60,}} value={50} id="circle-progress-03" >
                                    <h3>N</h3>
                                 </Circularprogressbar>
                                 <div className="progress-detail">
                                    <h2 className="counter"><CountUp start={1} end={3} duration={3}/></h2>
                                    <p  className="mb-2">User Non Aktif</p>
                                 </div>
                              </div>
                           </div>
                        </SwiperSlide>
                        {/* <div className="swiper-button swiper-button-next"></div>
                        <div className="swiper-button swiper-button-prev"></div> */}
                     </Swiper>
                  </div>
               </Row>
            </Col>
            <Col sm="12">
               <Card>
                  <Card.Header className="d-flex justify-content-between">
                     <div className="header-title">
                        <h4 className="card-title">User</h4>
                        <p>Overview Data User</p>
                     </div>
                     <div>
                        <Button  className="text-center btn-primary btn-icon me-2 mt-lg-0 mt-md-0 mt-3" onClick={modalImport}>
                           <i className="btn-inner">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                 <path d="M12.1221 15.436L12.1221 3.39502" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M15.0381 12.5083L12.1221 15.4363L9.20609 12.5083" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M16.7551 8.12793H17.6881C19.7231 8.12793 21.3721 9.77693 21.3721 11.8129V16.6969C21.3721 18.7269 19.7271 20.3719 17.6971 20.3719L6.55707 20.3719C4.52207 20.3719 2.87207 18.7219 2.87207 16.6869V11.8019C2.87207 9.77293 4.51807 8.12793 6.54707 8.12793L7.48907 8.12793" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                              </svg>
                           </i>
                           {' '}
                           <span>Import User</span>
                        </Button>
                        <Button  className="text-center btn-primary btn-icon mt-lg-0 mt-md-0 mt-3" onClick={modalAdd}>
                           <i className="btn-inner">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                              </svg>
                           </i>
                           <span>Tambah User</span>
                        </Button>
                     </div>
                  </Card.Header>
                  <Card.Body>
                     <div className="table-responsive">
                        <table id="tbl_user" className="table table-striped" data-toggle="data-table">
                           <thead>
                              <tr>
                                 <th>ID User</th>
                                 <th>Nama</th>
                                 <th>Status</th>
                                 <th className='text-center'>Aksi</th>
                              </tr>
                           </thead>
                           <tbody>
                              {Data_table.map((item) => (
                                 <tr key={item.id_user}>
                                    <td>{item.id_user}</td>
                                    <td>{item.nama_user}</td>
                                    <td>{item.status_user}</td>
                                    <td className='text-center'>
                                       <span className="btn btn-sm btn-icon text-warning flex-end" data-bs-toggle="tooltip" title="Edit Kelas" onClick={modalAdd}>
                                          <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                             <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                             <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                             <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                          </svg>
                                       </span>
                                       <span className="btn btn-sm btn-icon text-danger" data-bs-toggle="tooltip" title="Hapus Kelas" onClick={confirmDelete}>
                                          <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                                             <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                             <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                             <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                          </svg>
                                       </span>
                                    </td>
                                 </tr>
                              ))}
                           </tbody>
                        </table>
                     </div>
                  </Card.Body>
               </Card>
            </Col>
         </Row>
         
         <Modal show={importuser} onHide={closeImport}>
            <Modal.Header closeButton className={props.darkMode}>
               <Modal.Title>Upload User</Modal.Title>
            </Modal.Header>
            <Modal.Body className={props.darkMode}>
               <FileUploader
                  multiple={true}
                  handleChange={handleChange}
                  name="file"
                  types={fileTypes}
                  label="DRAG AND DROP A FILE OR SELECT ADD IMAGE"
                  classes="py-5"
               />
               {/* <div className="container upload-area">
                  <div {...getRootProps({ className: "dropzone" })}>
                     <input {...getInputProps()} />
                     <h3>DRAG AND DROP A FILES OR SELECT ADD IMAGE</h3>
                  </div>
                  <aside>
                     {files}
                  </aside>
               </div> */}
               <div className='text-center mt-2'>
                  <p>{file ? `File name: ${file[0].name}` : "No Files Uploaded Yet"}</p>
               </div>
               <Button variant="primary" className='w-100 mt-2'>
                  Download File Template
               </Button>
               <hr/>
               <Button variant="success" onClick={() =>{closeImport()}}>
                  Save
               </Button>
               {' '}
               <Button variant="danger" onClick={closeImport}>
                  Cancel
               </Button>
            </Modal.Body>
         </Modal>
         
         <Modal show={adduser} onHide={closeAdd}>
            <Modal.Header closeButton className={props.darkMode}>
               <Modal.Title>Tambah User</Modal.Title>
            </Modal.Header>
            <Modal.Body className={props.darkMode}>
               <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Nama User</Form.Label>
                  <Form.Control type="text" placeholder="Masukkan Nama User" id="nama_user" name='nama_user' />
               </Form.Group>
               <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Status User</Form.Label>
                  <select className='form-control' id='status_user' name='status_user'>
                     <option value=''>Pilih Status</option>
                     <option value='Aktif'>Aktif</option>
                     <option value='Non Aktif'>Non Aktif</option>
                  </select>
               </Form.Group>
               <Button variant="success" onClick={() =>{closeAdd()}}>
                  Save
               </Button>
               {' '}
               <Button variant="danger" onClick={closeAdd}>
                  Cancel
               </Button>
            </Modal.Body>
         </Modal>
      </>
   )
}

export default connect(mapStateToProps)(ListUser)

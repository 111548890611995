import React from 'react'
import Index from '../views/dashboard/index'
const BoxedRouter = () => {
    return (
        <>
            <Index />
        </>
    )
}

export default BoxedRouter

import React,{useState,useEffect} from 'react'
import {Row,Col,Image,Modal,Form,Button,InputGroup,FormControl} from 'react-bootstrap'
import {Link,useHistory} from 'react-router-dom'
import Swal from 'sweetalert2'
import Card from '../../../components/Card'
import '../../../index.css'
import {bindActionCreators} from "redux"
import axios from 'axios'

// store
import {NavbarstyleAction, getDirMode, getcustomizerMode, getcustomizerprimaryMode,  getcustomizerinfoMode,  SchemeDirAction, ColorCustomizerAction,  getNavbarStyleMode, getSidebarActiveMode, SidebarActiveStyleAction, getDarkMode, ModeAction,  SidebarColorAction, getSidebarColorMode, getSidebarTypeMode} from '../../../store/setting/setting'
import {connect} from "react-redux"

const mapStateToProps = (state) => {
   return {
       darkMode: getDarkMode(state),
       colorprimarymode: getcustomizerprimaryMode(state),
   };
}

const RekapSoal = (props) => {

   const [kelas, setKelas] = useState('');
   const [buku, setBuku] = useState('');
   const [soal, setSoal] = useState('');
   const token = localStorage.getItem("token");
   const [apiKelas, setApiKelas] = useState([]);
   const [apiBuku, setApiBuku] = useState([]);
   const [apiSoal, setApiSoal] = useState([]);

   useEffect(() => {
      getKelas();
   }, []);
   
   const getKelas = async (e) => {
      const res = await axios.get('https://api.alazhar2.com/node/admin/class', 
      { headers: {"Authorization" : `Bearer ${token}`}});
      const result = res.data.data.filter(item => {
         return item.status == 1;
      })
      setApiKelas(result);
   }

   const getBook = async (class_code) => {
      const res = await axios.get('https://api.alazhar2.com/node/admin/class/getBook?class='+class_code, 
      { headers: {"Authorization" : `Bearer ${token}`}});
      const result = res.data.data;
      setApiBuku(result);
   }

   const getSoal = async (book_code) => {
      const res = await axios.get('https://api.alazhar2.com/node/admin/books/attribute?book='+book_code+'&type=Question', 
      { headers: {"Authorization" : `Bearer ${token}`}});
      const result = res.data.data;
      detSoal(0,result,result.length);
   }

   const detSoal = async (curr, data, end) => {
      let code = data[curr].link;

      axios.get('https://api.alazhar2.com/node/admin/question?code='+code, 
      { headers: {"Authorization" : `Bearer ${token}`}})
      .then((res) => {
         let result = res.data.data;
         data[curr].code_soal = result[0].code;
         data[curr].nama_soal = result[0].name;
         curr = curr + 1;

         if (curr < end) {
            detSoal(curr,data,end);
         } else {
            setApiSoal(data);
         }
      })
   }
   
   return (
      <>
         <Row>
            <Col sm="12">
               <Card>
                  <Card.Header className="d-flex justify-content-between">
                     <div className="header-title">
                        <h4 className="card-title">Rekap Soal</h4>
                        <p>Overview Rekap Soal</p>
                     </div>
                  </Card.Header>
                  <Card.Body>
                     <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                           Kelas
                        </Form.Label>
                        <Col sm="5">
                           <Form.Select onChange={(e) => {setKelas(e.target.value); getBook(e.target.value);}}>
                              <option value=''>Pilih Kelas</option>
                              {apiKelas?.map((item) => (
                                 <option key={item.code} value={item.code}>{item.name}</option>
                              ))}
                           </Form.Select>
                        </Col>
                     </Form.Group>
                     <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                           Buku
                        </Form.Label>
                        <Col sm="5">
                           <Form.Select onChange={(e) => {setBuku(e.target.value); getSoal(e.target.value);}}>
                              <option value=''>Pilih Buku</option>
                              {apiBuku?.map((item) => (
                                 <option key={item.code} value={item.book_code}>{item.title}</option>
                              ))}
                           </Form.Select>
                        </Col>
                     </Form.Group>
                     <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                           Soal
                        </Form.Label>
                        <Col sm="5">
                           <Form.Select onChange={(e) => setSoal(e.target.value)}>
                              <option value=''>Pilih Soal</option>
                              {apiSoal?.map((item) => (
                                 <option key={item.code} value={item.code_soal}>{item.nama_soal}</option>
                              ))}
                           </Form.Select>
                        </Col>
                     </Form.Group>
                     <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                           &nbsp;
                        </Form.Label>
                        <Col sm="5">
                           <Button variant='success'>SUBMIT</Button>
                        </Col>
                     </Form.Group>
                  </Card.Body>
               </Card>
            </Col>
         </Row>
      </>
   )
}

export default connect(mapStateToProps)(RekapSoal)

import React, { useState } from 'react'
import AuthContext from "../context/AuthContext"
import {useHistory} from 'react-router-dom'

//router
import { Switch,Route } from 'react-router'
//layoutpages
import Index from '../views/index'
import Default from '../layouts/dashboard/default'
import Horizontal from '../layouts/dashboard/horizontal'
import Boxed from '../layouts/dashboard/boxed'
import DualHorizontal from '../layouts/dashboard/dual-horizontal'
import DualCompact from '../layouts/dashboard/dual-compact'
import BoxedFancy from "../layouts/dashboard/boxed-fancy"
import Simple from '../layouts/dashboard/simple'
import SignIn from '../views/dashboard/auth/sign-in'
import ViewEbook from '../views/dashboard/ebook/view_ebook'
import QuestionPage from '../views/dashboard/ebook/question_page'
import PrivacyPolicy from '../views/dashboard/ebook/privacy_policy'
import { useEffect } from 'react'

const IndexRouters = () => {
    const [authstatus, setauthstatus] = useState(false);
    const token = localStorage.getItem("token");
    //define history
    const history = useHistory();

    const login = () => {
        setauthstatus(true);
    };

    const logout = () => {
        localStorage.removeItem("token");
        setauthstatus(false);
        history.push('/');
    };

    useEffect(() => {
        // alert('i '+token);
        // if(!token || token == 'initoken') {
        //     //redirect login page
        //     history.push('/');
        // }
    },[])

    return (
        <>
            {/* <AuthContext.Provider value={{ status: authstatus, login: login, logout: logout }}> */}
                <Switch>
                    {/* {!authstatus ? */}
                    <Route exact path="/" component={SignIn}></Route> 
                    <Route path="/dashboard" component={Default}></Route>
                    {/* } */}
                    <Route  path="/boxed" component={Boxed}></Route>
                    <Route  path="/horizontal" component={Horizontal}></Route>
                    <Route  path="/dual-horizontal" component={DualHorizontal}></Route>
                    <Route  path="/dual-compact" component={DualCompact} ></Route>
                    <Route  path="/boxedFancy" component={BoxedFancy} ></Route>
                    <Route  path="/auth" component={Simple}></Route>
                    <Route  path="/errors" component={Simple}></Route>
                    <Route  path="/view_ebook/:view_code/:user_code" component={ViewEbook}></Route>
                    <Route  path="/question_page/:book_code/:code_soal/:user_code" component={QuestionPage}></Route>
                    <Route  path="/privacy_policy" component={PrivacyPolicy}></Route>
                </Switch>
            {/* </AuthContext.Provider> */}
        </>
    )
}

export default IndexRouters

import React,{useState,useEffect,useLayoutEffect,useRef} from 'react'
import {Row,Col,Image,Modal,Form,Button,InputGroup,FormControl,Collapse,Accordion} from 'react-bootstrap'
import {Link,useHistory,useParams} from 'react-router-dom'
import Card from '../../../components/Card'
import HTMLFlipBook from 'react-pageflip';
import '../../../index.css'
import axios from 'axios'
import Swal from 'sweetalert2'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

// img
import avatars1 from '../../../assets/images/avatars/01.png'
import auth1 from '../../../assets/images/auth/04.png'
import background from '../../../assets/images/dashboard/background.jpg'

const ViewEbook = () => {
    const { view_code } = useParams();
    const { user_code } = useParams();
    const [open, setOpen] = useState(false);

    const [pages, setPages] = useState();
    const [pdfimg, setPdfImg] = useState([]);
    const [apilink, setAPIlink] = useState([]);
    const [apisoal, setAPIsoal] = useState([]);
    const [apivideo, setAPIvid] = useState([]);
    const [apidoc, setAPIdoc] = useState([]);
    const [namabuku, setNamaBuku] = useState('');
    const [widthx, setWidth] = useState('');
    const [heightx, setHeight] = useState('');
    const token = localStorage.getItem("token");
    const [pakecover, setCover] = useState(true);
    const [portrait, setPortrait] = useState(true);
    const [link, setLink] = useState('');
    const [backpage, setBackPage] = useState('');

    const [viewlink, showLink] = useState(false);
    const closeLink = () => showLink(false);
    const modalLink = () => {
        showLink(true);
    }
    const [orientation, setOrientation] = useState('');
    const pageFlipRef = useRef();

    useEffect(() => {
        getResolution();
        getData();
        // setTimeout(() => {
        //     getLink();
        //     getSoal();
        //     getVideo();
        //     getDoc();
        // }, 5000);

        // window.addEventListener("orientationchange", function() {
        //     window.location.reload();
        // }, false)
    }, []);
    
    function getResolution() {
        let width = window.innerWidth;
        let height = window.innerHeight;
        let widthBook = 0;
        let heightBook = 0;
        if (width > height){
            widthBook = width*0.4;
            heightBook = ((width*0.4)*3)/2;

            if (heightBook > height) {
                heightBook = height;
            }
            
            setCover(true);
            setPortrait(false);
            // console.log("w : "+ width +" > h : "+ height);
        } else {
            widthBook = width*(width/height);
            heightBook = height*(width/height);

            if (height < 1000) {
                widthBook = width;
                heightBook = (width*3)/2;
            }
            
            setCover(false);
            setPortrait(true);
            // console.log("w : "+ width +" < h : "+ height);
        }
        setWidth(widthBook);
        setHeight(heightBook);
        // console.log(widthBook);
        // console.log(heightBook);
    }

    const getData = async (e) => {
        axios.get('https://api.alazhar2.com/node/admin/books/detail', {
            params: {
                books: view_code.replaceAll("-","/")
            }
        })
        .then(function (response) {
            let data = response.data;
            let dt_file = data.file;
            let dt_background = data.background;
            let dt_arr = data.data;

            if (dt_background != null){
                background = "https://api.alazhar2.com/upload/books/"+dt_file+"/background/"+dt_background;
            }
            
            setNamaBuku(dt_file);
            setPdfImg(dt_arr);
            setTimeout(() => {
                start_loadbook(dt_file, dt_arr.slice(0, 5));
            }, 500);
            getLink();
        })
        .catch(function (error) {
            console.log("GET DATA ERR : " + error);
        })
        .finally(function () {
            // always executed
        });  
    }

    const start_loadbook = (nmbuku, dataBook) => {
        dataBook.forEach((key,val) => {
            let parent = document.getElementById(key.pages);
            let link = "https://api.alazhar2.com/upload/books/"+nmbuku+"/detail/"+key.file;
            let imgPG = '<img src="'+link+'" style="position: absolute; width: 100%; height: 100%;">';

            parent.innerHTML = imgPG;
        });
    }

    const handleFlip = (page) => {
        if (page == 1) {
            localStorage.removeItem('midPG');
        }
        const dtPG = pdfimg;
        let currPG = page + 2;
        let t = 3;
        let x = localStorage.getItem('midPG');
        let y = 'ganjil';
        let cr = 0;
        let imgPG = '';

        if (x != null) {
            if (x%2 == 0) {
                x -= 1;
                y = 'genap';
            }

            t = x;
        }
        
        if (t == currPG) {
            if (y == 'ganjil') {
                t = parseInt(t) + 5;
                cr = currPG + 2;
            } else {
                t = parseInt(t) + 6;
                cr = currPG + 3;
            }
            localStorage.setItem('midPG', parseInt(t));
            
            let arr = dtPG.slice(cr, cr+5);
            arr.forEach((key,val) => {
                let parent = document.getElementById(key.pages);
                let link = "https://api.alazhar2.com/upload/books/"+namabuku+"/detail/"+key.file;
                imgPG = '<img src="'+link+'" style="position: absolute; width: 100%; height: 100%;">';
                
                parent.innerHTML = imgPG;
            });
        }

        if (page > 5) {
            for (let i = page; i > page-5; i--) {
                const myDiv = document.getElementById(i);
                const imageinDIV = myDiv.querySelector('img');
    
                if (!imageinDIV) {
                    let arrback = dtPG[i-1];
                    let parentback = document.getElementById(arrback.pages);
                    let linkback = "https://api.alazhar2.com/upload/books/"+namabuku+"/detail/"+arrback.file;
                    let imgPGback = '<img src="'+linkback+'" style="position: absolute; width: 100%; height: 100%;">';
                    
                    parentback.innerHTML = imgPGback;
                }
            }
        }
    };

    const getLink = async (e) => {
        axios.get('https://api.alazhar2.com/node/admin/books/attribute', {
            params: {
                book: view_code.replaceAll("-","/"),
                type: 'Link'
            }
        })
        .then(function (response) {
            let data = response.data;
            let dt_arr = data.data;

            setAPIlink(dt_arr);
            getSoal();
        })
        .catch(function (error) {
            console.log("GET LINK ERR : " + error);
        })
    }

    const getSoal = async (e) => {
        axios.get('https://api.alazhar2.com/node/admin/books/attribute', {
            params: {
                book: view_code.replaceAll("-","/"),
                type: 'Question'
            }
        })
        .then(function (response) {
            let data = response.data;
            let dt_arr = data.data;

            // console.log(dt_arr);
            setAPIsoal(dt_arr);
            getVideo();
        })
        .catch(function (error) {
            console.log("GET SOAL ERR : " + error);
        })
    }

    const getVideo = async (e) => {
        axios.get('https://api.alazhar2.com/node/admin/books/attribute', {
            params: {
                book: view_code.replaceAll("-","/"),
                type: 'Video'
            }
        })
        .then(function (response) {
            let data = response.data;
            let dt_arr = data.data;

            setAPIvid(dt_arr);
            getDoc();
        })
        .catch(function (error) {
            console.log("GET VIDEO ERR : " + error);
        })
    }

    const getDoc = async (e) => {
        axios.get('https://api.alazhar2.com/node/admin/books/attribute', {
            params: {
                book: view_code.replaceAll("-","/"),
                type: 'Doc'
            }
        })
        .then(function (response) {
            let data = response.data;
            let dt_arr = data.data;

            setAPIdoc(dt_arr);
        })
        .catch(function (error) {
            console.log("GET DOC ERR : " + error);
        })
    }

    const findpage = useRef();
    const [nopage, setNoPage] = useState(0);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //zoom in zoom out setting
    const [canPanning, setCanPanning] = useState(false);
    const [scaleZoom, setScaleZoom] = useState(1);

    const getScaleTransform = (ref, event) => {
        if (event.scale == 1) {
            setCanPanning(false);
        } else {
            setCanPanning(true);
        }
        //setScaleZoom(event.scale);
      };

    const carihalaman = () => {
        if (nopage > (pdfimg.length-1)) {
            Swal.fire({
                title: "Melebihi Total Halaman",
                text: "Mohon masukkan nomor halaman yang sesuai",
                icon: 'warning',
            })
            return;
        } else {
            let arr = pdfimg.slice(nopage-5, nopage+5);
            arr.forEach((key,val) => {
                let parent = document.getElementById(key.pages);
                let link = "https://api.alazhar2.com/upload/books/"+namabuku+"/detail/"+key.file;
                let imgPG = '<img src="'+link+'" style="position: absolute; width: 100%; height: 100%;">';
                
                parent.innerHTML = imgPG;
            });
            findpage.current.pageFlip().flip(parseInt(nopage),'bottom');
            // handleClose();
        }
    }

    return (
        <>
        <Modal show={show} onHide={handleClose} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title>CARI HALAMAN</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>Total Halaman : ({(pdfimg.length-1)})</Form.Label>
                    <Form.Control 
                        type="number" 
                        placeholder="No Halaman Yang Dicari" 
                        onChange={(e) => setNoPage(e.target.value)}
                        id="nohal"
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Batal
                </Button>
                <Button variant="primary" onClick={() => carihalaman()}>
                    Cari
                </Button>
            </Modal.Footer>
        </Modal>

        <section style={{overflow: "hidden", height: "100%"}}>
            <Row style={{overflow: "hidden", height: "100%"}}>            
                <Col md="12" className="p-0" style={{overflow: "hidden", height: "100%"}}>  
                    <div style={{ 
                        backgroundImage: `url(${background})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        display: "flex", 
                        justifyContent: "center",
                        overflow: "hidden", 
                        width: "100%",
                        height: "100%"
                    }}>

                        {/* <div className='section_find'>
                            <h2>
                                Cari Halaman : 
                                <Form.Control 
                                    type="number" 
                                    placeholder="No Halaman Yang Dicari" 
                                    onChange={(e) => setNoPage(e.target.value)}
                                    id="nohal"
                                />
                                / ({(pdfimg.length-1)})
                            </h2>
                        </div> */}

                        {/* <div className='btn btn-dark section__find'>
                            <span>
                                Cari Halaman : 
                                <input type='number' placeholder='No Halaman' id="nohal" onChange={(e) => setNoPage(e.target.value)} className='mx-2' style={{width: "7%", fontSize: "18px", borderRadius: "8px"}} /> 
                                / {(pdfimg.length-1)} 
                                <Button variant="primary" size="sm" style={{fontSize: "18px", padding: "2px 10px"}} className='ms-4' onClick={() => carihalaman()}>
                                    CARI
                                </Button>
                            </span>
                        </div> */}

                        <nav className="navbar fixed-bottom navbar-dark d-flex justify-content-center" style={{background: "transparent"}}>
                            {/* <span style={{fontSize: "22px"}}>
                                Cari Halaman : 
                                <input type='number' placeholder='No Halaman' id="nohal" onChange={(e) => setNoPage(e.target.value)} className='mx-2' style={{width: "120px", fontSize: "18px", borderRadius: "8px"}} /> 
                                / {(pdfimg.length-1)} 
                                <Button variant="primary" size="sm" style={{fontSize: "18px", padding: "0px 10px"}} className='ms-4' onClick={() => carihalaman()}>
                                    CARI
                                </Button>
                            </span> */}
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header></Accordion.Header>
                                    <Accordion.Body>
                                        <span style={{fontSize: "22px"}}>
                                            Cari Halaman : 
                                            <input type='number' placeholder='No Halaman' id="nohal" onChange={(e) => setNoPage(e.target.value)} className='mx-2' style={{width: "120px", fontSize: "18px", borderRadius: "8px"}} /> 
                                            / {(pdfimg.length-1)} 
                                            <Button variant="primary" size="sm" style={{fontSize: "18px", padding: "0px 10px"}} className='ms-4' onClick={() => carihalaman()}>
                                                CARI
                                            </Button>
                                        </span>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </nav>

                        <TransformWrapper
                            doubleClick={{disabled: true}}
                             panning={{disabled: !canPanning}}
                             onTransformed={getScaleTransform}>
                            <TransformComponent>
                                <HTMLFlipBook 
                                    width={widthx} 
                                    height={heightx} 
                                    showCover={pakecover} 
                                    drawShadow={false} 
                                    usePortrait={portrait} 
                                    ref={findpage}
                                    onFlip={e => handleFlip(e.data)}
                                    style={{
                                        overflow: "hidden"
                                    }}

                                    mobileScrollSupport={true}
                                    disableFlipByClick={true}>
                                    {pdfimg?.map((item,index) => (
                                        <div className="demoPage" key={item.code} id={item.pages}
                                            style={{
                                                position: "absolute"
                                            }}
                                        >   
                                            {/* <img
                                                src={"https://api.alazhar2.com/upload/books/"+namabuku+"/detail/"+item.file}
                                                style={{
                                                    position: "absolute",
                                                    width: "100%",
                                                    height: "100%",
                                                }}
                                            /> */}
                                            
                                            {apivideo.map((itemvid,indexvid) => (
                                                itemvid.pages != item.pages ? <></> :
                                                    <div key={indexvid} style={{position: "absolute", top: `${pakecover ? 35 : 25}px`}}>
                                                        <iframe src={itemvid.link} 
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen
                                                            style={{
                                                                zIndex: 99999,
                                                                position: "absolute",
                                                                width: `${String(parseInt(itemvid.width.replace("px",""))/itemvid.widthbook*widthx)}px`,
                                                                height: `${String(parseInt(itemvid.height.replace("px",""))/(itemvid.widthbook*3/2)*heightx)}px`,
                                                                top: `${(itemvid.y*heightx)/100}px`,
                                                                left: `${(itemvid.x*widthx)/100}px`
                                                            }}
                                                        >                                                    
                                                        </iframe>
                                                    </div>
                                            ))}
                                            {apilink.map((itemlink,indexlink) => (
                                                itemlink.pages != item.pages ? <></> :
                                                    <div key={indexlink} style={{position: "absolute", top: `${pakecover ? 35 : 25}px`}}>
                                                        <Button onClick={() => {modalLink();setLink(itemlink.link);}}
                                                            style={{
                                                                zIndex: 99999,
                                                                position: "absolute",
                                                                width: `${String(parseInt(itemlink.width.replace("px",""))/itemlink.widthbook*widthx)}px`,
                                                                height: `${String(parseInt(itemlink.height.replace("px",""))/(itemlink.widthbook*3/2)*heightx)}px`,
                                                                top: `${(itemlink.y*heightx)/100}px`,
                                                                left: `${(itemlink.x*widthx)/100}px`,
                                                                background: "#1E90FF",
                                                                color: "black",
                                                                fontWeight: "bold",
                                                                border: "2px solid black",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center"
                                                            }}
                                                        >
                                                            LINK
                                                        </Button>
                                                    </div>
                                            ))}
                                            {apisoal.map((itemsoal,indexsoal) => (
                                                itemsoal.pages != item.pages ? <></> :
                                                    <div key={indexsoal} style={{position: "absolute", top: `${pakecover ? 35 : 25}px`}}>
                                                        <iframe src={`/question_page/${view_code}/${itemsoal.link.replaceAll("/","-")}/${user_code}`} 
                                                            style={{
                                                                zIndex: 99999,
                                                                position: "absolute",
                                                                width: `${String(parseInt(itemsoal.width.replace("px",""))/itemsoal.widthbook*widthx)}px`,
                                                                height: `${String(parseInt(itemsoal.height.replace("px",""))/(itemsoal.widthbook*3/2)*heightx)}px`,
                                                                top: `${(itemsoal.y*heightx)/100}px`,
                                                                left: `${(itemsoal.x*widthx)/100}px`,
                                                                borderRadius: "20px"
                                                            }}
                                                        >

                                                        </iframe>
                                                    </div>
                                            ))}
                                            {apidoc.map((itemdoc,indexdoc) => (
                                                itemdoc.pages != item.pages ? <></> :
                                                    <div key={indexdoc} style={{position: "absolute", top: `${pakecover ? 35 : 25}px`}}>
                                                        <iframe src={itemdoc.link}  
                                                            allowFullScreen={false} mozallowfullscreen="false" webkitallowfullscreen="false"
                                                            style={{
                                                                zIndex: 99999,
                                                                position: "absolute",
                                                                width: `${String(parseInt(itemdoc.width.replace("px",""))/itemdoc.widthbook*widthx)}px`,
                                                                height: `${String(parseInt(itemdoc.height.replace("px",""))/(itemdoc.widthbook*3/2)*heightx)}px`,
                                                                top: `${(itemdoc.y*heightx)/100}px`,
                                                                left: `${(itemdoc.x*widthx)/100}px`,
                                                                borderRadius: "20px"
                                                            }}>
                                                        </iframe>                                                 
                                                    </div>
                                            ))}
                                        </div>
                                    ))}
                                </HTMLFlipBook>
                            </TransformComponent>
                        </TransformWrapper>
                    </div>
                </Col>
            </Row>
        </section>

        <Modal fullscreen={true} show={viewlink} onHide={closeLink}>
            <Modal.Header closeButton>
               <Modal.Title>View Link</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{overflow: "hidden"}}>
                <iframe src={link} style={{width: "100%", height: "100%"}}></iframe>
            </Modal.Body>
        </Modal>
        </>
    )
}

export default ViewEbook

import React, {useEffect, useState, useContext} from 'react'
import { Navbar,Container,Nav,Dropdown, Row, Col, Modal,Form,Button} from 'react-bootstrap'
import {Link,useHistory} from 'react-router-dom'
import CustomToggle from '../../../dropdowns'
import {bindActionCreators} from "redux"
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import Swal from 'sweetalert2'
import AuthContext from "../../../../context/AuthContext"

//img
import flag1 from '../../../../assets/images/Flag/flag001.png'
import flag2 from '../../../../assets/images/Flag/flag-02.png'
import flag3 from '../../../../assets/images/Flag/flag-03.png'
import flag4 from '../../../../assets/images/Flag/flag-04.png'
import flag5 from '../../../../assets/images/Flag/flag-05.png'
import flag6 from '../../../../assets/images/Flag/flag-06.png'
import shapes1 from '../../../../assets/images/shapes/01.png'
import shapes2 from '../../../../assets/images/shapes/02.png'
import shapes3 from '../../../../assets/images/shapes/03.png'
import shapes4 from '../../../../assets/images/shapes/04.png'
import shapes5 from '../../../../assets/images/shapes/05.png'
import avatars1 from '../../../../assets/images/avatars/01.png'
import avatars2 from '../../../../assets/images/avatars/avtar_1.png'
import avatars3 from '../../../../assets/images/avatars/avtar_2.png'
import avatars4 from '../../../../assets/images/avatars/avtar_3.png'
import avatars5 from '../../../../assets/images/avatars/avtar_4.png'
import avatars6 from '../../../../assets/images/avatars/avtar_5.png'
import logo from '../../../../assets/images/Logo-6.png'
// logo
// import Logo from '../../components/logo'

// store
import {NavbarstyleAction, getDirMode, SchemeDirAction,  getNavbarStyleMode, getSidebarActiveMode, SidebarActiveStyleAction, getDarkMode, ModeAction,  SidebarColorAction, getSidebarColorMode, getSidebarTypeMode, FullScreen} from '../../../../store/setting/setting'
import {connect} from "react-redux"

const mapStateToProps = (state) => {
    return {
        darkMode: getDarkMode(state),
        schemeDirMode: getDirMode(state),
        sidebarcolorMode: getSidebarColorMode(state),
        sidebarTypeMode: getSidebarTypeMode(state),
        sidebaractivestyleMode: getSidebarActiveMode(state),
        navbarstylemode: getNavbarStyleMode(state),
    };
}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            ModeAction,
            SchemeDirAction,
            SidebarColorAction,
            SidebarActiveStyleAction,
            NavbarstyleAction,
            FullScreen,
        },
        dispatch
    )
})


const Header = (props) => {
    
    //state user
    const [user, setUser] = useState({});
    //define history
    const history = useHistory();
    //token
    const token = localStorage.getItem("token");
    const decode = token != 'initoken' ? jwtDecode(token) : '';

    useEffect(() => {
        // navbarstylemode
        const navbarstyleMode1 = sessionStorage.getItem('Navbarstyle-mode');
        if(navbarstyleMode1===null){
            props.NavbarstyleAction(props.navbarstylemode);
        }
        else{
            props.NavbarstyleAction(navbarstyleMode1);
        }

        // alert('h '+token);
        //check token empty
        if(!token || token == 'initoken') {
            //redirect login page
            logoutHanlder();
        }
        
        //call function "fetchData"
        // fetchData();
    },[]);

    //function "fetchData"
    // const fetchData = async () => {

    //     //set axios header dengan type Authorization + Bearer token
    //     axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    //     //fetch user from Rest API
    //     await axios.get('http://localhost:8000/api/user')
    //     .then((response) => {

    //         //set response user to state
    //         setUser(response.data);
    //     })
    // }

    // CHANGE PASSWORD    
    const [changepw, showModal] = useState(false);
    const closeModal = () => showModal(false);
    const handleModal = () => showModal(true);
    const [username, setUsername] = useState(decode.username);
    const [codeuser, setCodeUser] = useState(decode.code);
    const [oldpassword, setOldPassword] = useState('');
    const [newpassword, setNewPassword] = useState('');
    const [confpassword, setConfPassword] = useState('');

    const changePassword = () => {
        if (newpassword !== confpassword) {
            pwnotmatch('Konfirmasi Password Tidak Sesuai, Ulangi Lagi !');
            return;
        }

        if (oldpassword == '' || newpassword == '' || confpassword == '') {
            pwnotmatch('Mohon Lengkapi Form Ubah Password !');
            return;
        }

        axios.post(`https://api.alazhar2.com/node/auth/changePassword`, {
            username,
            old_password: oldpassword,
            new_password: newpassword,
            code: codeuser
        }, { headers: {"Authorization" : `Bearer ${token}`}})
        .then((response) => {
            if (response.data.error) {
                pwnotmatch('Password Lama Tidak Sesuai !');
                return;
            } else {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Password berhasil diubah, silahkan login ulang',
                    icon: 'success'
                }).then((result) => {
                    logoutHanlder();
                });
            }
        })
        .catch((error) => {
            alert('error',error.response)
        })
    }

    const pwnotmatch = (text) => {
        Swal.fire({
            title: 'Gagal',
            text: text,
            icon: 'error'
        }).then((result) => {
            setNewPassword('');
            setConfPassword('');
        });
    }

    const { logout } = useContext(AuthContext);
    //function logout
    const logoutHanlder = async () => {

        //set axios header dengan type Authorization + Bearer token
        // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        //fetch Rest API
        // await axios.post('http://localhost:8000/api/logout')
        // .then(() => {

            //remove token from localStorage
            localStorage.removeItem("token");

            //redirect halaman login
            history.push('/');
            // logout()
        // });
    };
    
    const minisidebar =() =>{
        document.getElementsByTagName('ASIDE')[0].classList.toggle('sidebar-mini')
    }

    const lightdark = props.darkMode;
    let themeMode;
    if (lightdark === 'light') {
        themeMode = <Dropdown as="li" className="nav-item">
                        <Dropdown.Toggle as={CustomToggle} variant="nav-link" id="darkmode" onClick={() => {props.ModeAction('dark')}}  data-setting="color-mode" data-name="color" data-value="dark">
                            <svg width="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill="currentColor" d="M9,2C7.95,2 6.95,2.16 6,2.46C10.06,3.73 13,7.5 13,12C13,16.5 10.06,20.27 6,21.54C6.95,21.84 7.95,22 9,22A10,10 0 0,0 19,12A10,10 0 0,0 9,2Z" />
                            </svg>
                        </Dropdown.Toggle>
                    </Dropdown>;
    } else {
        themeMode = <Dropdown as="li" className="nav-item">
                        <Dropdown.Toggle as={CustomToggle} variant="nav-link" id="lightmode" onClick={() => {props.ModeAction('light')}}  data-setting="color-mode" data-name="color" data-value="light">
                            <svg width="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill="currentColor" d="M12,8A4,4 0 0,0 8,12A4,4 0 0,0 12,16A4,4 0 0,0 16,12A4,4 0 0,0 12,8M12,18A6,6 0 0,1 6,12A6,6 0 0,1 12,6A6,6 0 0,1 18,12A6,6 0 0,1 12,18M20,8.69V4H15.31L12,0.69L8.69,4H4V8.69L0.69,12L4,15.31V20H8.69L12,23.31L15.31,20H20V15.31L23.31,12L20,8.69Z" />
                            </svg>
                        </Dropdown.Toggle>
                    </Dropdown>;
    }
    
    return (
        <>
            <Navbar expand="lg" variant="light" className="nav iq-navbar">
                <Container fluid className="navbar-inner">
                    <Link to="/dashboard" className="navbar-brand">
                        {/* <Logo color={true} /> */}
                        <img src={logo} width="30" height="30" /> 
                        <h4 className="logo-title">Al - Azhar</h4>
                    </Link>
                    <div className="sidebar-toggle" data-toggle="sidebar" data-active="true" onClick={minisidebar}>
                        <i className="icon">
                            <svg width="20px" height="20px" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                            </svg>
                        </i>
                    </div>
                    {/* <div className="input-group search-input">
                        <span className="input-group-text" id="search-input">
                            <svg width="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="11.7669" cy="11.7666" r="8.98856" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></circle>
                                <path d="M18.0186 18.4851L21.5426 22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                        <input type="search" className="form-control" placeholder="Search..."/>
                    </div> */}
                    <Navbar.Toggle aria-controls="navbarSupportedContent">
                        <span className="navbar-toggler-icon">
                            <span className="mt-2 navbar-toggler-bar bar1"></span>
                            <span className="navbar-toggler-bar bar2"></span>
                            <span className="navbar-toggler-bar bar3"></span>
                        </span>
                    </Navbar.Toggle>
                    <Navbar.Collapse  id="navbarSupportedContent">
                        <Nav as="ul" className="mb-2 ms-auto navbar-list mb-lg-0">
                            {/* <Dropdown as="li" className="nav-item">
                                <Dropdown.Toggle as={CustomToggle}   variant="search-toggle nav-link">
                                    <img src={flag1} className="img-fluid rounded-circle" alt="user" style={{height: "30px", minWidth: "30px", width: "30px",}}/>
                                    <span className="bg-primary"></span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="p-0 sub-drop dropdown-menu-end">
                                    <div className="m-0 border-0 shadow-none card">
                                        <div className="p-0 ">
                                            <ul className="list-group list-group-flush">
                                                <li className="iq-sub-card list-group-item"><Link className="p-0" to="#"><img src={flag3} alt="img-flaf" className="img-fluid me-2" style={{width: "15px", height: "15px", minWidth: "15px",}}/>Italian</Link></li>
                                                <li className="iq-sub-card list-group-item"><Link className="p-0" to="#"><img src={flag4} alt="img-flaf" className="img-fluid me-2" style={{width: "15px", height: "15px", minWidth: "15px",}}/>French</Link></li>
                                                <li className="iq-sub-card list-group-item"><Link className="p-0" to="#"><img src={flag2} alt="img-flaf" className="img-fluid me-2" style={{width: "15px", height: "15px", minWidth: "15px",}}/>German</Link></li>
                                                <li className="iq-sub-card list-group-item"><Link className="p-0" to="#"><img src={flag5} alt="img-flaf" className="img-fluid me-2" style={{width: "15px", height: "15px", minWidth: "15px",}}/>Spanish</Link></li>
                                                <li className="iq-sub-card list-group-item"><Link className="p-0" to="#"><img src={flag6} alt="img-flaf" className="img-fluid me-2" style={{width: "15px", height: "15px", minWidth: "15px",}}/>Japanese</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown> */}
                            {/* <Dropdown as="li" className="nav-item">
                                <Dropdown.Toggle as={CustomToggle}  href="#"   variant=" nav-link" id="notification-drop" data-bs-toggle="dropdown" >
                                    <svg width="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M12 17.8476C17.6392 17.8476 20.2481 17.1242 20.5 14.2205C20.5 11.3188 18.6812 11.5054 18.6812 7.94511C18.6812 5.16414 16.0452 2 12 2C7.95477 2 5.31885 5.16414 5.31885 7.94511C5.31885 11.5054 3.5 11.3188 3.5 14.2205C3.75295 17.1352 6.36177 17.8476 12 17.8476Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M14.3889 20.8572C13.0247 22.3719 10.8967 22.3899 9.51953 20.8572" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                    <span className="bg-danger dots"></span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="p-0 sub-drop dropdown-menu-end" aria-labelledby="notification-drop">
                                    <div className="m-0 shadow-none card">
                                        <div className="py-3 card-header d-flex justify-content-between bg-primary">
                                            <div className="header-title">
                                            <h5 className="mb-0 text-white">All Notifications</h5>
                                            </div>
                                        </div>
                                        <div className="p-0 card-body">
                                            <Link to="#" className="iq-sub-card">
                                                <div className="d-flex align-items-center">
                                                    <img className="p-1 avatar-40 rounded-pill bg-soft-primary" src={shapes1} alt=""/>
                                                    <div className="ms-3 w-100">
                                                        <h6 className="mb-0 ">Emma Watson Bni</h6>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p className="mb-0">95 MB</p>
                                                            <small className="float-right font-size-12">Just Now</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="#" className="iq-sub-card">
                                                <div className="d-flex align-items-center">
                                                    <div className="">
                                                        <img className="p-1 avatar-40 rounded-pill bg-soft-primary" src={shapes2} alt=""/>
                                                    </div>
                                                    <div className="ms-3 w-100">
                                                        <h6 className="mb-0 ">New customer is join</h6>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p className="mb-0">Cyst Bni</p>
                                                            <small className="float-right font-size-12">5 days ago</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="#" className="iq-sub-card">
                                                <div className="d-flex align-items-center">
                                                    <img className="p-1 avatar-40 rounded-pill bg-soft-primary" src={shapes3} alt=""/>
                                                    <div className="ms-3 w-100">
                                                        <h6 className="mb-0 ">Two customer is left</h6>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p className="mb-0">Cyst Bni</p>
                                                            <small className="float-right font-size-12">2 days ago</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="#" className="iq-sub-card">
                                                <div className="d-flex align-items-center">
                                                    <img className="p-1 avatar-40 rounded-pill bg-soft-primary" src={shapes4} alt=""/>
                                                    <div className="w-100 ms-3">
                                                        <h6 className="mb-0 ">New Mail from Fenny</h6>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p className="mb-0">Cyst Bni</p>
                                                            <small className="float-right font-size-12">3 days ago</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown> */}
 
                            {/* {themeMode} */}

                            {/* <Dropdown as="li" className="nav-item">
                                <Dropdown.Toggle as={CustomToggle} onClick={() => {props.FullScreen()}} variant="nav-link" id="full-screen">
                                    <svg width="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill="currentColor" d="M9.5,13.09L10.91,14.5L6.41,19H10V21H3V14H5V17.59L9.5,13.09M10.91,9.5L9.5,10.91L5,6.41V10H3V3H10V5H6.41L10.91,9.5M14.5,13.09L19,17.59V14H21V21H14V19H17.59L13.09,14.5L14.5,13.09M13.09,9.5L17.59,5H14V3H21V10H19V6.41L14.5,10.91L13.09,9.5Z"></path>
                                    </svg>
                                </Dropdown.Toggle>
                            </Dropdown> */}
                            
                            <Dropdown as="li" className="nav-item">
                                <Dropdown.Toggle as={CustomToggle} variant=" nav-link py-0 d-flex align-items-center" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src={avatars1} alt="User-Profile" className="theme-color-default-img img-fluid avatar avatar-50 avatar-rounded"/>
                                    <div className="caption ms-3 d-none d-md-block ">
                                        <h6 className="mb-0 caption-title">{decode.first_name} {decode.last_name}</h6>
                                        <p className="mb-0 caption-sub-title">
                                            {decode.role} &nbsp;
                                            <svg width="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19 8.5L12 15.5L5 8.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </p>
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu  className="dropdown-menu-end" aria-labelledby="navbarDropdown">
                                    <Dropdown.Item onClick={handleModal}>
                                        <svg width="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg> &nbsp;
                                        Change Password
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={logoutHanlder}>
                                        <svg width="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.016 7.38948V6.45648C15.016 4.42148 13.366 2.77148 11.331 2.77148H6.45597C4.42197 2.77148 2.77197 4.42148 2.77197 6.45648V17.5865C2.77197 19.6215 4.42197 21.2715 6.45597 21.2715H11.341C13.37 21.2715 15.016 19.6265 15.016 17.5975V16.6545" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M21.8096 12.0215H9.76855" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M18.8813 9.1062L21.8093 12.0212L18.8813 14.9372" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg> &nbsp;
                                        Logout
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <Modal show={changepw} onHide={closeModal}>
                <Modal.Header closeButton className={props.darkMode}>
                    <Modal.Title>Change My Password</Modal.Title>
                </Modal.Header>
                <Modal.Body className={props.darkMode}>
                    <Form.Group className="mb-3">
                        <Form.Label>Old Password</Form.Label>
                        <Form.Control type="password" onChange={(e) => setOldPassword(e.target.value)}/>
                    </Form.Group>
                    <Row className="mb-3">
                        <Col md="6">
                            <Form.Group>
                                <Form.Label>New Password</Form.Label>
                                <Form.Control type="password" value={newpassword} onChange={(e) => setNewPassword(e.target.value)}/>
                            </Form.Group>
                        </Col>
                        <Col md="6">
                            <Form.Group>
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control type="password" value={confpassword} onChange={(e) => setConfPassword(e.target.value)}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Button variant="primary" onClick={changePassword}>
                        Save
                    </Button>
                    {' '}
                    <Button variant="danger" onClick={closeModal}>
                        Cancel
                    </Button>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)

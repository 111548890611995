import React,{useState,useEffect,useRef} from 'react'
import {Row,Col,Image,Modal,Form,Button,InputGroup,FormControl} from 'react-bootstrap'
import {Link,useHistory} from 'react-router-dom'
import Swal from 'sweetalert2'
import Card from '../../../components/Card'
import '../../../index.css'
import {bindActionCreators} from "redux"
import axios from 'axios'
import DataTable from 'react-data-table-component';
import jwtDecode from 'jwt-decode'

// Dropzone
import { useDropzone } from 'react-dropzone'
import { FileUploader } from 'react-drag-drop-files'
// store
import {NavbarstyleAction, getDirMode, getcustomizerMode, getcustomizerprimaryMode,  getcustomizerinfoMode,  SchemeDirAction, ColorCustomizerAction,  getNavbarStyleMode, getSidebarActiveMode, SidebarActiveStyleAction, getDarkMode, ModeAction,  SidebarColorAction, getSidebarColorMode, getSidebarTypeMode} from '../../../store/setting/setting'
import {connect} from "react-redux"

const mapStateToProps = (state) => {
   return {
       darkMode: getDarkMode(state),
       colorprimarymode: getcustomizerprimaryMode(state),
   };
}

const Soal = (props) => {
   const [Soal, showSoal] = useState(false);
   const closeSoal = () => showSoal(false);
   const modalSoal = () => showSoal(true);

   const [editSoal, showEdit] = useState(false);
   const closeEdit = () => {
      showEdit(false);
      cancelSoal();
   };
   const modalEdit = () => showEdit(true);

   const token = localStorage.getItem("token");
   const decode = jwtDecode(token);
   const [pending, setPending] = useState(true);

   const [rows, setRows] = useState([
       { nomor: '', code: '', name: '', type: '', jenis: '', createdtime: ''}
   ])
   const [backup, setBackup] = useState([
       { nomor: '', code: '', name: '', type: '', jenis: '', createdtime: ''}
   ])

   const columns = [
      {
         name: 'No.',
         selector: row => row['nomor'],
         sortable: true,
      },
      {
         name: 'Nama Soal',
         selector: row => row['name'],
         sortable: true,
      },
      {
         name: 'Tipe Soal',
         selector: row => row['type'],
         sortable: true,
      },
      {
         name: 'Jenis Soal',
         selector: row => row['jenis'],
         sortable: true,
      },
      {
         name: 'Tanggal Dibuat',
         selector: row => row['createdtime'],
         sortable: true,
      },
      {
         name: 'Aksi',
         button: true,
         minWidth: '15%',
         cell: row => (
            <div>
               <span className="btn btn-sm btn-icon text-warning flex-end" data-bs-toggle="tooltip" title="Buat Pertanyaan" onClick={() => setData(row)}>
                  <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                     <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                     <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                     <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
               </span>
               {decode.role != 'admin' ? <></> :
                  <>
                  <span className="btn btn-sm btn-icon text-danger" data-bs-toggle="tooltip" title="Hapus Soal" onClick={() => onDelete(row.code)}>
                     <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                        <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                     </svg>
                  </span>
                  </>
               }
            </div>
         )
      },
   ];

   // Variable Question
   const [code, setCode] = useState('');
   const [name, setName] = useState('');
   const [page, setPage] = useState('');
   const [type, setType] = useState('');
   const [jenis, setJenis] = useState('');
   const [isPG, PGset] = useState('PG');
   const [APIData, setAPIData] = useState([]);

   // Variable Question Detail
   const [codeSoal, setCodeSoal] = useState('');
   const [soal, setSoal] = useState('');
   const [noSoal, setNoSoal] = useState('');
   const [apilistsoal, setAPIListSoal] = useState([]);
   const [newsoal, setNewSoal] = useState(true);
   const [bobot, setBobot] = useState(1);
   const [gambarSoal, setImages] = useState(null);
   const [typePG, setTypePG] = useState('PG');
   const [typeCheck, setTypeCheck] = useState('radio');

   // Variable Question Answer
   const [inputFields, setInputFields] = useState([
      { key_answer: '', value_answer: '', right_answer: false }
   ])

   // Function for set Input Fields Answer
   const handleFormChange = (index, event) => {
      let data = [...inputFields];

      if (event.target.type === "radio") {
         for (let i = 0; i < data.length; i++) {
            data[i]['right_answer'] = false;
         }
      }

      data[index][event.target.name] = event.target.type === "radio" || event.target.type === "checkbox" ? event.target.checked : event.target.value;
      data[index]['key_answer'] = String.fromCharCode(index + 97);
      setInputFields(data);
   }
   

   // Variable Question True False
   const [inputFieldsTF, setInputFieldsTF] = useState([
      { key_answer: 'a', value_answer: 'Benar', right_answer: false },
      { key_answer: 'b', value_answer: 'Salah', right_answer: false }
   ])

   // Function for set Input Fields Answer
   const handleFormChangeTF = (index, event) => {
      let data = [...inputFieldsTF];

      data[index][event.target.name] = event.target.checked;
      data[index]['key_answer'] = String.fromCharCode(index + 97);
      setInputFieldsTF(data);
   }
   
   // Function for Dynamic Add Answer
   const addFields = () => {
      let newfield = { key_answer: '', value_answer: '', right_answer: false }
      setInputFields([...inputFields, newfield])
   }

   // Function for Dyanamic Remove Answer
   const removeFields = (index) => {
      let data = [...inputFields];
      data.splice(index, 1)
      setInputFields(data)
   } 

   useEffect(() => {
      // Get Data
      getData();
   }, []);
   
   // Get Data
   const getData = async (e) => {
      const res = await axios.get('https://api.alazhar2.com/node/admin/question', { headers: {"Authorization" : `Bearer ${token}`}});
      const query = res.data.data.map((item,index) => ({...item, 
         nomor: index+1+".", 
         code: item.code, 
         name: item.name,
         type: item.type == 'PG' ? 'Pilihan Ganda' : item.type,
         jenis: item.jenis,
         createdtime: item.createdtime
      }));
      setRows(query);
      setBackup(query);
   }

   // API Get Data After Add
   const nextStep = async (code) => {
      const res = await axios.get('https://64250b099e0a30d92b27e7cc.mockapi.io/ebook_soal?code='+code, { headers: {"Authorization" : `Bearer ${token}`}});
      setData(res.data);
   }

   // API Get List Soal
   const listSoal = async (code) => {
      const res = await axios.get('https://api.alazhar2.com/node/admin/question/detail?questionCode='+code, { headers: {"Authorization" : `Bearer ${token}`}});
      setAPIListSoal(res.data.data);
   }

   // API Set Soal
   const getDetSoal = async (data) => {
      cancelSoal();
      setCodeSoal(data.code);
      setNoSoal(data.runningnumber);
      setBobot(data.weight);
      setSoal(data.question);
      setTypePG(data.type);
      setTypeCheck(data.type == 'PG' ? 'radio' : 'checkbox')
      setNewSoal(false); 
      removeFields(0); 
      setImages(data.images);

      const res = await axios.get('https://api.alazhar2.com/node/admin/question/answer?detailCode='+data.code, { headers: {"Authorization" : `Bearer ${token}`}});
      const tempArr = res.data.data.map(item => ({...item, 
         key_answer: item.key_answer, 
         value_answer: item.value_answer, 
         right_answer: item.right_answer == 'true' ? true : false
      }))
      if (data.type == "Benar Salah") {
         setInputFieldsTF(tempArr);         
      } else {
         setInputFields(tempArr);
      }
   }

   // Set Data for Edit
   const setData = (data) => {
      modalEdit();
      let { code, name, page, type } = data;
      if(type == "Pilihan Ganda") {
         type = "PG";
      }
      setCode(code);
      setName(name);
      setPage(page);
      setType(type);
      listSoal(code);
   }

   // Post Data Header
   const postData = () => {
      axios.post(`https://api.alazhar2.com/node/admin/question/add`, {
         nama: name,
         type,
         jenis
      }, { headers: {"Authorization" : `Bearer ${token}`}})
      .then((response) => {
         closeSoal();
         getData();
         // nextStep(code);
      })
   }
   
   // Add Soal
   const submitSoal = (e) => {
      // e.preventDefault();

      if (soal === '') {
         Swal.fire({
            title: 'Mohon Masukkan Pertanyaan.',
            icon: 'warning'
         })
         return;
      }

      if (noSoal == '') {
         var runningnumber = apilistsoal.length + 1;
      } else {
         var runningnumber = noSoal;
      }

      var tipesoal = type;
      if (type == 'PG') {
         tipesoal = typePG;
      } 

      const formData = new FormData();
      formData.append("code", codeSoal);
      formData.append("questionCode", code);
      formData.append("question", soal);
      formData.append("runningnumber", runningnumber);
      formData.append("weight", bobot);
      formData.append("type", tipesoal);
      formData.append("images", gambarSoal);

      // console.log(formData);
      // return;

      axios.post(`https://api.alazhar2.com/node/admin/question/detail/add`, 
         formData, 
         { headers: {"Authorization" : `Bearer ${token}`}}
      ).then((res) => {
         if (type === 'Essay') {
            listSoal(code);
            cancelSoal();
         } else {
            submitAnswer(res.data.code, code);
         }
      })
   }  

   // Function for add Answer
   const submitAnswer = (codeSoal, code) => {
      if (type == 'Benar Salah') {
         var panjang = inputFieldsTF.length;
      } else {
         var panjang = inputFields.length;
      }

      sendAnswer(0,panjang,codeSoal,code);
   }

   const sendAnswer = (curr, end, codeSoal, code) => {
      if (type == 'Benar Salah') {
         var item = inputFieldsTF[curr];
      } else {
         var item = inputFields[curr];
      }

      axios.post(`https://api.alazhar2.com/node/admin/question/answer/add`, 
         {detailCode: codeSoal, key_answer: item.key_answer, value_answer: item.value_answer, right_answer: item.right_answer, question_code: code}, 
         { headers: {"Authorization" : `Bearer ${token}`}}
      ).then((res) => {
         curr = curr+1;
         if (curr < end) {
            sendAnswer(curr, end, codeSoal, code);
         } else {
            listSoal(code);
            cancelSoal();
         }
      })
   }


   // creating the ref for file input
   const inputRef = useRef(null);
   // Function for Reset Soal
   const cancelSoal = () => {
      // resetting the input value
      inputRef.current.value = null;
      setNewSoal(true);
      setCodeSoal('');
      setNoSoal('');
      setSoal('');
      setBobot(1);
      setTypePG('PG');
      setInputFields([
         { key_answer: '', value_answer: '', right_answer: false }
      ])
      setInputFieldsTF([
         { key_answer: 'a', value_answer: 'Benar', right_answer: false },
         { key_answer: 'b', value_answer: 'Salah', right_answer: false }
      ])
   }

   const tipeCheck = (tipepg) => {
      setTypePG(tipepg);
      if (tipepg == 'PG') {
         setTypeCheck('radio');
      } else {
         setTypeCheck('checkbox');
      }
   }

   // Update Data
   const updateData = async (e) => {
      axios.put(`https://64250b099e0a30d92b27e7cc.mockapi.io/ebook_soal/${code}`, {
         code,
         name,
         page
      }, { headers: {"Authorization" : `Bearer ${token}`}})
      .then((response) => {
         getData();
         closeEdit();
      })
   }

   // Sweetalert Delete
   const onDelete  = (code) => {
      Swal.fire({
         title: 'Data Ingin Dihapus ?',
         text: "Data Yang Dihapus Tidak Dapat Dikembalikan Lagi !",
         icon: 'warning',
         showCancelButton: true,
         confirmButtonText: 'Ok',
         cancelButtonText: 'Cancel',
         reverseButtons: true
      }).then((result) => {
         if (result.isConfirmed) {
            axios.delete(
               `https://api.alazhar2.com/node/admin/question/delete?code=${code}`, 
               { headers: {"Authorization" : `Bearer ${token}`}}
            ).then((response) => {
               Swal.fire(
                  'Deleted!',
                  'Your data has been deleted.',
                  'success'
               )
               getData();
            })
         }
      });
   }

   // Hapus Soal
   const delSoal = (codeSoal) => {
      Swal.fire({
         title: 'Yakin Ingin Menghapus Soal ?',
         text: "Data Yang Dihapus Tidak Dapat Dikembalikan Lagi !",
         icon: 'warning',
         showCancelButton: true,
         confirmButtonText: 'Ok',
         cancelButtonText: 'Cancel',
         reverseButtons: true
      }).then((result) => {
         if (result.isConfirmed) {
            axios.delete(`https://api.alazhar2.com/node/admin/question/detail/delete?code=${codeSoal}`, { headers: {"Authorization" : `Bearer ${token}`}})
            .then((response) => {
               Swal.fire(
                  'Deleted!',
                  'Your data has been deleted.',
                  'success'
               )
               listSoal(code);
               cancelSoal();
            })
         }
      });
   }

   const [filterText, setFilterText] = useState('');
   const cari = (val) => {
      var data = backup;
      var filteredItems = data.filter(
         item => {
            if (item.name && item.name.toLowerCase().includes(val.toLowerCase())) {
               return true;
            } 
            else if (item.type && item.type.toLowerCase().includes(val.toLowerCase())) {
               return true;
            }
   
            return false;
         }
      )

      if (val == '') {
         setRows(backup);
      } else {
         setRows(filteredItems);
      }
   }

   const handleClear = () => {
      setFilterText('');
      setRows(backup);
   }
   
   return (
      <>
         <Row>
            <Col sm="12">
               <Card>
                  <Card.Header className="d-flex justify-content-between">
                     <div className="header-title">
                        <h4 className="card-title">Soal</h4>
                        <p>Overview Soal E-Book</p>
                     </div>
                     <div>
                        <Button className="text-center btn-primary btn-icon mt-lg-0 mt-md-0 mt-3" onClick={modalSoal}>
                           <i className="btn-inner">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                              </svg>
                           </i>
                           <span>Tambah Soal</span>
                        </Button>
                     </div>
                  </Card.Header>
                  <Card.Body>
                     <Form.Group className='d-flex mb-3 w-100' style={{justifyContent: 'end', alignContent: 'center'}}>
                        <Form.Control
                           id="search"
                           type="text"
                           placeholder="Cari..."
                           aria-label="Search Input"
                           value={filterText}
                           onChange={(e) => {cari(e.target.value); setFilterText(e.target.value);}}
                           className='me-2 w-25'
                        />
                        <Button variant="secondary" type="button" title="Reset" onClick={handleClear}>
                           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" fill="currentColor" id="reload">
                              <path d="M21,11a1,1,0,0,0-1,1,8.05,8.05,0,1,1-2.22-5.5h-2.4a1,1,0,0,0,0,2h4.53a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4.77A10,10,0,1,0,22,12,1,1,0,0,0,21,11Z"></path>
                           </svg>
                        </Button>
                     </Form.Group>
                     <div className="table-responsive">
                        <DataTable
                           columns={columns}
                           data={rows}
                           pagination
                           highlightOnHover
                        />
                     </div>
                  </Card.Body>
               </Card>
            </Col>
         </Row>
         
         <Modal show={Soal} onHide={closeSoal}>
            <Modal.Header closeButton className={props.darkMode}>
               <Modal.Title>Tambah Soal</Modal.Title>
            </Modal.Header>
            <Modal.Body className={props.darkMode}>
               <Form.Group className="mb-3">
                  <Form.Label>Nama Soal</Form.Label>
                  <Form.Control type="text" id="name" onChange={(e) => setName(e.target.value)} />
               </Form.Group>
               <Form.Group className="mb-3">
                  <Form.Label>Tipe Soal</Form.Label>
                  <select className='form-control' id="type" onChange={(e) => setType(e.target.value)}>
                     <option value=''>Pilih Tipe</option>
                     <option value='Essay'>Essay</option>
                     <option value='PG'>Pilihan Ganda</option>
                     <option value='Benar Salah'>Benar Salah</option>
                  </select>
               </Form.Group>
               <Form.Group className="mb-3">
                  <Form.Label>Jenis Soal</Form.Label>
                  <select className='form-control' id="jenis" onChange={(e) => setJenis(e.target.value)}>
                     <option value=''>Pilih Jenis</option>
                     <option value='Latihan'>Soal Latihan</option>
                     <option value='Ujian'>Soal Ujian</option>
                  </select>
               </Form.Group>
            </Modal.Body>
            <Modal.Footer>   
               <Button variant="success" onClick={postData}>Simpan</Button>
               {' '}
               <Button variant="danger" onClick={closeSoal}>Batal</Button>
            </Modal.Footer>
         </Modal>

         <Modal fullscreen={true} show={editSoal} onHide={closeEdit}>
            <Modal.Header closeButton className={props.darkMode}>
               <Modal.Title>Buat Soal</Modal.Title>
            </Modal.Header>
            <Modal.Body className={props.darkMode}>
               <Row className="h-100">
                  <Col md="3">
                     <Card className='h-100'>
                        <Card.Header className="border border-bottom pb-3">
                           <h4>Detail Soal</h4>
                        </Card.Header>
                        <Card.Body>
                           <Form.Group className="mb-3 d-none">
                              <Form.Label>Kode Soal</Form.Label>
                              <Form.Control type="text" id="code" readOnly value={code} onChange={(e) => setCode(e.target.value)} />
                           </Form.Group>
                           <Form.Group className="mb-3">
                              <Form.Label>Nama Soal</Form.Label>
                              <Form.Control readOnly type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} />
                           </Form.Group>
                           <Form.Group className="mb-3">
                              <Form.Label>Tipe Soal</Form.Label>
                              <select className='form-control' id="type" disabled value={type} onChange={(e) => setType(e.target.value)}>
                                 <option value=''>Pilih Tipe</option>
                                 <option value='Essay'>Essay</option>
                                 <option value='PG'>Pilihan Ganda</option>
                                 <option value='Benar Salah'>Benar Salah</option>
                              </select>
                           </Form.Group>
                           <Form.Group className="mb-3">
                              <Form.Label>Jenis Soal</Form.Label>
                              <select className='form-control' id="type" disabled value={jenis} onChange={(e) => setJenis(e.target.value)}>
                                 <option value=''>Pilih Jenis</option>
                                 <option value='Latihan'>Soal Latihan</option>
                                 <option value='Ujian'>Soal Ujian</option>
                              </select>
                           </Form.Group>
                           <hr />
                           <Row>
                              {apilistsoal?.map((item,index) => (
                                 <Col sm="3" className='my-2 me-n4' key={item.code}>
                                    <Button className='w-100' style={{height:"50px"}} variant='secondary' onClick={() => getDetSoal(item)}>{item.runningnumber}</Button>
                                 </Col>
                              ))}
                           </Row>
                        </Card.Body>
                     </Card>
                  </Col>
                  <Col md="9">
                     <Card className='h-100'>
                        <Card.Header className="border border-bottom pb-3">
                           <h4>Masukkan Pertanyaan :</h4>
                        </Card.Header>
                        <Card.Body>
                           <Form.Control type="hidden" value={codeSoal} onChange={(e) => setCodeSoal(e.target.value)} />
                           <Form.Control type="hidden" readOnly value={noSoal} onChange={(e) => setNoSoal(e.target.value)} />
                           <Row className='mb-2'>
                              <Col md={3}>
                                 <Form.Group>
                                    <Form.Label>Bobot Soal :</Form.Label>
                                    <Form.Control name="bobot" type="number" placeholder='Masukkan Bobot' value={bobot} onChange={(e) => setBobot(e.target.value)} />
                                 </Form.Group>
                              </Col>
                              <Col md={5}>
                                 <Form.Group>
                                    <Form.Label>
                                       Gambar Soal (Opsional) : 
                                       {gambarSoal == '' || gambarSoal == null ? <></> : 
                                          <>
                                          &nbsp; <a href={"https://api.alazhar2.com/upload/question/"+code.replaceAll("/", "")+"/"+codeSoal.replaceAll("/", "")+".png"} target='_blank' className='text-info'>Preview</a>
                                          </>
                                       }
                                    </Form.Label>
                                    <Form.Control ref={inputRef} type="file" id="gambar" accept="image/*" onChange={(e) => setImages(e.target.files[0])}/>
                                 </Form.Group>
                              </Col>
                              <Col md={4}>
                                 {isPG !== type ? <></> : <>
                                    <Form.Group>
                                       <Form.Label>Pilih Tipe :</Form.Label>
                                       <Form.Select value={typePG} onChange={(e) => tipeCheck(e.target.value)}>
                                          <option value="PG">Pilihan Ganda Tunggal</option>
                                          <option value="PG Multi">Pilihan Ganda Multi</option>
                                       </Form.Select>
                                    </Form.Group>
                                    </>
                                 }
                              </Col>
                           </Row>
                           <Form.Group>
                              <Form.Label>Pertanyaan :</Form.Label>
                              <Form.Control name="pertanyaan" as="textarea" rows={4} placeholder='Masukkan Pertanyaan Disini' value={soal} onChange={(e) => setSoal(e.target.value)}/>
                           </Form.Group>
                           {isPG !== type ? <>
                                 {type == 'Benar Salah' ? 
                                    <>
                                       <hr />
                                       {inputFieldsTF.map((input, index) => {
                                          return (      
                                             <Row key={index} className='my-2 d-flex' style={{alignItems:'center'}}>         
                                                <Col md={1} className='text-center ps-0'>
                                                   <h3>{String.fromCharCode(index + 97).toUpperCase()}</h3>
                                                </Col>                
                                                <Col md={11} className='px-0 d-flex' style={{alignItems:'center'}}>
                                                   <Form.Check  
                                                      name="right_answer"
                                                      type="radio"
                                                      title="Pilih Jawaban"
                                                      checked={input.right_answer}
                                                      onChange={event => handleFormChangeTF(index, event)}
                                                      className='mx-1'
                                                   />
                                                   <Form.Label className='ms-2 pt-2'><h4>{input.value_answer}</h4></Form.Label> 
                                                </Col>  
                                             </Row>
                                          )
                                       })}
                                    </> :
                                    <></> 
                                 }
                              </> : <>
                              <hr/>                              
                              {inputFields.map((input, index) => {
                                 return (      
                                    <Row key={index} className='my-2 d-flex' style={{alignItems:'center'}}>         
                                       <Col md={1} className='text-center ps-0'>
                                          <h3>{String.fromCharCode(index + 97).toUpperCase()}</h3>
                                       </Col>                
                                       <Col md={11} className='px-0 d-flex' style={{alignItems:'center'}}>
                                          <Form.Check  
                                             name="right_answer"
                                             type={typeCheck}
                                             title="Pilih Jawaban"
                                             checked={input.right_answer}
                                             onChange={event => handleFormChange(index, event)}
                                             className='mx-1'
                                          />
                                          <Form.Control 
                                             name='value_answer'
                                             placeholder='Masukkan Jawaban'
                                             value={input.value_answer}
                                             onChange={event => handleFormChange(index, event)}
                                             className='mx-1'
                                          />    
                                          <Button variant='danger' size="sm" className='mx-1' title="Hapus Jawaban" onClick={() => removeFields(index)}>
                                             <h4 className='text-white'>X</h4>
                                          </Button>        
                                       </Col>  
                                    </Row>
                                 )
                              })}
                              <Row>
                                 <Col md={12} className='my-3 d-flex' style={{alignItems:'center', justifyContent:'center'}}>
                                    <Button variant='warning' onClick={addFields}>
                                    <svg width="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                                       <path d="M12.0001 8.32739V15.6537" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                       <path d="M15.6668 11.9904H8.3335" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                       <path fillRule="evenodd" clipRule="evenodd" d="M16.6857 2H7.31429C4.04762 2 2 4.31208 2 7.58516V16.4148C2 19.6879 4.0381 22 7.31429 22H16.6857C19.9619 22 22 19.6879 22 16.4148V7.58516C22 4.31208 19.9619 2 16.6857 2Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                    <span className='ms-2'>Tambah Jawaban</span>
                                    </Button>
                                 </Col>
                              </Row>
                           </> }
                           <hr />
                           <div className='d-flex mt-3' style={{alignItems:"center", justifyContent:"center"}}>
                              <Button variant="success" className='mx-1' title="Simpan Soal" onClick={submitSoal}>
                                 Simpan
                              </Button>
                              {newsoal ? <></> : 
                                 <>
                                 <Button variant="danger" className='mx-1' onClick={() => delSoal(codeSoal)} title="Hapus Soal">
                                    Hapus
                                 </Button>
                                 </>
                              }
                              <Button variant="secondary" className='mx-1' onClick={cancelSoal} title="Batal Ubah Soal">
                                 Batal
                              </Button>
                           </div>
                        </Card.Body>
                     </Card>
                  </Col>
               </Row>
            </Modal.Body>
            {/* <Modal.Footer className={props.darkMode}>   
               <Button variant="success" onClick={updateData}>Simpan</Button>
               {' '}
               <Button variant="danger" onClick={closeEdit}>Batal</Button>
            </Modal.Footer> */}
         </Modal>
      </>
   )
}

export default connect(mapStateToProps)(Soal)

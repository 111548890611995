import React,{useState,useEffect} from 'react'
import {Row,Col,Modal,Form,Button} from 'react-bootstrap'
import {Link,useHistory} from 'react-router-dom'
import Card from '../../../components/Card'
import {bindActionCreators} from "redux"

// store
import {NavbarstyleAction, getDirMode, getcustomizerMode, getcustomizerprimaryMode,  getcustomizerinfoMode,  SchemeDirAction, ColorCustomizerAction,  getNavbarStyleMode, getSidebarActiveMode, SidebarActiveStyleAction, getDarkMode, ModeAction,  SidebarColorAction, getSidebarColorMode, getSidebarTypeMode} from '../../../store/setting/setting'
import {connect} from "react-redux"

const mapStateToProps = (state) => {
   return {
      darkMode: getDarkMode(state),
      colorprimarymode: getcustomizerprimaryMode(state),
   };
}

const Data_table = [
   {
      id_notif: 'notif001',
      judul_notif: 'Notifikasi Baru',
      isi_notif: 'Segera periksa pemberitahuan terbaru',
      waktu_notif: '2023/01/01',
   },
]

const PushNotif = (props) => {
   const [pushnotif, showNotif] = useState(false);
   const closeNotif = () => showNotif(false);
   const modalNotif = () => showNotif(true);

   return (
      <>
         <Row>
            <Col sm="12">
               <Card>
                  <Card.Header className="d-flex justify-content-between">
                     <div className="header-title">
                        <h4 className="card-title">Notifikasi</h4>
                        <p>Overview Data Notifikasi</p>
                     </div>
                     <div>
                        <Button  className="text-center btn-primary btn-icon me-2 mt-lg-0 mt-md-0 mt-3" onClick={modalNotif}>
                           <i className="btn-inner">
                           <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path fillRule="evenodd" clipRule="evenodd" d="M12 17.8476C17.6392 17.8476 20.2481 17.1242 20.5 14.2205C20.5 11.3188 18.6812 11.5054 18.6812 7.94511C18.6812 5.16414 16.0452 2 12 2C7.95477 2 5.31885 5.16414 5.31885 7.94511C5.31885 11.5054 3.5 11.3188 3.5 14.2205C3.75295 17.1352 6.36177 17.8476 12 17.8476Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M14.3889 20.8572C13.0247 22.3719 10.8967 22.3899 9.51953 20.8572" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                           </svg>
                           </i>
                           {' '}
                           <span>Push Notifikasi</span>
                        </Button>
                     </div>
                  </Card.Header>
                  <Card.Body>
                     <div className="table-responsive">
                        <table id="tbl_notif" className="table table-striped" data-toggle="data-table">
                           <thead>
                              <tr>
                                 <th>ID Notifikasi</th>
                                 <th>Judul Notifikasi</th>
                                 <th>Isi Notifikasi</th>
                                 <th>Waktu</th>
                              </tr>
                           </thead>
                           <tbody>
                              {Data_table.map((item) => (
                                 <tr key={item.id_notif}>
                                    <td>{item.id_notif}</td>
                                    <td>{item.judul_notif}</td>
                                    <td>{item.isi_notif}</td>
                                    <td>{item.waktu_notif}</td>
                                 </tr>
                              ))}
                           </tbody>
                        </table>
                     </div>
                  </Card.Body>
               </Card>
            </Col>
         </Row>
         
         <Modal show={pushnotif} onHide={closeNotif}>
            <Modal.Header closeButton className={props.darkMode}>
               <Modal.Title>Tambah Notifikasi Apps</Modal.Title>
            </Modal.Header>
            <Modal.Body className={props.darkMode}>
               <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Judul</Form.Label>
                  <Form.Control type="text" placeholder="Masukkan Judul Notif" id="judul_notif" name='judul_notif' />
               </Form.Group>
               <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Deskripsi</Form.Label>
                  <textarea className='form-control' id='isi_notif' name='isi_notif' rows='3'></textarea>
               </Form.Group>
               <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>User</Form.Label>
                  <select className='form-control' id='user_target' name='user_target'>
                     <option value=''>Silahkan Pilih</option>
                     <option value='Guru'>Guru</option>
                     <option value='Siswa'>Siswa</option>
                  </select>
               </Form.Group>
               <Button variant="success" onClick={() =>{closeNotif()}}>
                  Save
               </Button>
               {' '}
               <Button variant="danger" onClick={closeNotif}>
                  Cancel
               </Button>
            </Modal.Body>
         </Modal>
      </>
   )
}

export default connect(mapStateToProps)(PushNotif)

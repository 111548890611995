import React from 'react'
import Index from '../views/dashboard/index'
import {Switch,Route} from 'react-router-dom'
// user
import UserProfile from '../views/dashboard/app/user-profile';
import UserAdd from '../views/dashboard/app/user-add';
import UserList from '../views/dashboard/app/user-list';
import userProfileEdit from '../views/dashboard/app/user-privacy-setting';
// widget
import Widgetbasic from '../views/dashboard/widget/widgetbasic';
import Widgetcard from '../views/dashboard/widget/widgetcard';
import Widgetchart from '../views/dashboard/widget/widgetchart';
// icon
import Solid from '../views/dashboard/icons/solid';
import Outline from '../views/dashboard/icons/outline';
import DualTone from '../views/dashboard/icons/dual-tone';
// Form
import FormElement from '../views/dashboard/from/form-element';
import FormValidation from '../views/dashboard/from/form-validation';
import FormWizard from '../views/dashboard/from/form-wizard';
// table
import BootstrapTable from '../views/dashboard/table/bootstrap-table';
import TableData from '../views/dashboard/table/table-data';

// kelas
import Kelas from '../views/dashboard/kelas/kelas';
import Guru from '../views/dashboard/kelas/guru';
import Siswa from '../views/dashboard/kelas/siswa';

// Ebook
import Kategori from '../views/dashboard/ebook/kategori';
import Ebook from '../views/dashboard/ebook/ebook';
import Soal from '../views/dashboard/ebook/soal';
import RekapSoal from '../views/dashboard/ebook/rekapsoal';

// Other
import ListUser from '../views/dashboard/other/listuser';
import LogsActivity from '../views/dashboard/other/logactivity';
import PushNotif from '../views/dashboard/other/pushnotif';

// map
import Vector from '../views/dashboard/maps/vector';
import Google from '../views/dashboard/maps/google';

//extra
import PrivacyPolicy from '../views/dashboard/extra/privacy-policy';
import TermsofService from '../views/dashboard/extra/terms-of-service';

//TransitionGroup
import {TransitionGroup,CSSTransition} from "react-transition-group";
//Special Pages
import Billing from '../views/dashboard/special-pages/billing';
import Kanban from '../views/dashboard/special-pages/kanban';
import Pricing from '../views/dashboard/special-pages/pricing';
import Timeline from '../views/dashboard/special-pages/timeline';
import Calender from '../views/dashboard/special-pages/calender';
//admin
import Admin from '../views/dashboard/admin/admin';

const DefaultRouter = () => {
    return (
        <TransitionGroup>
            <CSSTransition classNames="fadein" timeout={300}>
                <Switch>
                    <Route path="/dashboard" exact component={Index} />
                    {/* user */}
                    <Route path="/dashboard/app/user-profile"     exact component={UserProfile} />
                    <Route path="/dashboard/app/user-add"         exact component={UserAdd}/>
                    <Route path="/dashboard/app/user-list"        exact component={UserList}/>
                    <Route path="/dashboard/app/user-privacy-setting" exact component={userProfileEdit}/>
                     {/* widget */}
                     <Route path="/dashboard/widget/widgetbasic"   exact component={Widgetbasic}/>
                     <Route path="/dashboard/widget/widgetcard"    exact component={Widgetcard}/>
                     <Route path="/dashboard/widget/widgetchart"   exact component={Widgetchart}/>
                     {/* icon */}
                     <Route path="/dashboard/icon/solid"           exact component={Solid}/>
                     <Route path="/dashboard/icon/outline"         exact component={Outline}/>
                     <Route path="/dashboard/icon/dual-tone"       exact component={DualTone}/>
                     {/* From */}
                     <Route path="/dashboard/form/form-element"    exact component={FormElement}/>
                     <Route path="/dashboard/form/form-validation" exact component={FormValidation}/>
                     <Route path="/dashboard/form/form-wizard"     exact component={FormWizard}/>
                     {/* table */}
                     <Route path="/dashboard/table/bootstrap-table" exact component={BootstrapTable}/>
                     <Route path="/dashboard/table/table-data"      exact component={TableData}/>
                     {/* kelas */}
                     <Route path="/dashboard/kelas/kelas"      exact component={Kelas}/>
                     <Route path="/dashboard/kelas/guru"      exact component={Guru}/>
                     <Route path="/dashboard/kelas/siswa"      exact component={Siswa}/>
                     {/* ebook */}
                     <Route path="/dashboard/ebook/kategori"      exact component={Kategori}/>
                     <Route path="/dashboard/ebook/ebook"      exact component={Ebook}/>
                     <Route path="/dashboard/ebook/soal"      exact component={Soal}/>
                     <Route path="/dashboard/ebook/rekapsoal"      exact component={RekapSoal}/>
                     {/* other */}
                     <Route path="/dashboard/other/listuser"      exact component={ListUser}/>
                     <Route path="/dashboard/other/logactivity"      exact component={LogsActivity}/>
                     <Route path="/dashboard/other/pushnotif"      exact component={PushNotif}/>
                     {/*special pages */}
                     <Route path="/dashboard/special-pages/billing" exact component={Billing}/>
                     <Route path="/dashboard/special-pages/kanban" exact component={Kanban}/>
                     <Route path="/dashboard/special-pages/pricing" exact component={Pricing}/>
                     <Route path="/dashboard/special-pages/timeline" exact component={Timeline}/>
                     <Route path="/dashboard/special-pages/calender" exact component={Calender}/>
                     {/* map */}
                     <Route path="/dashboard/map/vector" exact component={Vector}/>
                     <Route path="/dashboard/map/google" exact component={Google}/>
                     {/* extra */}
                     <Route path="/dashboard/extra/privacy-policy" exact component={PrivacyPolicy}/>
                     <Route path="/dashboard/extra/terms-of-service" exact component={TermsofService}/>
                     {/*admin*/}
                     <Route path="/dashboard/admin/admin" exact component={Admin}/>
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    )
}

export default DefaultRouter

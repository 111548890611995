import React,{useState,useEffect} from 'react'
import {Row,Col,Modal,Form,Button} from 'react-bootstrap'
import {Link,useHistory} from 'react-router-dom'
import Swal from 'sweetalert2'
import Card from '../../../components/Card'
import '../../../index.css'
import {bindActionCreators} from "redux"
import axios from 'axios'
import DataTable from 'react-data-table-component';
import templatefile from '../../../assets/file/template_import_user.xlsx'
import '../../../assets/loading.css'
import jwtDecode from 'jwt-decode'

// Dropzone
import { useDropzone } from 'react-dropzone'
import { FileUploader } from 'react-drag-drop-files'
// store
import {NavbarstyleAction, getDirMode, getcustomizerMode, getcustomizerprimaryMode,  getcustomizerinfoMode,  SchemeDirAction, ColorCustomizerAction,  getNavbarStyleMode, getSidebarActiveMode, SidebarActiveStyleAction, getDarkMode, ModeAction,  SidebarColorAction, getSidebarColorMode, getSidebarTypeMode} from '../../../store/setting/setting'
import {connect} from "react-redux"

const mapStateToProps = (state) => {
   return {
      darkMode: getDarkMode(state),
      colorprimarymode: getcustomizerprimaryMode(state),
   };
}

const Guru = (props) => {   
   const [adduser, showAdd] = useState(false);
   const closeAdd = () => showAdd(false);
   const modalAdd = () => {
      showAdd(true);
      inpUsername('');
   }
   
   const [upduser, showUpd] = useState(false);
   const closeUpd = () => showUpd(false);
   const modalUpd = () => showUpd(true);

   const [importuser, showImport] = useState(false);
   const closeImport = () => showImport(false);
   const modalImport = () => showImport(true);

   const [id, setID] = useState(null);
   const [code, setCode] = useState('');
   const [nisn, setNisn] = useState('');
   const [username, setUsername] = useState('');
   const [first_name, setFirstName] = useState('');
   const [last_name, setLastName] = useState('');
   const [profile, setProfile] = useState('');
   const [status, setStatus] = useState('');
   
   const [APIData, setAPIData] = useState([]);
   const [optUsername, suggestUsername] = useState([]);
   const [inpuser, inpUsername] = useState('');
   const token = localStorage.getItem("token");
   const decode = jwtDecode(token);
   const [pending, setPending] = useState(true);
   const [loading, setLoading] = useState(false);

   const [rows, setRows] = useState([
       { nomor: '', code: '', name: '', first_name: '', last_name: '', username: '', profile: '', status: '', createdtime: ''}
   ])
   const [backup, setBackup] = useState([
       { nomor: '', code: '', name: '', first_name: '', last_name: '', username: '', profile: '', status: '', createdtime: ''}
   ])

   const columns = [
      {
         name: 'No.',
         selector: row => row['nomor'],
         maxWidth: '5%',
         sortable: true,
      },
      {
         name: 'NUPTK',
         selector: row => row['code'],
         sortable: true,
      },
      {
         name: 'Nama',
         selector: row => row['name'],
         sortable: true,
      },
      {
         name: 'Profile',
         selector: row => row['profile'],
         minWidth: '25%',
         sortable: true,
      },
      {
         name: 'Status',
         selector: row => row['status'],
         sortable: true,
      },
      {
         name: 'Tanggal Dibuat',
         selector: row => row['createdtime'],
         sortable: true,
      },
      {
         name: 'Aksi',
         button: true,
         minWidth: '15%',
         cell: (row) => (
            <div>
               <span className="btn btn-sm btn-icon text-warning flex-end" data-bs-toggle="tooltip" title="Edit Guru" onClick={() => setData(row)}>
                  <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                     <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                     <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                     <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
               </span>
               {decode.role != 'admin' ? <></> :
                  <>
                  <span className="btn btn-sm btn-icon text-danger" data-bs-toggle="tooltip" title="Hapus Guru" onClick={() => onDelete(row.code)}>
                     <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                        <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                     </svg>
                  </span>
                  </>
               }
            </div>
         )
      },
   ];

   useEffect(() => {
      // Get Data
      getData();
   }, []);
   
   // Get Data
   const getData = async (e) => {
      const res = await axios.get('https://api.alazhar2.com/node/admin/user?role=guru', { headers: {"Authorization" : `Bearer ${token}`}});
      const query = res.data.data.map((item,index) => ({...item, 
         nomor: index+1+".", 
         code: item.code, 
         name: item.first_name+' '+item.last_name,
         first_name: item.first_name,
         last_name: item.last_name,
         username: item.username,
         profile: item.profile,
         status: item.status == 1 ? 'Aktif' : 'Non Aktif',
         createdtime: item.createdtime
      }));
      setRows(query);
      setBackup(query);
   }

   // Post Data
   const postData = () => {
      axios.post(`https://api.alazhar2.com/node/admin/user/add`, {
         nisn,
         first_name,
         last_name,
         profile,
         role: 'guru',
         status
      }, { headers: {"Authorization" : `Bearer ${token}`}})
      .then((response) => {
         if (response.data.error){
            Swal.fire({
               title: response.data.response,
               text: "Mohon pilih username yang kami sarankan",
               icon: 'warning',
            })
            inpUsername('error');
            suggestUsername(response.data.suggestions);
         } else {
            closeAdd();
            getData();
         }
      })
      .catch((error) => {
         alert('error',error.response)
      })
   }

   // Set Data for Edit
   const setData = (data) => {
      modalUpd();
      let { code, username, first_name, last_name, profile, status } = data;
      if(status == "Aktif") {
         status = 1;
      } else {
         status = 0;
      }
      setCode(code);
      setNisn(code);
      setUsername(username);
      setFirstName(first_name);
      setLastName(last_name);
      setProfile(profile);
      setStatus(status);
   }

   // Update Data
   const updateData = async (e) => {
      axios.put(`https://api.alazhar2.com/node/admin/user/update`, {
         code,
         first_name,
         last_name,
         profile,
         status
      }, { headers: {"Authorization" : `Bearer ${token}`}})
      .then((response) => {
         closeUpd();
         getData();
      })
   }

   // Sweetalert Delete
   const onDelete  = (code) => {
      Swal.fire({
         title: 'Data Ingin Dihapus ?',
         text: "Data Yang Dihapus Tidak Dapat Dikembalikan Lagi !",
         icon: 'warning',
         showCancelButton: true,
         confirmButtonText: 'Ok',
         cancelButtonText: 'Cancel',
         reverseButtons: true
      }).then((result) => {
         if (result.isConfirmed) {
            axios.delete(
               `https://api.alazhar2.com/node/admin/user/delete?code=${code}`, 
               { headers: {"Authorization" : `Bearer ${token}`}}
            )
            .then((response) => {
               Swal.fire(
                  'Deleted!',
                  'Your file has been deleted.',
                  'success'
               )
               getData();
            })
         }
      });
   }

   // IMPORT USER
   const fileTypes = ["CSV", "XLS", "XLSX"];
   const [file, setFile] = useState(null);
   const handleChange = (file) => {
      setFile(file);
   };

   const submitImport = async (e) => {
      e.preventDefault();

      const formData = new FormData();
      formData.append("berkas", file);
      formData.append("type", 'guru');

      setLoading(true);
      try {
         axios.post(`https://api.alazhar2.com/node/admin/user/addBulk`, formData, { headers: {"Authorization" : `Bearer ${token}`}})
         .then((response) => {
            setLoading(false);
            getData();
            closeImport();
         })
      } catch(error) {
         console.log(error)
      }
   }

   const [filterText, setFilterText] = useState('');
   const cari = (val) => {
      var data = backup;
      var filteredItems = data.filter(
         item => {
            if (item.name && item.name.toLowerCase().includes(val.toLowerCase())) {
               return true;
            } 
            else if (item.code && item.code.toLowerCase().includes(val.toLowerCase())) {
               return true;
            }
            else if (item.profile && item.profile.toLowerCase().includes(val.toLowerCase())) {
               return true;
            }
            else if (item.status && item.status.toLowerCase().includes(val.toLowerCase())) {
               return true;
            }
   
            return false;
         }
      )

      if (val == '') {
         setRows(backup);
      } else {
         setRows(filteredItems);
      }
   }

   const handleClear = () => {
      setFilterText('');
      setRows(backup);
   }

   return (
      <>
         {loading ? 
            <div className='loadingpage'>
               <div className="book">
                  <div className="book__pg-shadow"></div>
                  <div className="book__pg"></div>
                  <div className="book__pg book__pg--2"></div>
                  <div className="book__pg book__pg--3"></div>
                  <div className="book__pg book__pg--4"></div>
                  <div className="book__pg book__pg--5"></div>
               </div>
            </div> : ''
         }

         <Row>
            <Col sm="12">
               <Card>
                  <Card.Header className="d-flex justify-content-between">
                     <div className="header-title">
                        <h4 className="card-title">Guru</h4>
                        <p>Overview Data Guru</p>
                     </div>
                     <div>
                        <Button  className="text-center btn-primary btn-icon me-2 mt-lg-0 mt-md-0 mt-3" onClick={modalImport}>
                           <i className="btn-inner me-1">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                 <path d="M12.1221 15.436L12.1221 3.39502" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M15.0381 12.5083L12.1221 15.4363L9.20609 12.5083" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M16.7551 8.12793H17.6881C19.7231 8.12793 21.3721 9.77693 21.3721 11.8129V16.6969C21.3721 18.7269 19.7271 20.3719 17.6971 20.3719L6.55707 20.3719C4.52207 20.3719 2.87207 18.7219 2.87207 16.6869V11.8019C2.87207 9.77293 4.51807 8.12793 6.54707 8.12793L7.48907 8.12793" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                              </svg>
                           </i>
                           <span>Import Guru</span>
                        </Button>
                        <Button  className="text-center btn-primary btn-icon mt-lg-0 mt-md-0 mt-3" onClick={modalAdd}>
                           <i className="btn-inner">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                              </svg>
                           </i>
                           <span>Tambah Guru</span>
                        </Button>
                     </div>
                  </Card.Header>
                  <Card.Body>
                     <Form.Group className='d-flex mb-3 w-100' style={{justifyContent: 'end', alignContent: 'center'}}>
                        <Form.Control
                           id="search"
                           type="text"
                           placeholder="Cari..."
                           aria-label="Search Input"
                           value={filterText}
                           onChange={(e) => {cari(e.target.value); setFilterText(e.target.value);}}
                           className='me-2 w-25'
                        />
                        <Button variant="secondary" type="button" title="Reset" onClick={handleClear}>
                           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" fill="currentColor" id="reload">
                              <path d="M21,11a1,1,0,0,0-1,1,8.05,8.05,0,1,1-2.22-5.5h-2.4a1,1,0,0,0,0,2h4.53a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4.77A10,10,0,1,0,22,12,1,1,0,0,0,21,11Z"></path>
                           </svg>
                        </Button>
                     </Form.Group>
                     <div className="table-responsive">
                        <DataTable
                           columns={columns}
                           data={rows}
                           pagination
                           highlightOnHover
                        />
                     </div>
                  </Card.Body>
               </Card>
            </Col>
         </Row>

         <Modal show={importuser} onHide={closeImport}>
            <Modal.Header closeButton className={props.darkMode}>
               <Modal.Title>Import Guru</Modal.Title>
            </Modal.Header>
            <Modal.Body className={props.darkMode}>
               <FileUploader
                  multiple={false}
                  handleChange={handleChange}
                  name="file"
                  types={fileTypes}
                  label=" DRAG AND DROP A FILE"
                  classes="py-5"
               />
               <div className='text-center mt-2'>
                  <p>{file ? `File name: ${file.name}` : "No Files Uploaded Yet"}</p>
               </div>
               <a className='w-100 mt-2 btn btn-primary' href={templatefile} download>Download File Template</a>
            </Modal.Body>
            <Modal.Footer className={props.darkMode}>
               <Button variant="success" onClick={submitImport}>
                  Simpan
               </Button>
               {' '}
               <Button variant="danger" onClick={closeImport}>
                  Batal
               </Button>
            </Modal.Footer>
         </Modal>
         
         <Modal show={adduser} onHide={closeAdd}>
            <Modal.Header closeButton className={props.darkMode}>
               <Modal.Title>Tambah Guru</Modal.Title>
            </Modal.Header>
            <Modal.Body className={props.darkMode}>
               <Form.Group className="mb-3">
                  <Form.Label>NUPTK</Form.Label>
                  <Form.Control type="number" id="nisn" placeholder="Masukkan NUPTK" onChange={(e) => setNisn(e.target.value)} />
               </Form.Group>
               <Form.Group className="mb-3">
                  <Form.Label>Nama Depan</Form.Label>
                  <Form.Control type="text" id="firstname" placeholder="Masukkan Nama Depan" onChange={(e) => setFirstName(e.target.value)} />
               </Form.Group>
               <Form.Group className="mb-3">
                  <Form.Label>Nama Belakang</Form.Label>
                  <Form.Control type="text" id="lastname" placeholder="Masukkan Nama Belakang" onChange={(e) => setLastName(e.target.value)} />
               </Form.Group>
               <Form.Group className="mb-3">
                  <Form.Label>Profile</Form.Label>
                  <Form.Control type="text" id="profile" placeholder="Masukkan Profile" onChange={(e) => setProfile(e.target.value)} />
               </Form.Group>
               {/* <Form.Group className="mb-3">
                  <Form.Label>Username</Form.Label>
                  {inpuser == '' ? 
                     <><Form.Control type="text" id="username" placeholder="Masukkan Username" onChange={(e) => setUsername(e.target.value)} /></> 
                     : 
                     <><select className='form-control' id="status" onChange={(e) => setUsername(e.target.value)}>
                        {optUsername?.map((item) => (
                           <>
                           <option value={item}>{item}</option>
                           </>
                        ))}
                     </select></>
                  }
               </Form.Group> */}
               <Form.Group className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <select className='form-control' id="status" onChange={(e) => setStatus(e.target.value)}>
                     <option value=''>Pilih Status</option>
                     <option value='1'>Aktif</option>
                     <option value='0'>Non Aktif</option>
                  </select>
               </Form.Group>
               <Button variant="success" onClick={postData}>Save</Button>
               {' '}
               <Button variant="danger" onClick={closeAdd}>Cancel</Button>
            </Modal.Body>
         </Modal>
         
         <Modal show={upduser} onHide={closeUpd}>
            <Modal.Header closeButton className={props.darkMode}>
               <Modal.Title>Update Guru</Modal.Title>
            </Modal.Header>
            <Modal.Body className={props.darkMode}>
               <Form.Group className="mb-3">
                  <Form.Label>NUPTK</Form.Label>
                  <Form.Control type="text" id="nisn" readOnly placeholder="Masukkan NUPTK" value={nisn} />
               </Form.Group>
               <Form.Group className="mb-3">
                  <Form.Label>Nama Depan</Form.Label>
                  <Form.Control type="text" id="firstname" placeholder="Masukkan Nama Depan" value={first_name} onChange={(e) => setFirstName(e.target.value)} />
               </Form.Group>
               <Form.Group className="mb-3">
                  <Form.Label>Nama Belakang</Form.Label>
                  <Form.Control type="text" id="lastname" placeholder="Masukkan Nama Belakang" value={last_name} onChange={(e) => setLastName(e.target.value)} />
               </Form.Group>
               <Form.Group className="mb-3">
                  <Form.Label>Profile</Form.Label>
                  <Form.Control type="text" id="profile" placeholder="Masukkan Profile" value={profile} onChange={(e) => setProfile(e.target.value)} />
               </Form.Group>
               {/* <Form.Group className="mb-3">
                  <Form.Label>Username</Form.Label>
                  <Form.Control type="text" id="username" readOnly placeholder="Masukkan Username" value={username} onChange={(e) => setLastName(e.target.value)} />
               </Form.Group> */}
               <Form.Group className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <select className='form-control' id="status" value={status} onChange={(e) => setStatus(e.target.value)}>
                     <option value=''>Pilih Status</option>
                     <option value='1'>Aktif</option>
                     <option value='0'>Non Aktif</option>
                  </select>
               </Form.Group>
               <Button variant="success" onClick={updateData}>Save</Button>
               {' '}
               <Button variant="danger" onClick={closeUpd}>Cancel</Button>
            </Modal.Body>
         </Modal>
      </>
   )
}

export default connect(mapStateToProps)(Guru)

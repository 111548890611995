import React,{useState,useEffect} from 'react'
import {Row,Col,Image,Modal,Form,Button,InputGroup,FormControl} from 'react-bootstrap'
import {Link,useHistory} from 'react-router-dom'
import Swal from 'sweetalert2'
import Card from '../../../components/Card'
import {bindActionCreators} from "redux"
import axios from 'axios'
import { Document, Page, pdfjs } from 'react-pdf';
import moment from 'moment';
import HTMLFlipBook from 'react-pageflip';
import DataTable from 'react-data-table-component';
import jwtDecode from 'jwt-decode';

import '../../../index.css'
import '../../../assets/loading.css'
import pdf from '../../../assets/images/pdf.pdf'
import imgLink from '../../../assets/images/dragable/link.png'
import imgImage from '../../../assets/images/dragable/image.png'
import imgVideo from '../../../assets/images/dragable/video.png'
import imgText from '../../../assets/images/dragable/text.png'
import avatars1 from '../../../assets/images/avatars/ava1.png'
import avatars2 from '../../../assets/images/avatars/ava2.png'
import avatars3 from '../../../assets/images/avatars/ava3.png'
import avatars4 from '../../../assets/images/avatars/01.png'
import iconmove from '../../../assets/images/icons/move.png'
import defaultBackground from '../../../assets/images/dashboard/background.jpg'

// Import Worker
import { Worker } from '@react-pdf-viewer/core';
// Import the main Viewer component
import { Viewer } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
// default layout plugin
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Import styles of default layout plugin
import '@react-pdf-viewer/default-layout/lib/styles/index.css';


// Dropzone
import { useDropzone } from 'react-dropzone'
import { FileUploader } from 'react-drag-drop-files'
// store
import {NavbarstyleAction, getDirMode, getcustomizerMode, getcustomizerprimaryMode,  getcustomizerinfoMode,  SchemeDirAction, ColorCustomizerAction,  getNavbarStyleMode, getSidebarActiveMode, SidebarActiveStyleAction, getDarkMode, ModeAction,  SidebarColorAction, getSidebarColorMode, getSidebarTypeMode} from '../../../store/setting/setting'
import {connect} from "react-redux"
import { event } from 'jquery'

const mapStateToProps = (state) => {
   return {
      darkMode: getDarkMode(state),
      colorprimarymode: getcustomizerprimaryMode(state),
   };
}


var kodebuku = '';

const Ebook = (props) => {      
   const token = localStorage.getItem("token");
   const decode = jwtDecode(token);

   const [addebook, showAdd] = useState(false);
   const closeAdd = () => showAdd(false);
   const modalAdd = () => {
      showAdd(true);
      getKategori();
      cancelEbook();
   }
   
   const [editorebook, showEditor] = useState(false);
   const closeEditor = () => showEditor(false);
   const modalEditor = () => {
      showEditor(true);
      getKategori();
   }
   
   const [rating, showRating] = useState(false);
   const closeRating = () => showRating(false);
   const modalRating = () => showRating(true);
   const [apirating, setAPIRating] = useState([]);
   const [totalRate, RateLength] = useState('0');
   const [totalStar, RateStar] = useState('0');

   const viewRating = async (code) => {
      modalRating();
      const res = await axios.get('https://api.alazhar2.com/node/admin/ratings?book='+code, { headers: {"Authorization" : `Bearer ${token}`}});
      setAPIRating(res.data.data);
      RateLength(res.data.data.length);
      let star = 0;
      for(let i = 0; i < res.data.data.length; i++){
         star = star + parseFloat(res.data.data[i].rating);
      }
      RateStar(star);
   }

   const [Diskusi, showDiskusi] = useState(false);
   const closeDiskusi = () => showDiskusi(false);
   const modalDiskusi = () => showDiskusi(true);
   const [apicomment, setAPIComment] = useState([]);
   const [parentbook, setParentBook] = useState('');
   const [parentcode, setParentCode] = useState('');
   const [parentuser, setParentUser] = useState('');
   const [comment, setComment] = useState('');
   const [comdisable, setDisable] = useState(true);

   const viewComment = async (code) => {
      setDisable(true);
      setParentBook(code);
      modalDiskusi();
      var a = new Array();
      const res = await axios.get('https://api.alazhar2.com/node/admin/comment?parent='+code, { headers: {"Authorization" : `Bearer ${token}`}});
      a = res.data.data;
      countReply(a);      
   }

   const countReply = (result) => {
      result.forEach((item,index) => {
         axios.get('https://api.alazhar2.com/node/admin/comment?parent='+item.code, { headers: {"Authorization" : `Bearer ${token}`}})
         .then(res => {
            result[index]["child"] = res.data.data;
         })
      });
      setTimeout(
         () => setAPIComment(result), 
         1000
      );
   }

   const showcom = (code,status) => {
      if (status){
         document.getElementById(code).classList.remove("d-none");
         document.getElementById(code+'-show').classList.add("d-none");
         document.getElementById(code+'-hide').classList.remove("d-none");
      } else {
         document.getElementById(code).classList.add("d-none");
         document.getElementById(code+'-hide').classList.add("d-none");
         document.getElementById(code+'-show').classList.remove("d-none");
      }
   }

   // Set Data for Reply
   const replyComment = (bookcode, pcode, puser) => {
      setDisable(false);
      setParentBook(bookcode);
      setParentCode(pcode);
      setParentUser(puser);
      setComment('');
   }

   const cancelComment = () => {
      setDisable(true);
      setParentCode('');
   }

   const sendComment = async (e) => {
      if (parentcode == ''){
         var code = parentbook;
      } else {
         var code = parentcode
      }
      axios.post(`https://api.alazhar2.com/node/admin/comment/add`, {
         parent: code,
         comment
      }, { headers: {"Authorization" : `Bearer ${token}`}})
      .then((response) => {
         setComment('');
         viewComment(parentbook);
      })
   }

   const delComment  = (bookcode, code) => {
      Swal.fire({
         title: 'Hapus Komentar ?',
         text: "Komentar Yang Dihapus Tidak Dapat Dikembalikan Lagi !",
         icon: 'warning',
         showCancelButton: true,
         confirmButtonText: 'Ok',
         cancelButtonText: 'Cancel',
         reverseButtons: true
      }).then((result) => {
         if (result.isConfirmed) {
            axios.delete(
               `https://api.alazhar2.com/node/admin/comment/delete?code=${code}`, 
               { headers: {"Authorization" : `Bearer ${token}`}}
            )
            .then((response) => {
               Swal.fire(
                  'Deleted!',
                  'Comment has been deleted.',
                  'success'
               )
               viewComment(bookcode);
            })
         }
      });
   }

   const [fullscreen, setFullscreen] = useState(true);
   const [APIData, setAPIData] = useState([]);
   const [APIKategori, setAPIKategori] = useState([]);
   const [pending, setPending] = useState(true);
   const [loading, setLoading] = useState(false);

   const [rows, setRows] = useState([
       { nomor: '', code: '', judul: '', mapel: '', penerbit: '', tahunterbit: '', status: '', createdtime: ''}
   ])
   const [backup, setBackup] = useState([
       { nomor: '', code: '', judul: '', mapel: '', penerbit: '', tahunterbit: '', status: '', createdtime: ''}
   ])

   const columns = [
      {
         name: 'No.',
         selector: row => row['nomor'],
         maxWidth: '5%',
         sortable: true,
      },
      {
         name: 'Judul',
         selector: row => row['judul'],
         sortable: true,
      },
      {
         name: 'Mata Pelajaran',
         selector: row => row['mapel'],
         sortable: true,
      },
      {
         name: 'Penerbit',
         selector: row => row['penerbit'],
         sortable: true,
      },
      {
         name: 'Tahun Terbit',
         selector: row => row['tahunterbit'],
         sortable: true,
      },
      {
         name: 'Status',
         selector: row => row['status'],
         sortable: true,
      },
      {
         name: 'Tanggal Dibuat',
         selector: row => row['createdtime'],
         sortable: true,
      },
      {
         name: 'Aksi',
         button: true,
         minWidth: '20%',
         cell: row => (
            <div>
               <Link className="btn btn-sm btn-icon text-secondary" data-bs-toggle="tooltip" title="Lihat Ebook" target='_blank' to={`/view_ebook/${row.code.replaceAll("/","-")}/${decode.code.replaceAll("/","-")}`}>
                  <svg width="25" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                     <path d="M50 34c-8.8 0-16 7.2-16 16s7.2 16 16 16 16-7.2 16-16-7.2-16-16-16zm0 28c-6.6 0-12-5.4-12-12s5.4-12 12-12 12 5.4 12 12-5.4 12-12 12zm44.4-13.4l-8.6-8.7C76.3 30.3 63.6 25 50 25s-26.3 5.3-35.8 14.9l-8.6 8.7c-.8.8-.8 2 0 2.8l8.6 8.7C23.7 69.7 36.4 75 50 75s26.3-5.3 35.8-14.9l8.6-8.7c.8-.8.8-2 0-2.8zM83 57.3C74.2 66.1 62.5 71 50 71s-24.2-4.9-33-13.7L9.8 50l7.2-7.3C25.8 33.9 37.5 29 50 29s24.2 4.9 33 13.7l7.2 7.3-7.2 7.3z"></path>
                     <path fill="#00F" d="M944-790V894H-840V-790H944m8-8H-848V902H952V-798z"></path>
                  </svg>
               </Link>
               {decode.role == 'murid' ? <></> :
                  <>
                  <span className="btn btn-sm btn-icon text-warning flex-end" data-bs-toggle="tooltip" title="Edit Ebook" onClick={() => editBook(row)}>
                     <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                        <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                     </svg>
                  </span>
                  <span className="btn btn-sm btn-icon text-success" data-bs-toggle="tooltip" title="Rating Ebook" onClick={() => viewRating(row.code)}>
                     <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M17.9184 14.32C17.6594 14.571 17.5404 14.934 17.5994 15.29L18.4884 20.21C18.5634 20.627 18.3874 21.049 18.0384 21.29C17.6964 21.54 17.2414 21.57 16.8684 21.37L12.4394 19.06C12.2854 18.978 12.1144 18.934 11.9394 18.929H11.6684C11.5744 18.943 11.4824 18.973 11.3984 19.019L6.96839 21.34C6.74939 21.45 6.50139 21.489 6.25839 21.45C5.66639 21.338 5.27139 20.774 5.36839 20.179L6.25839 15.259C6.31739 14.9 6.19839 14.535 5.93939 14.28L2.32839 10.78C2.02639 10.487 1.92139 10.047 2.05939 9.65C2.19339 9.254 2.53539 8.965 2.94839 8.9L7.91839 8.179C8.29639 8.14 8.62839 7.91 8.79839 7.57L10.9884 3.08C11.0404 2.98 11.1074 2.888 11.1884 2.81L11.2784 2.74C11.3254 2.688 11.3794 2.645 11.4394 2.61L11.5484 2.57L11.7184 2.5H12.1394C12.5154 2.539 12.8464 2.764 13.0194 3.1L15.2384 7.57C15.3984 7.897 15.7094 8.124 16.0684 8.179L21.0384 8.9C21.4584 8.96 21.8094 9.25 21.9484 9.65C22.0794 10.051 21.9664 10.491 21.6584 10.78L17.9184 14.32Z" fill="currentColor"></path>
                     </svg>
                  </span>
                  <span className="btn btn-sm btn-icon text-info" data-bs-toggle="tooltip" title="Diskusi Ebook" onClick={() => viewComment(row.code)}>
                     <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M2 12.015C2 6.74712 6.21 2 12.02 2C17.7 2 22 6.65699 22 11.985C22 18.1642 16.96 22 12 22C10.36 22 8.54 21.5593 7.08 20.698C6.57 20.3876 6.14 20.1572 5.59 20.3375L3.57 20.9384C3.06 21.0986 2.6 20.698 2.75 20.1572L3.42 17.9139C3.53 17.6034 3.51 17.2729 3.35 17.0125C2.49 15.4301 2 13.6975 2 12.015ZM10.7 12.015C10.7 12.7261 11.27 13.2969 11.98 13.307C12.69 13.307 13.26 12.7261 13.26 12.025C13.26 11.314 12.69 10.7431 11.98 10.7431C11.28 10.7331 10.7 11.314 10.7 12.015ZM15.31 12.025C15.31 12.7261 15.88 13.307 16.59 13.307C17.3 13.307 17.87 12.7261 17.87 12.025C17.87 11.314 17.3 10.7431 16.59 10.7431C15.88 10.7431 15.31 11.314 15.31 12.025ZM7.37 13.307C6.67 13.307 6.09 12.7261 6.09 12.025C6.09 11.314 6.66 10.7431 7.37 10.7431C8.08 10.7431 8.65 11.314 8.65 12.025C8.65 12.7261 8.08 13.2969 7.37 13.307Z" fill="currentColor"></path>
                     </svg>
                  </span>
                  <span className="btn btn-sm btn-icon text-danger" data-bs-toggle="tooltip" title="Hapus Ebook" onClick={() => confirmDelete(row.code)}>
                     <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                        <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                     </svg>
                  </span>
                  </>
               }
            </div>
         )
      },
   ];

   useEffect(() => {
      // Get Data
      getData();
   }, []);

   const getData = async (e) => {
      setLoading(true);
      const res = await axios.get('https://api.alazhar2.com/node/user/class/getUser?user='+decode.code, { headers: {"Authorization" : `Bearer ${token}`}});
      var classcode = '';
      res.data.data.forEach((data,index) => {
         classcode = data.class_code;
      });
      
      const res2 = await axios.get('https://api.alazhar2.com/node/user/class/getBook?classs='+classcode, { headers: {"Authorization" : `Bearer ${token}`}});
      const bookclass = res2.data.data;

      const res3 = await axios.get('https://api.alazhar2.com/node/admin/books', { headers: {"Authorization" : `Bearer ${token}`}});
      const allbook = res3.data.data;
      
      const arry = [];
      bookclass.forEach((data,index) => {
         allbook.forEach((data2,index2) => {
            if (data.book_code == data2.code) {
               arry.push(data2);
            }
         });
      });

      var resp = [];
      if (classcode != '') {
         resp = arry;
      } else {
         resp = allbook;
      }
      // console.log(resp);
      
      const query = resp.map((item, index) => ({...item, 
         nomor: index+1+".", 
         code: item.code, 
         judul: item.title,
         mapel: item.category_name,
         penerbit: item.publisher,
         tahunterbit: item.year_publish,
         status: item.status == 1 ? 'Aktif' : 'Non Aktif',
         cratedtime: item.createdtime
      }));
      setRows(query);
      setBackup(query);
      setLoading(false);
      // const timeout = setTimeout(() => {
      //    setRows(query);
      //    setPending(false);
      // }, 2000);
      // return () => clearTimeout(timeout);
   }
   
   const [filterText, setFilterText] = useState('');
   const cari = (val) => {
      var data = backup;
      var filteredItems = data.filter(
         item => {
            if (item.judul && item.judul.toLowerCase().includes(val.toLowerCase())) {
               return true;
            } 
            else if (item.mapel && item.mapel.toLowerCase().includes(val.toLowerCase())) {
               return true;
            }
            else if (item.penerbit && item.penerbit.toLowerCase().includes(val.toLowerCase())) {
               return true;
            }
            else if (item.tahunterbit && item.tahunterbit.toLowerCase().includes(val.toLowerCase())) {
               return true;
            }
            else if (item.status && item.status.toLowerCase().includes(val.toLowerCase())) {
               return true;
            }
   
            return false;
         }
      )

      if (val == '') {
         setRows(backup);
      } else {
         setRows(filteredItems);
      }
   }

   const handleClear = () => {
      setFilterText('');
      setRows(backup);
   }

   const getKategori = async (e) => {
      const res = await axios.get('https://api.alazhar2.com/node/admin/categories', { headers: {"Authorization" : `Bearer ${token}`}});
      setAPIKategori(res.data.data);
   }
 
   // creating new plugin instance
   const defaultLayoutPluginInstance = defaultLayoutPlugin();
   // pdf file onChange state
   const [pdfFile, setPdfFile]=useState(null);
   // pdf file error state
   const [pdfError, setPdfError]=useState('');
   // handle file onChange event
   const allowedFiles = ['application/pdf'];
   // Upload File 
   // a local state to store the currently selected file.
   const [bookcode, setBookCode] = useState('');
   const [title, setTitle] = useState('');
   const [kategori, setKategori] = useState('');
   const [sinopsis, setSinopsis] = useState('');
   const [publisher, setPublisher] = useState('');
   const [year_publish, setYearPublish] = useState('');
   const [status, setStatus] = useState('');
   const [thumbnail, setThumbnail] = useState(null);
   const [ebook, setEbook] = useState(null);
   const [background, setBackground] = useState(null);  
   const [url, setUrl] = useState('');
   const [filename, setFilename] = useState('');
   const [pdfimg, setPdfImg] = useState([]);
   const [perimg, setPerimg] = useState('');
   const [currpage, setCurrpage] = useState(0);

   const [mdledit, showEdit] = useState(false);
   const closeEdit = () => showEdit(false);
   const modalEdit = () => showEdit(true);
   const [idItem, setIdItem] = useState('');
   const [link, setLink] = useState('');
   const [apisoal, setAPIsoal] = useState([]);
   const [issoal, setIsSoal] = useState(false);
   const [isedit, setIsEdit] = useState(false);
   const [soal, setSoal] = useState('');
   const [typeEdit, setTypeEdit] = useState('');
   const [namafile, setNamaFile] = useState('');

   const cancelEbook = () => {
      setTitle('');
      setKategori('');
      setSinopsis('');
      setPublisher('');
      setYearPublish('');
      setStatus('');
      setThumbnail(null);
      setEbook(null);
      setBackground(null);
      setFilename('');
   }

   const handleFile = (e) => {
      let selectedFile = e.target.files[0];
      setEbook(selectedFile);
      setFilename(selectedFile.name);
      
      if(selectedFile){
         if(selectedFile&&allowedFiles.includes(selectedFile.type)){
            let reader = new FileReader();
            reader.readAsDataURL(selectedFile);
            reader.onloadend=(e)=>{
               setPdfError('');
               setPdfFile(e.target.result);
            }
         }
         else{
            setPdfError('Not a valid pdf: Please select only PDF');
            setPdfFile('');
         }
      }
      else{
         console.log('please select a PDF');
      }
   }

   const confirmDelete = (code) => {
      Swal.fire({
         title: 'Data Ingin Dihapus ?',
         text: "Data Yang Dihapus Tidak Dapat Dikembalikan Lagi !",
         icon: 'warning',
         showCancelButton: true,
         confirmButtonText: 'Ok',
         cancelButtonText: 'Cancel',
         reverseButtons: true
      }).then((result) => {
         if (result.isConfirmed) {
            axios.delete(
               `https://api.alazhar2.com/node/admin/books/delete?code=${code}`, 
               { headers: {"Authorization" : `Bearer ${token}`}}
            )
            .then((response) => {
               Swal.fire(
                  'Deleted!',
                  'Your file has been deleted.',
                  'success'
               )
               getData();
            })
         }
      });
   }

   const submitEbook = async (e) => {
      e.preventDefault();

      // console.log(thumbnail);
      if (thumbnail == '' || thumbnail == null) {
         Swal.fire(
            'Gagal !',
            'Thumbnail tidak boleh kosong.',
            'warning'
         )
         return;
      }

      const formData = new FormData();
      formData.append("title", title);
      formData.append("categories", kategori);
      formData.append("status", status);
      formData.append("thumbnails", thumbnail);
      formData.append("berkas", ebook);
      formData.append("sinopsis", sinopsis);
      formData.append("publisher", publisher);
      formData.append("yearPublish", year_publish);
      formData.append("background", background);

      setLoading(true);
      try {
         axios.post(`https://api.alazhar2.com/node/admin/books/add`, formData, { headers: {"Authorization" : `Bearer ${token}`}})
         .then((response) => {
            setLoading(false);
            getData();
            closeAdd();
         })
      } catch(error) {
         console.log(error)
      }
   }

   const editBook = async (data) => {
      modalEditor();
      const res = await axios.get('https://api.alazhar2.com/node/admin/books?code='+data.code, { headers: {"Authorization" : `Bearer ${token}`}});
      const result = res.data.data[0];
      
      kodebuku = result.code;
      setBookCode(result.code);
      setTitle(result.title);
      setKategori(result.categories);
      setSinopsis(result.sinopsis);
      setPublisher(result.publisher);
      setYearPublish(result.year_publish);
      setStatus(result.status);
      setThumbnail(result.thumbnail);
      setBackground(result.background);
      setNamaFile(result.file);

      const conv = await axios.get('https://api.alazhar2.com/node/admin/books/detail?books='+data.code, { headers: {"Authorization" : `Bearer ${token}`}});
      setPdfImg(conv.data.data);
      setPerimg(conv.data.data[0].file);
      setCurrpage(0);

      try {
         axios.get(`https://api.alazhar2.com/node/admin/books/attribute?book=`+data.code+`&pages=`+1, 
         { headers: {"Authorization" : `Bearer ${token}`}})
         .then((response) => {
            console.log(response);
            response.data.data.map((item,index)=>(
               addItem(event, item.code, item.width, item.height, item.x, item.y, item.type, item.pages, item.link)
            ))
         })
      } catch(error) {
         console.log(error)
      }
   }

   // Function Drag
   const dragElement = (elmnt) => {
      
      var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    
      const elementDrag = async(e) => {
         e = e || window.event;
         e.preventDefault();
         // calculate the new cursor position:
         pos1 = pos3 - e.clientX;
         pos2 = pos4 - e.clientY;
         pos3 = e.clientX;
         pos4 = e.clientY;

         // set the element's new position:
         elmnt.parentNode.style.top = (elmnt.parentNode.offsetTop - pos2) + "px";
         elmnt.parentNode.style.left = (elmnt.parentNode.offsetLeft - pos1) + "px";

         let kiri = String(parseInt(elmnt.parentNode.style.left.replace("px",""))/(window.screen.width*0.35)*100);
         let atas = String(parseInt(elmnt.parentNode.style.top.replace("px",""))/((window.screen.width*0.35)*3/2)*100);
         document.getElementById(elmnt.parentNode.getAttribute("iditem")).setAttribute("x",kiri);
         document.getElementById(elmnt.parentNode.getAttribute("iditem")).setAttribute("y",atas);
      }
    
      const closeDragElement = () => {
        /* stop moving when mouse button is released:*/
        document.onmouseup = null;
        document.onmousemove = null;
      }      
    
      const dragMouseDown = async(e) => {
         e = e || window.event;
         e.preventDefault();
         // get the mouse cursor position at startup:
         pos3 = e.clientX;
         pos4 = e.clientY;
         document.onmouseup = closeDragElement;
         // call a function whenever the cursor moves:
         document.onmousemove = elementDrag;
       }

      if (document.getElementById("attribut")) {
        /* if present, the header is where you move the DIV from:*/
        document.getElementById(elmnt.parentNode.id + "attribut").onmousedown = dragMouseDown;
      } else {
        /* otherwise, move the DIV from anywhere inside the DIV:*/
        elmnt.onmousedown = dragMouseDown;
      }
   }

   const removeEle = async (e) => {
      let ele = e.parentNode.nextSibling.nextSibling;
      let id = ele.id;

      if (id.includes("temp")) {
         e.parentNode.parentNode.remove();
      } else {
         Swal.fire({
            title: 'Ingin menghapus item ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
            reverseButtons: true
         }).then((result) => {
            if (result.isConfirmed) {
               axios.delete(
                  `https://api.alazhar2.com/node/admin/books/attribute/delete?code=${id}`, 
                  { headers: {"Authorization" : `Bearer ${token}`}}
               )
               .then((response) => {
                  e.parentNode.parentNode.remove();
               })
            }
         });
      }
   }

   const saveEle = async (e, type) => {
      let resize = e.parentNode.nextSibling;
      let resize_p = resize.style.width;
      let resize_l = resize.style.height;
      let ele = e.parentNode.nextSibling.nextSibling;
      let id = ele.id;
      let x = ele.getAttribute("x");
      let y = ele.getAttribute("y");
      let panjang = ele.getAttribute("panjang");
      let lebar = ele.getAttribute("lebar");
      let linkele = ele.getAttribute("link");
      let pages = ele.getAttribute("pages");
      let widthbuku = window.screen.width*0.35;

      if (panjang != resize_p) {
         panjang = resize_p;
      }
      if (lebar != resize_l) {
         lebar = resize_l;
      }

      if (id.includes("temp")) {
         id = '';
      }  

      if (pages == '') {
         pages = parseInt(currpage)+1;
      }

      try {
         axios.post('https://api.alazhar2.com/node/admin/books/attribute/add', 
         {
            code: id,
            book: kodebuku,
            pages,
            panjang,
            lebar,
            x,
            y,
            link: linkele.replace('watch?v=', 'embed/'),
            type,
            widthbook: widthbuku
         }, 
         { headers: {"Authorization" : `Bearer ${token}`}})
         .then((response) => {
            Swal.fire(
               'Success!',
               'Your item has been saved.',
               'success'
            );
            setIsEdit(false);
         })
      } catch(error) {
         console.log(error)
      }
   }

   const [thisele, setThisele] = useState('');
   const editEle = async (e, type) => {
      let ele = e.parentNode.nextSibling.nextSibling;
      setThisele(e);
      setIdItem(ele.id);
      setTypeEdit(type);
      setIsEdit(true);
      
      var res = await axios.get('https://api.alazhar2.com/node/admin/books/attribute?code='+ele.id+'&type='+type, 
      { headers: {"Authorization" : `Bearer ${token}`}});
      var result = res.data.data;
      
      if (type == 'Question') {
         const qry = await axios.get('https://api.alazhar2.com/node/admin/question', 
         { headers: {"Authorization" : `Bearer ${token}`}});
         const query = qry.data.data;
         setAPIsoal(query);

         setIsSoal(true);
         if (result.length > 0) {
            setSoal(result[0].link);
         } else {
            setSoal('');
         }
      } else {
         setIsSoal(false);
         if (result.length > 0) {
            setLink(result[0].link);
         } else {
            setLink('');
         }
      }
   }

   const updEditor = (type) => {
      if (type == 'link') {
         document.getElementById(idItem).setAttribute("link",link);
      } else {
         document.getElementById(idItem).setAttribute("link",soal);
      }
      saveEle(thisele, typeEdit);
   }

   const cancelEdit = () => {
      setLink('');
      setSoal('');
      setIsEdit(false);
   }

   // Multi Media Editor
   const addItem = (event, codeEle = '', panjang = "0px", lebar = "0px", x = "0", y = "0", typeItem = '', pgs = '', itemlink = '') => {
      let div1 = document.createElement('div');
      div1.className = "mydiv attributebook";

      div1.style.position = "absolute";
      div1.style.top = y + "%";
      div1.style.left = x + "%";
      div1.style.background = "transparent";
      div1.style.border = "0px";

      let div2 = document.createElement('div');
      div2.style.display = "flex";
      div2.id = "tombol";

      let move = document.createElement('span');
      move.style.cursor = "all-scroll";
      move.className = "text-dark mb-1";
      move.innerHTML = '<img src="'+iconmove+'" width="32" />';

      let edit = document.createElement('span');
      edit.style.cursor = "pointer";
      edit.style.marginLeft = "auto";
      edit.className = "text-secondary";
      edit.innerHTML = '<svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M16.6653 2.01034C18.1038 1.92043 19.5224 2.41991 20.5913 3.3989C21.5703 4.46779 22.0697 5.88633 21.9898 7.33483V16.6652C22.0797 18.1137 21.5703 19.5322 20.6013 20.6011C19.5323 21.5801 18.1038 22.0796 16.6653 21.9897H7.33487C5.88636 22.0796 4.46781 21.5801 3.39891 20.6011C2.41991 19.5322 1.92043 18.1137 2.01034 16.6652V7.33483C1.92043 5.88633 2.41991 4.46779 3.39891 3.3989C4.46781 2.41991 5.88636 1.92043 7.33487 2.01034H16.6653ZM10.9811 16.845L17.7042 10.102C18.3136 9.4826 18.3136 8.48364 17.7042 7.87427L16.4056 6.57561C15.7862 5.95625 14.7872 5.95625 14.1679 6.57561L13.4985 7.25491C13.3986 7.35481 13.3986 7.52463 13.4985 7.62453C13.4985 7.62453 15.0869 9.20289 15.1169 9.24285C15.2268 9.36273 15.2967 9.52256 15.2967 9.70238C15.2967 10.062 15.007 10.3617 14.6374 10.3617C14.4675 10.3617 14.3077 10.2918 14.1978 10.1819L12.5295 8.5236C12.4496 8.44368 12.3098 8.44368 12.2298 8.5236L7.46474 13.2887C7.13507 13.6183 6.94527 14.0579 6.93528 14.5274L6.87534 16.8949C6.87534 17.0248 6.9153 17.1447 7.00521 17.2346C7.09512 17.3245 7.21499 17.3744 7.34486 17.3744H9.69245C10.172 17.3744 10.6315 17.1846 10.9811 16.845Z" fill="currentColor"></path></svg>';
      edit.onclick = function() {
         editEle(this, typeItem);
      }
      
      let ceklis = document.createElement('span');
      ceklis.style.cursor = "pointer";
      ceklis.className = "text-success";
      ceklis.innerHTML = '<svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.67 2H16.34C19.73 2 22 4.38 22 7.92V16.091C22 19.62 19.73 22 16.34 22H7.67C4.28 22 2 19.62 2 16.091V7.92C2 4.38 4.28 2 7.67 2ZM11.43 14.99L16.18 10.24C16.52 9.9 16.52 9.35 16.18 9C15.84 8.66 15.28 8.66 14.94 9L10.81 13.13L9.06 11.38C8.72 11.04 8.16 11.04 7.82 11.38C7.48 11.72 7.48 12.27 7.82 12.62L10.2 14.99C10.37 15.16 10.59 15.24 10.81 15.24C11.04 15.24 11.26 15.16 11.43 14.99Z" fill="currentColor"></path></svg>';
      ceklis.onclick = function() {
         saveEle(this, typeItem);
      }
      
      let silang = document.createElement('span');
      silang.style.cursor = "pointer";
      silang.className = "text-danger";
      silang.innerHTML = '<svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.67 1.99927H16.34C19.73 1.99927 22 4.37927 22 7.91927V16.0903C22 19.6203 19.73 21.9993 16.34 21.9993H7.67C4.28 21.9993 2 19.6203 2 16.0903V7.91927C2 4.37927 4.28 1.99927 7.67 1.99927ZM15.01 14.9993C15.35 14.6603 15.35 14.1103 15.01 13.7703L13.23 11.9903L15.01 10.2093C15.35 9.87027 15.35 9.31027 15.01 8.97027C14.67 8.62927 14.12 8.62927 13.77 8.97027L12 10.7493L10.22 8.97027C9.87 8.62927 9.32 8.62927 8.98 8.97027C8.64 9.31027 8.64 9.87027 8.98 10.2093L10.76 11.9903L8.98 13.7603C8.64 14.1103 8.64 14.6603 8.98 14.9993C9.15 15.1693 9.38 15.2603 9.6 15.2603C9.83 15.2603 10.05 15.1693 10.22 14.9993L12 13.2303L13.78 14.9993C13.95 15.1803 14.17 15.2603 14.39 15.2603C14.62 15.2603 14.84 15.1693 15.01 14.9993Z" fill="currentColor"></path></svg>';
      silang.onclick = function() {
         removeEle(this);
      }

      div2.append(move);
      div2.append(edit);
      div2.append(ceklis);
      div2.append(silang);
      div1.append(div2);

      if (typeItem == 'Video') {
         var itemx = document.createElement('input');
         itemx.type = "button";
         itemx.value = "VIDEO";
         itemx.className = "btn";
         itemx.style.background = "#333333";
         itemx.style.color = "white";
         itemx.style.fontWeight = "bold";
         itemx.style.border = "2px solid black";
         itemx.style.resize = "both";
         itemx.style.overflow = "auto";
      }
      else if (typeItem == 'Link') {
         var itemx = document.createElement('input');
         itemx.type = "button";
         itemx.value = "LINK";
         itemx.className = "btn";
         itemx.style.background = "#1E90FF";
         itemx.style.color = "black";
         itemx.style.fontWeight = "bold";
         itemx.style.border = "2px solid black";
      }
      else if (typeItem == 'Question') {
         var itemx = document.createElement('input');
         itemx.type = "button";
         itemx.value = "SOAL";
         itemx.className = "btn";
         itemx.style.background = "#FFD700";
         itemx.style.color = "black";
         itemx.style.fontWeight = "bold";
         itemx.style.border = "2px solid black";
         itemx.style.resize = "both";
         itemx.style.overflow = "auto";
      }
      else if (typeItem == 'Doc') {
         var itemx = document.createElement('input');
         itemx.type = "button";
         itemx.value = "DOCS";
         itemx.className = "btn";
         itemx.style.background = "#5FA44C";
         itemx.style.color = "black";
         itemx.style.fontWeight = "bold";
         itemx.style.border = "2px solid black";
         itemx.style.resize = "both";
         itemx.style.overflow = "auto";
      }
      itemx.style.width = panjang;
      itemx.style.height = lebar;
      itemx.id = "attribut";
      
      let input1 = document.createElement('input');
      input1.type = "hidden";
      input1.setAttribute("panjang",panjang);
      input1.setAttribute("lebar",lebar);
      input1.setAttribute("x",x);
      input1.setAttribute("y",y);
      input1.setAttribute("bookcode", kodebuku);
      input1.setAttribute("link", itemlink);
      input1.setAttribute("pages", pgs)
      input1.className = "inpItem";
      
      if (codeEle != '') {
         input1.id = codeEle;
         div1.setAttribute("iditem", codeEle);  
      } else {
         let as = document.querySelectorAll('.attributebook');
         codeEle = "temp"+(as.length+1);
         input1.id = codeEle;
         div1.setAttribute("iditem", codeEle); 
      }
      div1.append(itemx);
      div1.append(input1); 

      dragElement(div2);
      
      document.getElementById('editor').append(div1);

   }

   function showImg(page) {
      console.log(page);
      console.log(pdfimg);
      setcurr(page);
      setPerimg(pdfimg[page].file);
      
      let ele = document.querySelectorAll('.attributebook');
      for (let index = 0; index < ele.length; index++) {
         ele[index].remove();
      }

      try {
         axios.get(`https://api.alazhar2.com/node/admin/books/attribute?book=`+bookcode+`&pages=`+(page+1), 
         { headers: {"Authorization" : `Bearer ${token}`}})
         .then((response) => {
            response.data.data.map((item,index)=>(
               addItem(event, item.code, item.width, item.height, item.x, item.y, item.type, item.pages, item.link)
            ))
         })
      } catch(error) {
         console.log(error)
      }
   }

   function setcurr(page) {
      setCurrpage(page);
   }
    

   return (
      <>
         {loading ? 
            <div className='loadingpage'>
               <div className="book">
                  <div className="book__pg-shadow"></div>
                  <div className="book__pg"></div>
                  <div className="book__pg book__pg--2"></div>
                  <div className="book__pg book__pg--3"></div>
                  <div className="book__pg book__pg--4"></div>
                  <div className="book__pg book__pg--5"></div>
               </div>
            </div> : ''
         }

         <Row>
            <Col sm="12">
               <Card>
                  <Card.Header className="d-flex justify-content-between">
                     <div className="header-title">
                        <h4 className="card-title">Ebook</h4>
                        <p>Overview Data Ebook</p>
                     </div>
                     <div>
                        {decode.role == 'murid' ? <></> : <>
                        <Button  className="text-center btn-primary btn-icon mt-lg-0 mt-md-0 mt-3" onClick={modalAdd}>
                           <i className="btn-inner">
                              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                 <path fillRule="evenodd" clipRule="evenodd" d="M14.7364 2.76175H8.0844C6.0044 2.75375 4.3004 4.41075 4.2504 6.49075V17.2277C4.2054 19.3297 5.8734 21.0697 7.9744 21.1147C8.0114 21.1147 8.0484 21.1157 8.0844 21.1147H16.0724C18.1624 21.0407 19.8144 19.3187 19.8024 17.2277V8.03775L14.7364 2.76175Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                 <path d="M14.4746 2.75V5.659C14.4746 7.079 15.6236 8.23 17.0436 8.234H19.7976" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                 <path d="M11.6406 9.90869V15.9497" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                 <path d="M13.9864 12.2642L11.6414 9.90918L9.29639 12.2642" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                              </svg>
                           </i>
                           <span>&nbsp; Upload Ebook</span>
                        </Button>
                        </>}
                     </div>
                  </Card.Header>
                  <Card.Body>
                     <Form.Group className='d-flex mb-3 w-100' style={{justifyContent: 'end', alignContent: 'center'}}>
                        <Form.Control
                           id="search"
                           type="text"
                           placeholder="Cari..."
                           aria-label="Search Input"
                           value={filterText}
                           onChange={(e) => {cari(e.target.value); setFilterText(e.target.value);}}
                           className='me-2 w-25'
                        />
                        <Button variant="secondary" type="button" title="Reset" onClick={handleClear}>
                           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" fill="currentColor" id="reload">
                              <path d="M21,11a1,1,0,0,0-1,1,8.05,8.05,0,1,1-2.22-5.5h-2.4a1,1,0,0,0,0,2h4.53a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4.77A10,10,0,1,0,22,12,1,1,0,0,0,21,11Z"></path>
                           </svg>
                        </Button>
                     </Form.Group>
                     <div className="table-responsive">
                        <DataTable
                           columns={columns}
                           data={rows}
                           // progressPending={pending}
                           pagination
                           highlightOnHover
                        />
                     </div>
                  </Card.Body>
               </Card>
            </Col>
         </Row>

         <Modal size="lg" show={addebook} onHide={closeAdd}>
            <Modal.Header closeButton className={props.darkMode}>
               <Modal.Title>Tambah E-Book</Modal.Title>
            </Modal.Header>
            <Modal.Body className={props.darkMode}>
               <Form>
                  <Form.Group className="mb-3">
                     <div className="dropzone-wrapper">
                        <div className="dropzone-desc">
                           <svg xmlns="http://www.w3.org/2000/svg" width="35" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path d="M12.1221 15.436L12.1221 3.39502" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M15.0381 12.5083L12.1221 15.4363L9.20609 12.5083" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M16.7551 8.12793H17.6881C19.7231 8.12793 21.3721 9.77693 21.3721 11.8129V16.6969C21.3721 18.7269 19.7271 20.3719 17.6971 20.3719L6.55707 20.3719C4.52207 20.3719 2.87207 18.7219 2.87207 16.6869V11.8019C2.87207 9.77293 4.51807 8.12793 6.54707 8.12793L7.48907 8.12793" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                           </svg>
                           <h5 className="m-2">{filename == '' ? 'IMPORT PDF' : filename}</h5>
                           <p>DRAG AND DROP A E-BOOK PDF HERE</p>
                        </div>
                        <input type="file" name="files" className="dropzone" onChange={handleFile} />
                     </div>
                  </Form.Group>
                  <Form.Group className="mb-3">
                     <Form.Label>Judul E-book</Form.Label>
                     <Form.Control type="text" name="title" placeholder="Masukkan Title" onChange={(e) => setTitle(e.target.value)}/>
                  </Form.Group>
                  <Form.Group className="mb-3">
                     <Form.Label>Mata Pelajaran</Form.Label>
                     <Form.Select name="kategori" onChange={(e) => setKategori(e.target.value)}>
                        <option value=''>Pilih Mata Pelajaran</option>
                        {APIKategori?.map((item) => (
                           <option value={item.code} key={item.code}>{item.name}</option>
                        ))}
                     </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                     <Form.Label>Sinopsis</Form.Label>
                     <Form.Control name="sinopsis" as="textarea" rows={3} onChange={(e) => setSinopsis(e.target.value)}/>
                  </Form.Group>
                  <Form.Group className="mb-3">
                     <Form.Label>Penerbit</Form.Label>
                     <Form.Control type="text" name="publisher" placeholder="Masukkan Penerbit" onChange={(e) => setPublisher(e.target.value)}/>
                  </Form.Group>
                  <Form.Group className="mb-3">
                     <Form.Label>Tahun Terbit</Form.Label>
                     <Form.Control type="number" name="year_publish" placeholder="Masukkan Tahun Terbit" onChange={(e) => setYearPublish(e.target.value)}/>
                  </Form.Group>
                  <Form.Group className="mb-3">
                     <Form.Label>Status</Form.Label>
                     <Form.Select name="status" onChange={(e) => setStatus(e.target.value)}>
                        <option value=''>Pilih Status</option>
                        <option value='1'>Aktif</option>
                        <option value='0'>Non Aktif</option>
                     </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                     <Form.Label>Thumbnail</Form.Label>
                     <Form.Control type="file" name="thumbnail" accept="image/*" onChange={(e) => setThumbnail(e.target.files[0])}/>
                  </Form.Group>
                  <Form.Group className="mb-3">
                     <Form.Label>Background</Form.Label>
                     <Form.Control type="file" name="background" accept="image/*" onChange={(e) => setBackground(e.target.files[0])}/>
                  </Form.Group>
               </Form>
            </Modal.Body>
            <Modal.Footer className={props.darkMode}>
               <Button variant="success" onClick={submitEbook}>
                  Simpan
               </Button>
               {' '}
               <Button variant="danger" onClick={closeAdd}>
                  Batal
               </Button>
            </Modal.Footer>
         </Modal>

         <Modal show={editorebook} fullscreen={fullscreen} onHide={closeEditor}>
            <Modal.Header closeButton className={props.darkMode}>
               <Modal.Title>Multimedia Editor</Modal.Title>
            </Modal.Header>
            <Modal.Body className={props.darkMode} style={{background: "#F5F5F5", overflow: "hidden"}}>
               <Row>
                  <Col md="3" style={{
                        display: "block",
                        overflowY: "scroll", 
                        height: "800px"
                     }}
                  >
                     <div>
                     <Card>
                        <Card.Header>
                           <h2>Detail E-Book</h2>
                        </Card.Header>
                        <Card.Body>
                           <Form>
                              <Form.Group className="mb-3">
                                 <Form.Label>Judul E-book</Form.Label>
                                 <Form.Control type="text" readOnly name="title" placeholder="Masukkan Title" value={title} onChange={(e) => setTitle(e.target.value)}/>
                              </Form.Group>
                              <Form.Group className="mb-3">
                                 <Form.Label>Mata Pelajaran</Form.Label>
                                 <Form.Select name="kategori" disabled value={kategori} onChange={(e) => setKategori(e.target.value)}>
                                    <option value=''>Pilih Mata Pelajaran</option>
                                    {APIKategori?.map((item) => (
                                       <option value={item.code} key={item.code}>{item.name}</option>
                                    ))}
                                 </Form.Select>
                              </Form.Group>
                              <Form.Group className="mb-3">
                                 <Form.Label>Sinopsis</Form.Label>
                                 <Form.Control name="sinopsis" readOnly as="textarea" rows={3} value={sinopsis} onChange={(e) => setSinopsis(e.target.value)}/>
                              </Form.Group>
                              <Form.Group className="mb-3">
                                 <Form.Label>Penerbit</Form.Label>
                                 <Form.Control type="text" readOnly name="publisher" placeholder="Masukkan Penerbit" value={publisher} onChange={(e) => setPublisher(e.target.value)}/>
                              </Form.Group>
                              <Form.Group className="mb-3">
                                 <Form.Label>Tahun Terbit</Form.Label>
                                 <Form.Control type="number" readOnly name="year_publish" placeholder="Masukkan Tahun Terbit" value={year_publish} onChange={(e) => setYearPublish(e.target.value)}/>
                              </Form.Group>
                              <Form.Group className="mb-3">
                                 <Form.Label>Status</Form.Label>
                                 <Form.Select name="status" disabled value={status} onChange={(e) => setStatus(e.target.value)}>
                                    <option value=''>Pilih Status</option>
                                    <option value='1'>Aktif</option>
                                    <option value='0'>Non Aktif</option>
                                 </Form.Select>
                              </Form.Group>
                              <Form.Group className="mb-3">
                                 <Form.Label>Thumbnail</Form.Label>
                                 <Form.Control type="file" disabled name="thumbnail" accept="image/*" onChange={(e) => setThumbnail(e.target.files[0])}/>
                                 <img src={"https://api.alazhar2.com/upload/books/"+namafile+"/thumbnails/"+thumbnail} className='mt-2 border border-primary preview rounded' />
                              </Form.Group>
                              <Form.Group className="mb-3">
                                 <Form.Label>Background</Form.Label>
                                 <Form.Control type="file" disabled name="background" accept="image/*" onChange={(e) => setBackground(e.target.files[0])}/>
                                 <img src={background == null ? defaultBackground : "https://api.alazhar2.com/upload/books/"+namafile+"/background/"+background} className='mt-2 border border-primary' style={{
                                    width: "100%",
                                    borderRadius: "20px"
                                 }} />
                              </Form.Group>
                              {/* <Form.Group className='mt-3 text-center'>
                                 <Button variant="success" onClick={submitEbook}>
                                    Simpan
                                 </Button>
                                 {' '}
                                 <Button variant="danger" onClick={closeEditor}>
                                    Batal
                                 </Button>
                              </Form.Group> */}
                           </Form>
                        </Card.Body>
                     </Card>
                     </div>
                  </Col>
                  <Col md="1">
                     <Form.Group className='m-2 w-100' style={{height: "100%"}}>
                        <Button  
                           className='my-1' 
                           onClick={() => addItem(event, '', '100px', '40px', '0', '0', 'Link', '')}
                           style={{
                              color: "white !important",
                              backgroundColor: "#888888",
                              minWidth: "95px",
                              width: "100%",
                              border: "0px"
                           }}
                        >
                           <svg width="45" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> 
                              <path fillRule="evenodd" clipRule="evenodd" d="M13.5442 10.4558C11.8385 8.75022 9.07316 8.75022 7.36753 10.4558L4.27922 13.5442C2.57359 15.2498 2.57359 18.0152 4.27922 19.7208C5.98485 21.4264 8.75021 21.4264 10.4558 19.7208L12 18.1766" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> 
                              <path fillRule="evenodd" clipRule="evenodd" d="M10.4558 13.5442C12.1614 15.2498 14.9268 15.2498 16.6324 13.5442L19.7207 10.4558C21.4264 8.75021 21.4264 5.98485 19.7207 4.27922C18.0151 2.57359 15.2497 2.57359 13.5441 4.27922L12 5.82338" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> 
                           </svg>
                           <h5 className='text-white mt-1'>Link</h5>
                        </Button>
                        <Button  
                           className='my-1' 
                           onClick={() => addItem(event, '', `${(window.screen.width*0.30)}px`, `${((window.screen.width*0.30)*3/2)}px`, '0', '0', 'Question', '')}
                           style={{
                              color: "white !important",
                              backgroundColor: "#888888",
                              minWidth: "95px",
                              width: "100%",
                              border: "0px"
                           }}
                        >
                           <svg width="45" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M9.3764 20.0279L18.1628 8.66544C18.6403 8.0527 18.8101 7.3443 18.6509 6.62299C18.513 5.96726 18.1097 5.34377 17.5049 4.87078L16.0299 3.69906C14.7459 2.67784 13.1541 2.78534 12.2415 3.95706L11.2546 5.23735C11.1273 5.39752 11.1591 5.63401 11.3183 5.76301C11.3183 5.76301 13.812 7.76246 13.8651 7.80546C14.0349 7.96671 14.1622 8.1817 14.1941 8.43969C14.2471 8.94493 13.8969 9.41792 13.377 9.48242C13.1329 9.51467 12.8994 9.43942 12.7297 9.29967L10.1086 7.21422C9.98126 7.11855 9.79025 7.13898 9.68413 7.26797L3.45514 15.3303C3.0519 15.8355 2.91395 16.4912 3.0519 17.1255L3.84777 20.5761C3.89021 20.7589 4.04939 20.8879 4.24039 20.8879L7.74222 20.8449C8.37891 20.8341 8.97316 20.5439 9.3764 20.0279ZM14.2797 18.9533H19.9898C20.5469 18.9533 21 19.4123 21 19.9766C21 20.5421 20.5469 21 19.9898 21H14.2797C13.7226 21 13.2695 20.5421 13.2695 19.9766C13.2695 19.4123 13.7226 18.9533 14.2797 18.9533Z" fill="currentColor"></path>
                           </svg>
                           <h5 className='text-white mt-1'>Soal</h5>
                        </Button>
                        <Button  
                           className='my-1' 
                           onClick={() => addItem(event, '', '320px', '180px', '0', '0', 'Video', '')}
                           style={{
                              color: "white !important",
                              backgroundColor: "#888888",
                              minWidth: "95px",
                              width: "100%",
                              border: "0px"
                           }}
                        >
                           <svg width="45" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M2 12.0058C2 6.48625 6.48842 2 12 2C17.5116 2 22 6.48625 22 12.0058C22 17.5137 17.5116 22 12 22C6.48842 22 2 17.5137 2 12.0058ZM15.668 13.0178C15.7741 12.9117 15.9093 12.7477 15.9382 12.7091C16.0927 12.5065 16.1699 12.2557 16.1699 12.0058C16.1699 11.725 16.083 11.4645 15.9189 11.2523C15.9055 11.2388 15.8791 11.2102 15.8448 11.1728C15.7806 11.103 15.6885 11.0026 15.6004 10.9146C14.8089 10.0656 12.7432 8.67631 11.6622 8.25181C11.4981 8.18524 11.083 8.03956 10.861 8.02991C10.6486 8.02991 10.4459 8.07815 10.2529 8.17463C10.0116 8.3097 9.81853 8.52195 9.71236 8.77279C9.64479 8.94645 9.53861 9.46744 9.53861 9.47709C9.43243 10.0463 9.37452 10.9725 9.37452 11.9952C9.37452 12.9706 9.43243 13.8572 9.51931 14.4361C9.52169 14.4385 9.53 14.4799 9.5429 14.5442C9.58211 14.7397 9.6638 15.1471 9.75097 15.314C9.96332 15.7192 10.3784 15.9701 10.8224 15.9701H10.861C11.1506 15.9604 11.7587 15.7096 11.7587 15.7C12.7819 15.2754 14.7992 13.9537 15.61 13.0757L15.668 13.0178Z" fill="currentColor"></path>
                           </svg>
                           <h5 className='text-white mt-1'>Video</h5>
                        </Button>
                        <Button  
                           className='my-1' 
                           onClick={() => addItem(event, '', '450px', '250px', '0', '0', 'Doc')}
                           style={{
                              color: "white !important",
                              backgroundColor: "#888888",
                              minWidth: "95px",
                              width: "100%",
                              border: "0px"
                           }}
                        >
                           <svg width="45" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M8.92574 16.39H14.3119C14.7178 16.39 15.0545 16.05 15.0545 15.64C15.0545 15.23 14.7178 14.9 14.3119 14.9H8.92574C8.5198 14.9 8.18317 15.23 8.18317 15.64C8.18317 16.05 8.5198 16.39 8.92574 16.39ZM12.2723 9.9H8.92574C8.5198 9.9 8.18317 10.24 8.18317 10.65C8.18317 11.06 8.5198 11.39 8.92574 11.39H12.2723C12.6782 11.39 13.0149 11.06 13.0149 10.65C13.0149 10.24 12.6782 9.9 12.2723 9.9ZM19.3381 9.02561C19.5708 9.02292 19.8242 9.02 20.0545 9.02C20.302 9.02 20.5 9.22 20.5 9.47V17.51C20.5 19.99 18.5099 22 16.0545 22H8.17327C5.59901 22 3.5 19.89 3.5 17.29V6.51C3.5 4.03 5.5 2 7.96535 2H13.2525C13.5099 2 13.7079 2.21 13.7079 2.46V5.68C13.7079 7.51 15.203 9.01 17.0149 9.02C17.4381 9.02 17.8112 9.02316 18.1377 9.02593C18.3917 9.02809 18.6175 9.03 18.8168 9.03C18.9578 9.03 19.1405 9.02789 19.3381 9.02561ZM19.6111 7.566C18.7972 7.569 17.8378 7.566 17.1477 7.559C16.0527 7.559 15.1507 6.648 15.1507 5.542V2.906C15.1507 2.475 15.6685 2.261 15.9646 2.572C16.5004 3.13476 17.2368 3.90834 17.9699 4.67837C18.7009 5.44632 19.4286 6.21074 19.9507 6.759C20.2398 7.062 20.0279 7.565 19.6111 7.566Z" fill="currentColor"></path>
                           </svg>
                           <h5 className='text-white mt-1'>Docs</h5>
                        </Button>
                     </Form.Group>
                  </Col>
                  <Col md={isedit ? "5" : "8"} style={{overflow: "auto"}}>
                     <Row>
                        <Col md="12" style={{display: "flex", justifyContent: "center", alignItems: "center"}}> 
                           <Button variant='secondary' className='me-2' onClick={(e) => showImg(currpage == 0 ? 0 : (currpage-1))}>Prev</Button>
                           <Form.Select 
                              className='me-2' 
                              style={{width: "90px"}} 
                              value={currpage}
                              onChange={(e) => showImg(e.target.value)}
                           >
                              {pdfimg?.map((item,index) => (
                                 <option 
                                    key={item.code} 
                                    value={index}                                    
                                 >
                                    {item.pages}
                                 </option>
                              ))}
                           </Form.Select>
                           <Button variant='secondary' className='me-2' onClick={(e) => showImg(currpage == (pdfimg.length-1) ? (pdfimg.length-1) : (parseInt(currpage)+1))}>Next</Button>
                        </Col>
                        
                        <Col md="12" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                           <div className='my-4' id="editor" style={{position: "relative"}}>
                              <img src={"https://api.alazhar2.com/upload/books/"+namafile+"/detail/"+perimg}
                              width={(window.screen.width*0.35)} style={{aspectRatio: "2/3"}}/>
                           </div>
                        </Col>
                     </Row>
                  </Col>
                  {isedit ? 
                  <Col md="3">
                     <Card>
                        <Card.Header>
                           <h2>{issoal ? 'Pilih Soal' : 'Masukkan Link'}</h2>
                        </Card.Header>
                        <Card.Body>
                           <Form>
                              <Form.Group>
                                 <Form.Control type="hidden" placeholder='Kode Item' value={idItem} onChange={(e) => setIdItem(e.target.value)} />
                                 {issoal ? 
                                    <Form.Select value={soal} onChange={(e) => setSoal(e.target.value)}>
                                       <option value=''>Pilih Soal</option>
                                       {apisoal?.map((item) => (
                                          <option key={item.code} value={item.code}>{item.name}</option>
                                       ))}
                                    </Form.Select> :
                                    <Form.Control as="textarea" rows={3} placeholder='Masukkan Link' value={link} onChange={(e) => setLink(e.target.value)} />
                                 }      
                              </Form.Group>
                              <Form.Group className='mt-3 text-center'>
                                 <Button variant="success" className='me-2' onClick={issoal ? () => updEditor('soal') : () => updEditor('link')}>
                                    Simpan
                                 </Button>
                                 <Button variant="danger" className='me-2' onClick={cancelEdit}>
                                    Batal
                                 </Button>
                              </Form.Group>
                           </Form> 
                        </Card.Body>
                     </Card>
                  </Col> : <></> 
                  }
               </Row>
            </Modal.Body>
         </Modal>
         
         <Modal size="lg" show={rating} onHide={closeRating}>
            <Modal.Header closeButton className={props.darkMode}>
               <Modal.Title>Rating & Ulasan E-Book</Modal.Title>
            </Modal.Header>
            <Modal.Body className={props.darkMode}>
               {apirating.length > 0 ? 
                  <><div className="d-flex justify-content-center">
                     <div className="text-center">
                        <h1 className='d-inline'>{totalStar/totalRate}</h1>
                        <h2 className='d-inline'>/5</h2>
                        <svg style={{"display":"none"}}>
                           <defs>
                              <symbol id="fivestars">
                                 <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z M0 0 h24 v24 h-24 v-24" fill="white" fillRule="evenodd"/>
                                 <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z M0 0 h24 v24 h-24 v-24" fill="white" fillRule="evenodd" transform="translate(24)"/>
                                 <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z M0 0 h24 v24 h-24 v-24" fill="white" fillRule="evenodd" transform="translate(48)"/>
                                 <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z M0 0 h24 v24 h-24 v-24" fill="white" fillRule="evenodd" transform="translate(72)"/>
                                 <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z M0 0 h24 v24 h-24 v-24" fill="white" fillRule="evenodd"  transform="translate(96)"/>
                              </symbol>
                           </defs>
                        </svg>
                        <div className="rating">
                           {/* <div className="rating-bg" style="width: 90%;"></div> */}
                           <progress className="rating-bg" value={totalStar/totalRate} max="5"></progress>
                           <svg><use xlinkHref="#fivestars"/></svg>
                        </div>
                        <span>{totalRate} Reviews</span>
                     </div>               
                  </div> 
                  <hr/></> : 
                  <div style={{
                     height:"250px", 
                     display:"flex",
                     justifyContent:"center",
                     alignItems:"center"
                  }}>
                     <h2>Belum Ada Rating</h2>
                  </div>
               }
               <Card>
                  <Card.Body>
                     {apirating?.map((item) => (
                        <>
                        <div className='border-bottom'>
                           <div className="user-post-data">
                              <div className="d-flex flex-wrap">
                                 <div className="media-support-info mt-2">
                                    <h5 className="mb-2 d-inline-block">{item.first_name} {item.last_name}</h5>
                                    <svg style={{"display":"none"}}>
                                       <defs>
                                          <symbol id="fivestars">
                                             <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z M0 0 h24 v24 h-24 v-24" fill="white" fillRule="evenodd"/>
                                             <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z M0 0 h24 v24 h-24 v-24" fill="white" fillRule="evenodd" transform="translate(24)"/>
                                             <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z M0 0 h24 v24 h-24 v-24" fill="white" fillRule="evenodd" transform="translate(48)"/>
                                             <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z M0 0 h24 v24 h-24 v-24" fill="white" fillRule="evenodd" transform="translate(72)"/>
                                             <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z M0 0 h24 v24 h-24 v-24" fill="white" fillRule="evenodd"  transform="translate(96)"/>
                                          </symbol>
                                       </defs>
                                    </svg>
                                    <div className="rating">
                                       {/* <div className="rating-bg" style="width: 90%;"></div> */}
                                       <progress className="rating-bg" value={item.rating} max="5"></progress>
                                       <svg><use xlinkHref="#fivestars"/></svg>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="mt-2">
                              <p>{item.descriptions}</p>
                           </div>
                        </div>
                        </>
                     ))}
                  </Card.Body>
               </Card>
            </Modal.Body>
         </Modal>
         
         <Modal size="lg" show={Diskusi} onHide={closeDiskusi}>
            <Modal.Header closeButton className={props.darkMode}>
               <Modal.Title>Diskusi Seputar E-Book</Modal.Title>
            </Modal.Header>
            <Modal.Body className={props.darkMode}>
               {apicomment.length > 0 ? "" : 
                  <div style={{
                     height:"250px", 
                     display:"flex",
                     justifyContent:"center",
                     alignItems:"center"
                  }}>
                     <h2>Belum Ada Komentar</h2>
                  </div>
               }
               {apicomment?.map((item) => (
                  <Card key={item.code}>
                     <Card.Header className="d-flex align-items-center justify-content-between pb-4">
                        <div className="header-title">
                           <div className="d-flex flex-wrap">
                              <div className="media-support-user-img me-3">
                                 <Image className="rounded-circle avatar-60 bg-soft-primary" src={avatars2} alt=""/>
                              </div>
                              <div className="media-support-info mt-2">
                                 <h4 className="mb-0">{item.first_name} {item.last_name}</h4>
                                 <p style={{fontSize: "14px"}} className="mb-0 text-secondary">{item.createdtime}</p>
                              </div>
                           </div>
                        </div>  
                     </Card.Header>
                     <Card.Body className="p-0">
                        <p className="px-3 mb-0">{item.comment}</p>
                        <div className="comment-area p-3">
                           <div className="d-flex flex-wrap justify-content-between align-items-center">
                              <div className="d-flex align-items-center">
                                 <div className="d-flex align-items-center feather-icon">
                                    <span className="mx-1">{item.child > 0 ? item.child.length : '0'}</span>
                                    <svg width="20" height="20" viewBox="0 0 24 24">
                                       <path fill="currentColor" d="M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9M10,16V19.08L13.08,16H20V4H4V16H10Z" />
                                    </svg>
                                 </div>
                                 <div className="d-flex align-items-center feather-icon mx-1">
                                    <span onClick={() => replyComment(item.parent_code, item.code, item.createdby)} className='text-success' data-bs-toggle="tooltip" title="Balas Komentar" style={{cursor:"pointer", marginTop:"1px"}}>
                                       <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="currentColor" viewBox="0 0 16 18"> 
                                          <path fillRule="evenodd" d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z"/> 
                                       </svg>
                                    </span>
                                 </div>
                                 <div className="d-flex align-items-center feather-icon">
                                    <span onClick={() => delComment(item.parent_code, item.code)} className='text-danger' data-bs-toggle="tooltip" title="Hapus Komentar" style={{cursor:"pointer", marginBottom:"1px"}}>
                                       <svg width="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                                          <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                          <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                          <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                       </svg>
                                    </span>
                                 </div>
                              </div>
                              <div className='ml-auto'>
                                 <span id={`${item.code}-show`} className='text-info' style={{cursor:"pointer"}} onClick={() => showcom(item.code, true)}>Lihat Komentar</span>   
                                 <span id={`${item.code}-hide`} className='text-info d-none' style={{cursor:"pointer"}} onClick={() => showcom(item.code, false)}>Sembunyikan Komentar</span> 
                              </div>
                           </div>
                           <hr/>
                           <ul className="list-inline p-0 m-0 d-none" id={item.code}>
                              {item.child?.map((com) => (
                                 <li className="my-2" key={com.code}>
                                    <div className="d-flex">
                                       <Image src={avatars1} alt="userimg" className="avatar-50 bg-soft-secondary rounded-circle img-fluid"/>
                                       <div className="ms-3">
                                          <h5 className="my-1">{com.first_name} {com.last_name} <span style={{fontSize:"10px", color:"grey"}}>{com.createdtime}</span></h5>
                                          <p className="mb-1">{com.comment}</p>
                                          <div className="d-flex flex-wrap align-items-center mt-2">
                                             <span onClick={() => replyComment(item.parent_code, item.code, com.createdby)} className="me-2 text-info" style={{cursor: "pointer"}}>
                                                <svg width="20" height="20" className="me-1 mb-1" viewBox="0 0 24 24">
                                                   <path fill="currentColor" d="M8,9.8V10.7L9.7,11C12.3,11.4 14.2,12.4 15.6,13.7C13.9,13.2 12.1,12.9 10,12.9H8V14.2L5.8,12L8,9.8M10,5L3,12L10,19V14.9C15,14.9 18.5,16.5 21,20C20,15 17,10 10,9" />
                                                </svg>
                                                balas
                                             </span>
                                             <span onClick={() => delComment(item.parent_code, com.code)} className='text-danger' data-bs-toggle="tooltip" title="Hapus Komentar" style={{cursor:"pointer"}}>
                                                <svg className='mb-1 me-1' width="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                                                   <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                   <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                   <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>
                                                hapus
                                             </span>
                                          </div>
                                       </div>
                                    </div>
                                 </li>
                              ))}
                           </ul>
                        </div>                              
                     </Card.Body>
                  </Card>
               ))}
            </Modal.Body>
            <Modal.Footer className={props.darkMode}>             
               {comdisable ? "" : <span className='text-left w-100' style={{fontSize:"14px"}}>Membalas Komentar {parentuser}</span>}
               <Form action="#" id="formKomen" className="w-100">
                  <FormControl type="hidden" value={parentbook} onChange={(e) => setParentBook(e.target.value)}/>
                  <FormControl type="hidden" value={parentcode} onChange={(e) => setParentCode(e.target.value)}/>
                  <InputGroup className="mb-3">
                     <FormControl type="text" placeholder="Masukkan Komentar" aria-label="Masukkan Komentar" aria-describedby="basic-addon2" value={comment} onChange={(e) => setComment(e.target.value)}/>
                     <Button type="button" className="input-group-text btn-primary" id="basic-addon2" title="Kirim Komentar" onClick={sendComment}>
                        <svg width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M21.4274 2.5783C20.9274 2.0673 20.1874 1.8783 19.4974 2.0783L3.40742 6.7273C2.67942 6.9293 2.16342 7.5063 2.02442 8.2383C1.88242 8.9843 2.37842 9.9323 3.02642 10.3283L8.05742 13.4003C8.57342 13.7163 9.23942 13.6373 9.66642 13.2093L15.4274 7.4483C15.7174 7.1473 16.1974 7.1473 16.4874 7.4483C16.7774 7.7373 16.7774 8.2083 16.4874 8.5083L10.7164 14.2693C10.2884 14.6973 10.2084 15.3613 10.5234 15.8783L13.5974 20.9283C13.9574 21.5273 14.5774 21.8683 15.2574 21.8683C15.3374 21.8683 15.4274 21.8683 15.5074 21.8573C16.2874 21.7583 16.9074 21.2273 17.1374 20.4773L21.9074 4.5083C22.1174 3.8283 21.9274 3.0883 21.4274 2.5783Z" fill="currentColor"></path>
                           <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M3.01049 16.8079C2.81849 16.8079 2.62649 16.7349 2.48049 16.5879C2.18749 16.2949 2.18749 15.8209 2.48049 15.5279L3.84549 14.1619C4.13849 13.8699 4.61349 13.8699 4.90649 14.1619C5.19849 14.4549 5.19849 14.9299 4.90649 15.2229L3.54049 16.5879C3.39449 16.7349 3.20249 16.8079 3.01049 16.8079ZM6.77169 18.0003C6.57969 18.0003 6.38769 17.9273 6.24169 17.7803C5.94869 17.4873 5.94869 17.0133 6.24169 16.7203L7.60669 15.3543C7.89969 15.0623 8.37469 15.0623 8.66769 15.3543C8.95969 15.6473 8.95969 16.1223 8.66769 16.4153L7.30169 17.7803C7.15569 17.9273 6.96369 18.0003 6.77169 18.0003ZM7.02539 21.5683C7.17139 21.7153 7.36339 21.7883 7.55539 21.7883C7.74739 21.7883 7.93939 21.7153 8.08539 21.5683L9.45139 20.2033C9.74339 19.9103 9.74339 19.4353 9.45139 19.1423C9.15839 18.8503 8.68339 18.8503 8.39039 19.1423L7.02539 20.5083C6.73239 20.8013 6.73239 21.2753 7.02539 21.5683Z" fill="currentColor"></path>
                        </svg>
                     </Button>
                     <button type="button" className="btn btn-danger" onClick={cancelComment} title="Batal Komentar">
                        <svg width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M14.3955 9.59497L9.60352 14.387" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                           <path d="M14.3971 14.3898L9.60107 9.59277" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path fillRule="evenodd" clipRule="evenodd" d="M16.3345 2.75024H7.66549C4.64449 2.75024 2.75049 4.88924 2.75049 7.91624V16.0842C2.75049 19.1112 4.63549 21.2502 7.66549 21.2502H16.3335C19.3645 21.2502 21.2505 19.1112 21.2505 16.0842V7.91624C21.2505 4.88924 19.3645 2.75024 16.3345 2.75024Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                     </button>
                  </InputGroup>
               </Form>
            </Modal.Footer>
         </Modal>
      </>
   )
}

export default connect(mapStateToProps)(Ebook)

import React,{useState,useEffect,useRef} from 'react'
import {Row,Col,Modal,Form,Button,Table} from 'react-bootstrap'
import {Link,useHistory} from 'react-router-dom'
import Swal from 'sweetalert2'
import Card from '../../../components/Card'
import '../../../index.css'
import {bindActionCreators} from "redux"
import axios from 'axios'
import DataTable from 'react-data-table-component';
import Select from "react-select";
import jwtDecode from 'jwt-decode'

// Dropzone
import { useDropzone } from 'react-dropzone'
import { FileUploader } from 'react-drag-drop-files'
// store
import {NavbarstyleAction, getDirMode, getcustomizerMode, getcustomizerprimaryMode,  getcustomizerinfoMode,  SchemeDirAction, ColorCustomizerAction,  getNavbarStyleMode, getSidebarActiveMode, SidebarActiveStyleAction, getDarkMode, ModeAction,  SidebarColorAction, getSidebarColorMode, getSidebarTypeMode} from '../../../store/setting/setting'
import {connect} from "react-redux"

const mapStateToProps = (state) => {
   return {
       darkMode: getDarkMode(state),
       colorprimarymode: getcustomizerprimaryMode(state),
   };
}

const Kelas = (props) => {
   const [member, showMember] = useState(false);
   const closeMember = () => showMember(false);
   const modalMember = () => showMember(true);

   const [book, showBook] = useState(false);
   const closeBook = () => showBook(false);
   const modalBook = () => showBook(true);
   
   const [addkls, showAdd] = useState(false);
   const closeAdd = () => showAdd(false);
   const modalAdd = () => showAdd(true);
   
   const [updkls, showUpd] = useState(false);
   const closeUpd = () => showUpd(false);
   const modalUpd = () => showUpd(true);

   const [id, setID] = useState(null);
   const [code, setCode] = useState('');
   const [name, setName] = useState('');
   const [status, setStatus] = useState('');
   const [thumbs, editThumbs] = useState('');
   const [thumbnail, setThumbnail] = useState(null);
   const [APIData, setAPIData] = useState([]);

   const token = localStorage.getItem("token");
   const decode = jwtDecode(token);
   const [pending, setPending] = useState(true);
   // creating the ref for file input
   const inputRef = useRef(null);

   const [rows, setRows] = useState([
       { nomor: '', code: '', name: '', status: '', createdtime: ''}
   ])
   const [backup, setBackup] = useState([
       { nomor: '', code: '', name: '', status: '', createdtime: ''}
   ])

   const columns = [
      {
         name: 'No.',
         selector: row => row['nomor'],
         sortable: true,
      },
      {
         name: 'Nama',
         selector: row => row['name'],
         sortable: true,
      },
      {
         name: 'Status',
         selector: row => row['status'],
         sortable: true,
      },
      {
         name: 'Tanggal Dibuat',
         selector: row => row['createdtime'],
         sortable: true,
      },
      {
         name: 'Aksi',
         button: true,
         minWidth: '20%',
         cell: (row) => (
            <div>
               <span className="btn btn-sm btn-icon text-success flex-end" data-bs-toggle="tooltip" title="Tambah Anggota" onClick={() => addMember(row.code)}>
                  <svg className="icon-32" width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.87651 15.2063C6.03251 15.2063 2.74951 15.7873 2.74951 18.1153C2.74951 20.4433 6.01251 21.0453 9.87651 21.0453C13.7215 21.0453 17.0035 20.4633 17.0035 18.1363C17.0035 15.8093 13.7415 15.2063 9.87651 15.2063Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.8766 11.886C12.3996 11.886 14.4446 9.841 14.4446 7.318C14.4446 4.795 12.3996 2.75 9.8766 2.75C7.3546 2.75 5.3096 4.795 5.3096 7.318C5.3006 9.832 7.3306 11.877 9.8456 11.886H9.8766Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M19.2036 8.66919V12.6792" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M21.2497 10.6741H17.1597" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
               </span>
               <span className="btn btn-sm btn-icon text-success flex-end" data-bs-toggle="tooltip" title="Tambah Buku" onClick={() => addBook(row.code)}>
                  <svg className="icon-32" width="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path fill="#5578FD" fillRule="evenodd" d="M14 5C11.2386 5 9 7.23858 9 10V40V42C9 42.5523 9.44772 43 10 43H38V41H11V40C11 38.3431 12.3431 37 14 37H38C38.5523 37 39 36.5523 39 36V6C39 5.44772 38.5523 5 38 5H14ZM11 35.9996C11.8357 35.3719 12.8744 35 14 35H37V7H14C12.3431 7 11 8.34315 11 10V35.9996ZM23 19V14H25V19L30 19V21H25V26H23V21H18V19L23 19Z" clipRule="evenodd"></path>
                  </svg>
               </span>
               <span className="btn btn-sm btn-icon text-warning flex-end" data-bs-toggle="tooltip" title="Edit Kelas" onClick={() => setData(row)}>
                  <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                     <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                     <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                     <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
               </span>
               {decode.role != 'admin' ? <></> :
                  <>
                  <span className="btn btn-sm btn-icon text-danger" data-bs-toggle="tooltip" title="Hapus Kelas" onClick={() => onDelete(row.code)}>
                     <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                        <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                     </svg>
                  </span>
                  </>
               }
            </div>
         )
      },
   ];

   useEffect(() => {
      // Get Data
      getData();
   }, []);
   
   // Get Data
   const getData = async (e) => {
      const res = await axios.get('https://api.alazhar2.com/node/admin/class', { headers: {"Authorization" : `Bearer ${token}`}});
      const query = res.data.data.map((item,index) => ({...item, 
         nomor: index+1+".", 
         code: item.code, 
         name: item.name,
         status: item.status == 1 ? 'Aktif' : 'Non Aktif',
         createdtime: item.createdtime
      }));
      setRows(query);
      setBackup(query);
      // const timeout = setTimeout(() => {
      //    setRows(query);
      //    setPending(false);
      // }, 2000);
      // return () => clearTimeout(timeout);
   }

   // Post Data
   const postData = () => {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("status", status);
      formData.append("thumbnails", thumbnail);

      axios.post(`https://api.alazhar2.com/node/admin/class/add`, 
         formData, 
         { headers: {"Authorization" : `Bearer ${token}`}}
      ).then((response) => {
         closeAdd();
         getData();
      })
      .catch((error) => {
         alert('error',error.response)
      })
   }

   // Set Data for Edit
   const setData = (data) => {
      modalUpd();
      let { code, name, status, thumbnails } = data;
      if(status == "Aktif") {
         status = 1;
      } else {
         status = 0;
      }
      setCode(code);
      setName(name);
      setStatus(status);
      setThumbnail(thumbnails);
   }

   // Update Data
   const updateData = async (e) => {
      const formData = new FormData();
      formData.append("code", code);
      formData.append("name", name);
      formData.append("status", status);
      formData.append("thumbnails", thumbnail);

      axios.put(`https://api.alazhar2.com/node/admin/class/update`, 
      formData, { headers: {"Authorization" : `Bearer ${token}`}})
      .then((response) => {
         closeUpd();
         getData();
      })
   }

   // Sweetalert Delete
   const onDelete  = (code) => {
      Swal.fire({
         title: 'Data Ingin Dihapus ?',
         text: "Data Yang Dihapus Tidak Dapat Dikembalikan Lagi !",
         icon: 'warning',
         showCancelButton: true,
         confirmButtonText: 'Ok',
         cancelButtonText: 'Cancel',
         reverseButtons: true
      }).then((result) => {
         if (result.isConfirmed) {
            axios.delete(
               `https://api.alazhar2.com/node/admin/class/delete?code=${code}`, 
               { headers: {"Authorization" : `Bearer ${token}`}}
            )
            .then((response) => {
               Swal.fire(
                  'Deleted!',
                  'Your file has been deleted.',
                  'success'
               )
               getData();
            })
         }
      });
   }
   
   // Manage Member
   const addMember = async (code) => {
      modalMember();
      setCode(code);
      getGuru(code);
      getMurid(code);
   }

   const conditionalRowStyles = [
      {
         when: row => row.keterangan == 'Terdaftar',
         style: {
            backgroundColor: 'rgba(63, 195, 128, 0.9)',
            color: 'white',
         },
      }
   ]

   const manageUserClass = (codeUser, codeClass, keterangan, roleUser) => {
      if (keterangan == 'Terdaftar') {
         delUser(codeUser, codeClass, roleUser);
      } else {
         addUser(codeUser, codeClass, roleUser);
      }
   }

   const addUser = (codeUser, codeClass, roleUser) => {
      axios.post(`https://api.alazhar2.com/node/admin/class/addUser`, {
         class: codeClass,
         user: codeUser
      }, { headers: {"Authorization" : `Bearer ${token}`}})
      .then((response) => {
         // Swal.fire(
         //    'Success!',
         //    'Data has been added.',
         //    'success'
         // )

         if (roleUser == 'guru') {
            getGuru(codeClass);
         } else {
            getMurid(codeClass);
         }
      })
      .catch((error) => {
         alert('error',error.response)
      })
   }

   const delUser  = (codeUser, classcode, roleUser) => {
      Swal.fire({
         title: 'User Akan di Hapus dari Kelas Ini.',
         text: "Yakin ingin menghapus user dari kelas ini ?",
         icon: 'warning',
         showCancelButton: true,
         confirmButtonText: 'Ok',
         cancelButtonText: 'Cancel',
         reverseButtons: true
      }).then((result) => {
         if (result.isConfirmed) {
            axios.delete(
               `https://api.alazhar2.com/node/admin/class/deleteUser?user=${codeUser}&class=${classcode}`, 
               { headers: {"Authorization" : `Bearer ${token}`}}
            )
            .then((response) => {
               Swal.fire(
                  'Deleted!',
                  'Your file has been deleted.',
                  'success'
               )

               if (roleUser == 'guru') {
                  getGuru(classcode);
               } else {
                  getMurid(classcode);
               }
            })
         }
      });
   }

   const [dataGuru, setDataGuru] = useState([
      { nomor: '', code: '', class_code: '', name: '', keterangan: ''}
   ]);
   const [dataGuru_find, setDataGuru_find] = useState([
      { nomor: '', code: '', class_code: '', name: '', keterangan: ''}
   ]);
   const columnGuru = [
      {
         name: 'No.',
         selector: row => row['nomor'],
         sortable: true,
         maxWidth: '5%'
      },
      {
         name: 'Nama Guru',
         cell: (row) => (
            <div className='w-100 h-100 d-flex align-items-center' onClick={() => manageUserClass(row.code, row.class_code, row.keterangan, 'guru')} role="button">
               <span>{row.name}</span>
            </div>
         )
      },
      {
         name: 'Keterangan',
         cell: (row) => (
            <div className='w-100 h-100 d-flex align-items-center' onClick={() => manageUserClass(row.code, row.class_code, row.keterangan, 'guru')} role="button">
               <span>{row.keterangan}</span>
            </div>
         )
      },
   ];
   const [filterGuru, setFilterGuru] = useState('');
   
   const getGuru = async (code) => {
      const res = await axios.get('https://api.alazhar2.com/node/admin/class/getuser?classs='+code+'&role=guru', { headers: {"Authorization" : `Bearer ${token}`}});
      const query = res.data.data.map((item,index) => ({...item, 
         nomor: index+1+".", 
         code: item.code, 
         class_code: item.class_code, 
         name: item.first_name+' '+item.last_name
      }));
      
      getAllGuru(code, query);
   }

   const getAllGuru = async (codeClass, existGuru) => {
      const res = await axios.get('https://api.alazhar2.com/node/admin/user?role=guru', { headers: {"Authorization" : `Bearer ${token}`}});
      const rows = res.data.data.filter(obj => {
         if (obj.status == '1') {
            return true;
         } 

         return false;
      });

      const data = rows.map((item,index) => ({...item, 
         nomor: index+1+".", 
         code: item.code, 
         class_code: codeClass, 
         name: item.first_name+' '+item.last_name,
         keterangan: existGuru.filter(obj => {if (obj.user_code == item.code) { return true; } return false;}).length > 0 ? 'Terdaftar' : 'Tidak Terdaftar'
      }));

      setDataGuru(data);
      setDataGuru_find(data);
   }

   const cariGuru = (val) => {
      var data = dataGuru_find;
      var filteredItems = data.filter(
         item => {
            if (item.name && item.name.toLowerCase().includes(val.toLowerCase())) {
               return true;
            } 
            else if (item.keterangan && item.keterangan.toLowerCase().includes(val.toLowerCase())) {
               return true;
            }
   
            return false;
         }
      )

      if (val == '') {
         setDataGuru(dataGuru_find);
      } else {
         setDataGuru(filteredItems);
      }
   }

   const clearGuru = () => {
      setFilterGuru('');
      setDataGuru(dataGuru_find);
   }

   const [dataMurid, setDataMurid] = useState([
      { nomor: '', code: '', class_code: '', name: '', keterangan: ''}
   ]);
   const [dataMurid_find, setDataMurid_find] = useState([
      { nomor: '', code: '', class_code: '', name: '', keterangan: ''}
   ]);
   const columnMurid = [
      {
         name: 'No.',
         selector: row => row['nomor'],
         sortable: true,
         maxWidth: '5%'
      },
      {
         name: 'Nama Murid',
         cell: (row) => (
            <div className='w-100 h-100 d-flex align-items-center' onClick={() => manageUserClass(row.code, row.class_code, row.keterangan, 'murid')} role="button">
               <span>{row.name}</span>
            </div>
         )
      },
      {
         name: 'Keterangan',
         cell: (row) => (
            <div className='w-100 h-100 d-flex align-items-center' onClick={() => manageUserClass(row.code, row.class_code, row.keterangan, 'murid')} role="button">
               <span>{row.keterangan}</span>
            </div>
         )
      },
   ];
   const [filterMurid, setFilterMurid] = useState('');
   
   const getMurid = async (code) => {
      const res = await axios.get('https://api.alazhar2.com/node/admin/class/getuser?classs='+code+'&role=murid', { headers: {"Authorization" : `Bearer ${token}`}});
      const query = res.data.data.map((item,index) => ({...item, 
         nomor: index+1+".", 
         code: item.code, 
         class_code: item.class_code, 
         name: item.first_name+' '+item.last_name
      }));
      
      getAllMurid(code, query);
   }

   const getAllMurid = async (codeClass, existMurid) => {
      const res = await axios.get('https://api.alazhar2.com/node/admin/user?role=murid', { headers: {"Authorization" : `Bearer ${token}`}});
      const rows = res.data.data.filter(obj => {
         if (obj.status == '1') {
            return true;
         } 

         return false;
      });

      const data = rows.map((item,index) => ({...item, 
         nomor: index+1+".", 
         code: item.code, 
         class_code: codeClass, 
         name: item.first_name+' '+item.last_name,
         keterangan: existMurid.filter(obj => {if (obj.user_code == item.code) { return true; } return false;}).length > 0 ? 'Terdaftar' : 'Tidak Terdaftar'
      }));

      setDataMurid(data);
      setDataMurid_find(data);
   }

   const cariMurid = (val) => {
      var data = dataMurid_find;
      var filteredItems = data.filter(
         item => {
            if (item.name && item.name.toLowerCase().includes(val.toLowerCase())) {
               return true;
            } 
            else if (item.keterangan && item.keterangan.toLowerCase().includes(val.toLowerCase())) {
               return true;
            }
   
            return false;
         }
      )

      if (val == '') {
         setDataMurid(dataMurid_find);
      } else {
         setDataMurid(filteredItems);
      }
   }

   const clearMurid = () => {
      setFilterMurid('');
      setDataMurid(dataMurid_find);
   }
   
   const [optBuku, setOptionBuku] = useState([]);
   const [showBuku, setGetBuku] = useState([]);
   const [buku, setBuku] = useState('');

   const [rowsBuku, setRowsBuku] = useState([
      { nomor: '', code: '', class_code: '', name: ''}
   ]);
   const [pendingBuku, setPendingBuku] = useState(true);

   const colBuku = [
      {
         name: 'No.',
         selector: row => row['nomor'],
         sortable: true,
         maxWidth: '5%'
      },
      {
         name: 'Nama Buku',
         selector: row => row['name'],
         sortable: true,
      },
      {
         name: 'Aksi',
         button: true,
         cell: (row) => (
            <div>
               <span className="btn btn-sm btn-icon text-danger" data-bs-toggle="tooltip" title="Hapus Buku" onClick={() => delBook(row.code,row.class_code)}>
                  <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                     <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                     <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                     <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
               </span>
            </div>
         )
      },
   ];

   // Get Member Buku
   const getBook = async (code) => {
      const res = await axios.get('https://api.alazhar2.com/node/admin/class/getBook?class='+code, { headers: {"Authorization" : `Bearer ${token}`}});
      const query = res.data.data.map((item,index) => ({...item, 
         nomor: index+1+".", 
         code: item.code, 
         class_code: item.class_code, 
         name: item.title
      }));
      setRowsBuku(query);
      getOptBuku();
      // const timeout = setTimeout(() => {
      //    setRowsBuku(query);
      //    setPendingBuku(false);
      // }, 2000);
      // return () => clearTimeout(timeout);
   }

   function availBook(code) {
      let cek = rowsBuku.find(item => {
         return item.book_code === code;
      })

      if (cek == undefined) {
         var hasil = 0;
      } else {
         var hasil = 1;
      }

      return hasil;
   }

   const addBook = async (code) => {
      modalBook();
      setCode(code);
      getBook(code);
      
      // Get Option Buku
      const res = await axios.get('https://api.alazhar2.com/node/admin/books', { headers: {"Authorization" : `Bearer ${token}`}});
      const selectBuku = res.data.data.filter(obj => {
         return obj.status === 1;
      });
      setOptionBuku(selectBuku);
   }

   const getOptBuku = async (e) => {
      const res = await axios.get('https://api.alazhar2.com/node/admin/books', { headers: {"Authorization" : `Bearer ${token}`}});
      const query = res.data.data.filter(obj => {
         if (obj.status === 1 && availBook(obj.code) < 1) {
            return true;
         } 

         return false;
      });
      const selectBuku = query.map((item,index) => ({ 
         value: item.code, 
         label: item.title
      }));
      setOptionBuku(selectBuku);
   }

   function handleSelect3(data) {
      setBuku(data);
   }

   // Post Data Buku
   const postBook = () => {
      saveBuku(0, buku.length, buku);
   }

   const saveBuku = (curr, end, data) => {
      let classcode = code;
      let book = data[curr].value;

      axios.post(`https://api.alazhar2.com/node/admin/class/addBook`, {
         class: classcode,
         book
      }, { headers: {"Authorization" : `Bearer ${token}`}})
      .then((response) => {
         curr = curr+1;
         if (curr < end) {
            saveBuku(curr, end, data);
         } else {
            setBuku('');
            getBook(classcode);
         }
      })
      .catch((error) => {
         alert('error',error.response)
      })
   }

   // Sweetalert Delete
   const delBook  = (code,classcode) => {
      Swal.fire({
         title: 'Data Ingin Dihapus ?',
         text: "Data Yang Dihapus Tidak Dapat Dikembalikan Lagi !",
         icon: 'warning',
         showCancelButton: true,
         confirmButtonText: 'Ok',
         cancelButtonText: 'Cancel',
         reverseButtons: true
      }).then((result) => {
         if (result.isConfirmed) {
            axios.delete(
               `https://api.alazhar2.com/node/admin/class/deleteBook?code=${code}`, 
               { headers: {"Authorization" : `Bearer ${token}`}}
            )
            .then((response) => {
               Swal.fire(
                  'Deleted!',
                  'Your data has been deleted.',
                  'success'
               )
               getBook(classcode);
            })
         }
      });
   }

   const [filterText, setFilterText] = useState('');
   const cari = (val) => {
      var data = backup;
      var filteredItems = data.filter(
         item => {
            if (item.name && item.name.toLowerCase().includes(val.toLowerCase())) {
               return true;
            } 
            else if (item.status && item.status.toLowerCase().includes(val.toLowerCase())) {
               return true;
            }
   
            return false;
         }
      )

      if (val == '') {
         setRows(backup);
      } else {
         setRows(filteredItems);
      }
   }

   const handleClear = () => {
      setFilterText('');
      setRows(backup);
   }
   
   return (
      <>
         <Row>
            <Col sm="12">
               <Card>
                  <Card.Header className="d-flex justify-content-between">
                     <div className="header-title">
                        <h4 className="card-title">Kelas</h4>
                        <p>Overview Data Kelas</p>
                     </div>
                     <div>
                        <Button  className="text-center btn-primary btn-icon mt-lg-0 mt-md-0 mt-3" onClick={modalAdd}>
                           <i className="btn-inner">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                              </svg>
                           </i>
                           <span>Tambah Kelas</span>
                        </Button>
                     </div>
                  </Card.Header>
                  <Card.Body className="py-0">
                     <Form.Group className='d-flex mb-3 w-100' style={{justifyContent: 'end', alignContent: 'center'}}>
                        <Form.Control
                           id="search"
                           type="text"
                           placeholder="Cari..."
                           aria-label="Search Input"
                           value={filterText}
                           onChange={(e) => {cari(e.target.value); setFilterText(e.target.value);}}
                           className='me-2 w-25'
                        />
                        <Button variant="secondary" type="button" title="Reset" onClick={handleClear}>
                           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" fill="currentColor" id="reload">
                              <path d="M21,11a1,1,0,0,0-1,1,8.05,8.05,0,1,1-2.22-5.5h-2.4a1,1,0,0,0,0,2h4.53a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4.77A10,10,0,1,0,22,12,1,1,0,0,0,21,11Z"></path>
                           </svg>
                        </Button>
                     </Form.Group>
                     <div className="table-responsive">
                        <DataTable
                           columns={columns}
                           data={rows}
                           // progressPending={pending}
                           pagination
                           highlightOnHover
                        />
                     </div>
                  </Card.Body>
               </Card>
            </Col>
         </Row>
         
         <Modal fullscreen={false} size="xl" show={member} onHide={closeMember}>
            <Modal.Header closeButton className={props.darkMode}>
               <Modal.Title>Kelola Member Kelas</Modal.Title>
            </Modal.Header>
            <Modal.Body className={props.darkMode}>
               <Row>
                  <Col md={6}>
                     <Card>
                        <Card.Body>
                           <Form>
                              {/* <Row className='mb-3'>
                                 <Col className='d-flex'>
                                    <Select
                                       options={optGuru}
                                       placeholder="Pilih Guru"
                                       value={guru}
                                       onChange={handleSelect}
                                       isSearchable={true}
                                       isMulti
                                       className='me-2 w-75'
                                    />                      
                                    <Button variant="success" onClick={() => postGuru()}>
                                       Simpan
                                    </Button>
                                 </Col>
                              </Row>
                              <DataTable
                                 columns={colGuru}
                                 data={rowsGuru}
                                 // progressPending={pendingGuru}
                                 pagination
                                 highlightOnHover
                              />
                              <br /> */}
                              <Form.Group className='d-flex mb-3 w-100' style={{justifyContent: 'end', alignContent: 'center'}}>
                                 <Form.Control
                                    id="search"
                                    type="text"
                                    placeholder="Cari..."
                                    aria-label="Search Input"
                                    value={filterGuru}
                                    onChange={(e) => {cariGuru(e.target.value); setFilterGuru(e.target.value);}}
                                    className='me-2'
                                 />
                                 <Button variant="secondary" type="button" title="Reset" onClick={clearGuru}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" fill="currentColor" id="reload">
                                       <path d="M21,11a1,1,0,0,0-1,1,8.05,8.05,0,1,1-2.22-5.5h-2.4a1,1,0,0,0,0,2h4.53a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4.77A10,10,0,1,0,22,12,1,1,0,0,0,21,11Z"></path>
                                    </svg>
                                 </Button>
                              </Form.Group>
                              <DataTable
                                 columns={columnGuru}
                                 data={dataGuru}
                                 pagination
                                 highlightOnHover
                                 conditionalRowStyles={conditionalRowStyles}
                              />
                           </Form>
                        </Card.Body>
                     </Card>
                  </Col>

                  <Col md={6}>
                     <Card>
                        <Card.Body>
                           <Form>
                              {/* <Row className='mb-3'>
                                 <Col className='d-flex'>
                                    <Select
                                       options={optMurid}
                                       placeholder="Pilih Murid"
                                       value={murid}
                                       onChange={handleSelect2}
                                       isSearchable={true}
                                       isMulti
                                       className='me-2 w-75'
                                    />                                
                                    <Button variant="success" onClick={() => postMurid()}>
                                       Simpan
                                    </Button>
                                 </Col>
                              </Row> */}                              
                              <Form.Group className='d-flex mb-3 w-100' style={{justifyContent: 'end', alignContent: 'center'}}>
                                 <Form.Control
                                    id="search"
                                    type="text"
                                    placeholder="Cari..."
                                    aria-label="Search Input"
                                    value={filterMurid}
                                    onChange={(e) => {cariMurid(e.target.value); setFilterMurid(e.target.value);}}
                                    className='me-2'
                                 />
                                 <Button variant="secondary" type="button" title="Reset" onClick={clearMurid}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" fill="currentColor" id="reload">
                                       <path d="M21,11a1,1,0,0,0-1,1,8.05,8.05,0,1,1-2.22-5.5h-2.4a1,1,0,0,0,0,2h4.53a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4.77A10,10,0,1,0,22,12,1,1,0,0,0,21,11Z"></path>
                                    </svg>
                                 </Button>
                              </Form.Group>
                              <DataTable
                                 columns={columnMurid}
                                 data={dataMurid}
                                 pagination
                                 highlightOnHover
                                 conditionalRowStyles={conditionalRowStyles}
                              />
                           </Form>
                        </Card.Body>
                     </Card>
                  </Col>
               </Row>
               
            </Modal.Body>
         </Modal>

         <Modal size="lg" show={book} onHide={closeBook}>
            <Modal.Header closeButton className={props.darkMode}>
               <Modal.Title>Kelola Ebook Kelas</Modal.Title>
            </Modal.Header>
            <Modal.Body className={props.darkMode}>
               <Card>
                  <Card.Body>
                     <Form>
                        <Row className='mb-3'>
                           <Col className='d-flex'>
                              <Select
                                 options={optBuku}
                                 placeholder="Pilih Buku"
                                 value={buku}
                                 onChange={handleSelect3}
                                 isSearchable={true}
                                 isMulti
                                 className='me-2 w-100'
                              />                               
                              <Button variant="success" onClick={() => postBook()}>
                                 Simpan
                              </Button>
                           </Col>
                        </Row>
                        <DataTable
                           columns={colBuku}
                           data={rowsBuku}
                           // progressPending={pendingBuku}
                           pagination
                           highlightOnHover
                        />
                     </Form>
                  </Card.Body>
               </Card>
            </Modal.Body>
         </Modal>
         
         <Modal show={addkls} onHide={closeAdd}>
            <Modal.Header closeButton className={props.darkMode}>
               <Modal.Title>Tambah Kelas</Modal.Title>
            </Modal.Header>
            <Modal.Body className={props.darkMode}>
               <Form.Group className="mb-3">
                  <Form.Label>Nama Kelas</Form.Label>
                  <Form.Control type="text" id="name" placeholder="Masukkan Nama Kelas" onChange={(e) => setName(e.target.value)} />
               </Form.Group>
               <Form.Group className="mb-3">
                  <Form.Label>Status Kelas</Form.Label>
                  <select className='form-control' id="status" onChange={(e) => setStatus(e.target.value)}>
                     <option value=''>Pilih Status</option>
                     <option value='1'>Aktif</option>
                     <option value='0'>Non Aktif</option>
                  </select>
               </Form.Group>
               <Form.Group className="mb-3">
                  <Form.Label>
                     Thumbnail Kelas 
                  </Form.Label>
                  <Form.Control ref={inputRef} type="file" id="gambar" accept="image/*" onChange={(e) => setThumbnail(e.target.files[0])}/>
               </Form.Group>
               <Button variant="success" onClick={postData}>Save</Button>
               {' '}
               <Button variant="danger" onClick={closeAdd}>Cancel</Button>
            </Modal.Body>
         </Modal>
         
         <Modal show={updkls} onHide={closeUpd}>
            <Modal.Header closeButton className={props.darkMode}>
               <Modal.Title>Update Kelas</Modal.Title>
            </Modal.Header>
            <Modal.Body className={props.darkMode}>
               <Form.Group className="mb-3 d-none">
                  <Form.Label>Kode Kelas</Form.Label>
                  <Form.Control type="text" id="code" placeholder="Masukkan Kode Kelas" value={code} onChange={(e) => setCode(e.target.value)} readOnly/>
               </Form.Group>
               <Form.Group className="mb-3">
                  <Form.Label>Nama Kelas</Form.Label>
                  <Form.Control type="text" id="name" placeholder="Masukkan Nama Kelas" value={name} onChange={(e) => setName(e.target.value)} />
               </Form.Group>
               <Form.Group className="mb-3">
                  <Form.Label>Status Kelas</Form.Label>
                  <select className='form-control' id="status" value={status} onChange={(e) => setStatus(e.target.value)}>
                     <option value=''>Pilih Status</option>
                     <option value='1'>Aktif</option>
                     <option value='0'>Non Aktif</option>
                  </select>
               </Form.Group>
               <Form.Group className="mb-3">
                  <Form.Label>
                     Thumbnail Kelas 
                     {thumbnail == null || thumbnail == '' ? <></> : 
                        <>
                        &nbsp; <a href={"https://api.alazhar2.com/upload/class/"+code.replaceAll("/", "")+"/thumbnails/"+code.replaceAll("/", "")+".png"} target='_blank' className='text-info'>Preview</a>
                        </>
                     }
                  </Form.Label>
                  <Form.Control ref={inputRef} type="file" id="gambar" accept="image/*" onChange={(e) => setThumbnail(e.target.files[0])}/>
               </Form.Group>
               <Button variant="success" onClick={updateData}>Update</Button>
               {' '}
               <Button variant="danger" onClick={closeUpd}>Cancel</Button>
            </Modal.Body>
         </Modal>
      </>
   )
}

export default connect(mapStateToProps)(Kelas)

import React,{useState,useEffect,useLayoutEffect} from 'react'
import {Row,Col,Image,Modal,Form,Button,InputGroup,FormControl,Table,Alert} from 'react-bootstrap'
import {Link,useHistory,useParams} from 'react-router-dom'
import Card from '../../../components/Card'
import HTMLFlipBook from 'react-pageflip';
import '../../../index.css'
import axios from 'axios'
import Swal from 'sweetalert2'

// img
import '../../../index.css'
import '../../../assets/loading.css'

const QuestionPage = () => {
    const { book_code } = useParams();
    const { code_soal } = useParams();
    const { user_code } = useParams();

    const [koreksi, showKoreksi] = useState(false);
    const closeKoreksi = () => showKoreksi(false);
    const modalKoreksi = (murid) => {
        showKoreksi(true);
        getCorrectOpt(murid);
    }

    const token = localStorage.getItem("token");
    const [loading, setLoading] = useState(false);
    const [apisoal, setAPIsoal] = useState([]);
    const [cekUser, setCekUser] = useState('');
    const [apiclass, setAPIClass] = useState([]);
    const [apimurid, setAPIMurid] = useState([]);
    const [doneanswer, setDone] = useState(false);
    const [waitcorrect, setWait] = useState(false);
    const [typesoal, setType] = useState('');
    const [nilai, setNilai] = useState(0);
    const [corrans, setCorrAns] = useState([]);
    const [grd, setGrd] = useState(0);
    const [kodekls, setKodekls] = useState('');

    useEffect(() => {
        getData();
        getUser();
        getTypeSoal();
        getCorrectOpt();

        // document.body.style.backgroundColor = "transparent";
    }, []);

    const getUser = () => {
        axios.get(`https://api.alazhar2.com/node/admin/user?code=`+user_code.replaceAll("-","/"))
        .then((res) => {
            setCekUser(res.data.data[0].role);
            if (res.data.data[0].role == 'guru') {
                getBookClass();
            }
        })
    }

    const getBookClass = () => {
        axios.get(`https://api.alazhar2.com/node/admin/class/getBook?book=`+book_code.replaceAll("-","/"))
        .then((res) => {
            let rows = res.data.data;
            getInfoClass(0, rows, rows.length);
        })
    }

    const getInfoClass = async (curr, data, end) => {
        let classCode = data[curr].class_code;

        axios.get(`https://api.alazhar2.com/node/admin/class?code=`+classCode)
        .then((res) => {
            data[curr].name = res.data.data[0].name;
            curr = curr+1;
            if (curr < end) {
                getInfoClass(curr, data, end);
            } else {
                getUserClass(0, data, data.length);
            }
        })

    }

    const getUserClass = async (curr, data, end) => {
        let classCode = data[curr].class_code;

        axios.get(`https://api.alazhar2.com/node/admin/class/getuser?classs=`+classCode+`&role=guru&user=`+user_code.replaceAll("-","/"))
        .then((res) => {
            data[curr].guru = res.data.data;
            curr = curr+1;
            if (curr < end) {
                getUserClass(curr, data, end);
            } else {
                setAPIClass(data);
                getListMurid(data[0].class_code);
            }
        })
    }

    const getListMurid = async (classCode) => {
        setLoading(true);
        axios.get(`https://api.alazhar2.com/node/admin/class/getuser?classs=`+classCode+`&role=murid`)
        .then((res) => {
            let result = res.data.data;
            // setAPIMurid(res.data.data);
            setKodekls(classCode);
            getStatusMurid(0, result, result.length);
        })
        setLoading(false);
    }

    const getStatusMurid = async (curr, data, end) => {
        let user = data[curr].user_code;
        let parent = code_soal.replaceAll("-","/");

        axios.get('https://api.alazhar2.com/node/user/getAnswerQuestion?user='+user+'&parent='+parent)
        .then((res) => {
            if (res.data.data.length > 0) {
                data[curr].status = "Sudah Mengerjakan";
            } else {
                data[curr].status = "Belum Mengerjakan";
            }

            curr = curr+1;
            if (curr < end) {
                getStatusMurid(curr, data, end);
            } else {
                setAPIMurid(data);
            }
        })
    }

    const getTypeSoal = async (e) => {
        const conv = await axios.get('https://api.alazhar2.com/node/admin/question?code='+code_soal.replaceAll("-","/"));
        let res = conv.data.data[0];
        setType(res.type);
    }

    const getData = async (e) => {
        const conv = await axios.get('https://api.alazhar2.com/node/admin/question/detail?questionCode='+code_soal.replaceAll("-","/"));
        let res = conv.data.data;
        getJawaban(0, res, res.length);
    }

    const getJawaban = async (curr, data, end) => {
        let detailcode = data[curr].code;

        axios.get(`https://api.alazhar2.com/node/admin/question/answer?detailCode=`+detailcode)
        .then((res) => {
            data[curr].detail = res.data.data;
            curr = curr+1;
            if (curr < end) {
                getJawaban(curr, data, end);
            } else {
                setAPIsoal(data);
            }
        })
    }

    const submitAnswer = () => {
        Swal.fire({
           title: 'Sudah Selesai Mengerjakan ?',
           text: "Periksa kembali jawabanmu sebelum dikirim, soal yang sudah dikerjakan tidak bisa dikerjakan kembali.",
           icon: 'warning',
           showCancelButton: true,
           confirmButtonText: 'Selesai',
           cancelButtonText: 'Batal',
           reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                postAnswer(0, apisoal, apisoal.length, typesoal);
            }
        });
    }

    const postAnswer = async (curr, data, end, type) => {
        let grade = 0;

        let jwb = document.getElementsByName("right_answer"+curr);
        for (let j = 0; j < jwb.length; j++) {
            if (type == "PG" || type == "Benar Salah") {
                if (jwb[j].checked == true) {
                    var ele = jwb[j];
                    var answer = ele.getAttribute('abjad');
                    if (ele.getAttribute('koreksi') == "true"){
                        grade = ele.getAttribute('bobot')/ele.getAttribute('jmlhbenar');

                        kirimJawaban(curr, data, end, type, answer, grade);
                    } else {
                        kirimJawaban(curr, data, end, type, answer, grade);
                    }
                }
            } else {
                var answer = jwb[j].value;

                kirimJawaban(curr, data, end, type, answer, grade);
            }
        }
    }

    const kirimJawaban = async (curr, data, end, type, answer, grade) => {
        let user = user_code.replaceAll("-","/");
        let parent = code_soal.replaceAll("-","/");
        let question = data[curr].code;

        axios.post(`https://api.alazhar2.com/node/student/answerQuestion`, {
            user,
            question,
            answer,
            grade,
            parent
        })
        .then((res) => {
            curr = curr+1;
            if (curr < end) {
                postAnswer(curr, data, end, type);
            } else {
                Swal.fire(
                    'Berhasil !',
                    'Jawaban telah berhasil dikirim.',
                    'success'
                )

                setDone(true);
                if (type == "Essay") {
                    setWait(true);
                } else {
                    getCorrectOpt();
                }
            }
        })        
    }

    const submitKoreksi = () => {
        let ele = document.getElementsByName("nilaiguru");
        let sum = 0;
        for(let x = 0; x < ele.length; x++) {
            let val = ele[x].value;
            if (val == '') {
                Swal.fire(
                    'Gagal !',
                    'Mohon Lengkapi Semua Penilaian.',
                    'error'
                )
                return;
            }
            sum = sum + parseInt(Math.round(val));
        }

        if (sum > 100) {
            Swal.fire(
                'Gagal !',
                'Total Penilaian Tidak Boleh Lebih dari 100.',
                'error'
            )
            return;
        }

        Swal.fire({
           title: 'Sudah Selesai Menilai ?',
           text: "Periksa kembali penilaian sebelum dikirim, tugas yang sudah dinilai tidak bisa dinilai kembali.",
           icon: 'warning',
           showCancelButton: true,
           confirmButtonText: 'Selesai',
           cancelButtonText: 'Batal',
           reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                postKoreksi(0, ele, ele.length);
            }
        });
    }

    const postKoreksi = async (curr, ele, end) => {
        let user = user_code.replaceAll("-","/");
        let val = ele[curr].value;
        if (val == '') {
            val = 0
        }
        let code = ele[curr].getAttribute("soal");

        axios.put(`https://api.alazhar2.com/node/teacher/gradingAnswer`, {
            code,
            grade: val,
            user
        })
        .then((res) => {
            curr = curr+1;
            if (curr < end) {
                postKoreksi(curr, ele, end);
            } else {
                Swal.fire(
                    'Berhasil !',
                    'Penilaian telah berhasil dikirim.',
                    'success'
                )

                setDone(true);
                closeKoreksi();
                getListMurid(kodekls);
            }
        })        
    }

    const getCorrectOpt = async (user = '') => {
        if (user == '') {
            user = user_code.replaceAll("-","/");
        }
        let parent = code_soal.replaceAll("-","/");

        const res = await axios.get('https://api.alazhar2.com/node/user/getAnswerQuestion?user='+user+'&parent='+parent);
        let rows = res.data.data;
        
        if (rows.length > 0) {
            setDone(true);
            setCorrAns(rows);
            
            let point = 0;
            rows.forEach(item => {
                point = point + item.grade;
            });
            setNilai(point); 
        }
    }

    function getTrueAns(qsd, type) {
        let trueans = '';
        let codeans = '';
        corrans.forEach(item => {
            if (item.question_code == qsd){
                trueans = item.answer;
                codeans = item.code;
            }
        });

        if (type == 'answer') {
            return trueans;
        }
        else if (type == 'code') {
            return codeans;
        }
    }

    function getSum(user) {
        let parent = code_soal.replaceAll("-","/");
        axios.get('https://api.alazhar2.com/node/user/getAnswerQuestion?user='+user+'&parent='+parent)
        .then((res) => {
            let rows = res.data.data;
            let nilai = 0;
            rows.forEach(item => {
                nilai = nilai + item.grade;
            });
            setGrd(nilai);
        })
    }

    function tipePG(tipe) {
        if (tipe == "PG" || tipe == "Benar Salah") {
            return "radio";
        } else {
            return "checkbox";
        }
    }

    function validasiNilai(ele, grade, maxGrade) {
        if (grade > maxGrade) {
            Swal.fire(
                'Peringatan !',
                'Nilai maksimal soal ini adalah '+ maxGrade +' !',
                'warning'
            )
            ele.target.value = '';
            return;
        }
    }

    const resetAnswer = (murid) => {
        let question = code_soal.replaceAll("-","/");
        let teacher = user_code.replaceAll("-","/");
        Swal.fire({
           title: 'Yakin Ingin Mereset Jawaban ?',
           text: "Data Yang Dihapus Tidak Dapat Dikembalikan Lagi !",
           icon: 'warning',
           showCancelButton: true,
           confirmButtonText: 'Ok',
           cancelButtonText: 'Cancel',
           reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`https://api.alazhar2.com/node/teacher/resetAnswer?user=`+murid+`&question=`+question+`&teacher=`+teacher)
                .then((response) => {
                    Swal.fire(
                        'Berhasil !',
                        'Jawaban Siswa Berhasil di Reset',
                        'success'
                    )
                    getUser();
                })
            }
        });
     }

    return (
        <>
        {loading ? 
            <div className='loadingpage'>
               <div className="book">
                  <div className="book__pg-shadow"></div>
                  <div className="book__pg"></div>
                  <div className="book__pg book__pg--2"></div>
                  <div className="book__pg book__pg--3"></div>
                  <div className="book__pg book__pg--4"></div>
                  <div className="book__pg book__pg--5"></div>
               </div>
            </div> : ''
        }

        <div>
        {cekUser == 'guru' ? 
            <Row className='m-3'>
                <Form.Group>
                    <Form.Label>
                        <h3>KELAS :</h3>
                    </Form.Label>
                    <Form.Select style={{border: "1px solid black", color: "black"}} onChange={(e) => getListMurid(e.target.value)}>
                        {apiclass?.map((item) => (
                            <option key={item.code} value={item.class_code}>{item.name}</option>
                        ))}
                    </Form.Select>

                    {apimurid?.map((item,index) => (
                        item.status == 'Sudah Mengerjakan' ?
                            <div className="card my-3 ps-0 pe-0" key={item.code} style={{border: "1px solid green"}}>
                                {getSum(item.user_code)}
                                <div className='card-header bg-success p-3 d-flex'>
                                    <h5 className='text-white'>{item.status}</h5>
                                    <h5 className="ms-auto text-white me-2">NILAI : {grd}</h5>
                                    <div style={{cursor: "pointer"}} className='text-danger' title="Reset Jawaban" onClick={() => resetAnswer(item.user_code)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="25" fill="currentColor" stroke="currentColor">
                                            <path d="M21,11a1,1,0,0,0-1,1,8.05,8.05,0,1,1-2.22-5.5h-2.4a1,1,0,0,0,0,2h4.53a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4.77A10,10,0,1,0,22,12,1,1,0,0,0,21,11Z"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div className="card-body py-2" style={{display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer"}} onClick={() => modalKoreksi(item.user_code)}>
                                    <h3 className="card-title text-black">{index+1}. {item.first_name} {item.last_name}</h3>
                                    <svg  className='ms-auto' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="45" id="checkmark">
                                        <g data-name="Layer 2">
                                            <path fill="green" d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm4.3 7.61-4.57 6a1 1 0 0 1-.79.39 1 1 0 0 1-.79-.38l-2.44-3.11a1 1 0 0 1 1.58-1.23l1.63 2.08 3.78-5a1 1 0 1 1 1.6 1.22z" data-name="checkmark-circle-2"></path>
                                        </g>
                                    </svg>
                                </div>
                            </div> :
                            item.status == 'Belum Mengerjakan' ?
                                <div className="card my-3 ps-0 pe-0" key={item.code} style={{border: "1px solid grey"}}>
                                    <h5 className="card-header text-white bg-secondary p-3">{item.status}</h5>
                                    <div className="card-body py-2" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <h3 className="card-title text-black">{index+1}. {item.first_name} {item.last_name}</h3>
                                        <svg className='ms-auto' width="40" viewBox="0 0 24 24" fill="grey" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M16.6653 2.01034C18.1038 1.92043 19.5224 2.41991 20.5913 3.3989C21.5703 4.46779 22.0697 5.88633 21.9898 7.33483V16.6652C22.0797 18.1137 21.5703 19.5322 20.6013 20.6011C19.5323 21.5801 18.1038 22.0796 16.6653 21.9897H7.33487C5.88636 22.0796 4.46781 21.5801 3.39891 20.6011C2.41991 19.5322 1.92043 18.1137 2.01034 16.6652V7.33483C1.92043 5.88633 2.41991 4.46779 3.39891 3.3989C4.46781 2.41991 5.88636 1.92043 7.33487 2.01034H16.6653ZM10.9811 16.845L17.7042 10.102C18.3136 9.4826 18.3136 8.48364 17.7042 7.87427L16.4056 6.57561C15.7862 5.95625 14.7872 5.95625 14.1679 6.57561L13.4985 7.25491C13.3986 7.35481 13.3986 7.52463 13.4985 7.62453C13.4985 7.62453 15.0869 9.20289 15.1169 9.24285C15.2268 9.36273 15.2967 9.52256 15.2967 9.70238C15.2967 10.062 15.007 10.3617 14.6374 10.3617C14.4675 10.3617 14.3077 10.2918 14.1978 10.1819L12.5295 8.5236C12.4496 8.44368 12.3098 8.44368 12.2298 8.5236L7.46474 13.2887C7.13507 13.6183 6.94527 14.0579 6.93528 14.5274L6.87534 16.8949C6.87534 17.0248 6.9153 17.1447 7.00521 17.2346C7.09512 17.3245 7.21499 17.3744 7.34486 17.3744H9.69245C10.172 17.3744 10.6315 17.1846 10.9811 16.845Z" fill="currentColor"></path>
                                        </svg>
                                    </div>
                                </div> :
                                <div className="card my-3 ps-0 pe-0" key={item.code} style={{border: "1px solid red", cursor: "pointer"}} onClick={() => modalKoreksi(item.user_code)}>
                                    <h5 className="card-header text-white bg-danger p-3">{item.status}</h5>
                                    <div className="card-body py-2" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <h3 className="card-title text-black">{index+1}. {item.first_name} {item.last_name}</h3>
                                        <svg className='ms-auto' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="45" id="exclamation">
                                            <path fill="#d50000" d="M32,6A26,26,0,1,0,58,32,26,26,0,0,0,32,6Zm4,41H28V41.68h8Zm0-7.32H28V17h8Z" data-name="Layer 2"></path>
                                        </svg>
                                    </div>
                                </div>
                    ))}
                </Form.Group>
            </Row> :
            <div className='p-3'>
                <ol>
                    {apisoal?.map((item,index) => (
                        <Form.Group className='mt-3' key={item.code}>
                        {item.images == null ? <></> : 
                            <img src={"https://api.alazhar2.com/upload/question/"+item.question_code.replaceAll("/", "")+"/"+item.code.replaceAll("/", "")+".png"} width="150px" height="150px" className='mb-2 ms-2' />
                        }
                        <li style={{color: "black", fontWeight: "Bold"}}>
                            <div className='ps-2'>
                                <h6 style={{color: "black", fontWeight: "Bold"}}>{item.question} (Bobot : {item.weight} / Nilai : {(item.weight/item.weight_total)*100})</h6>
                                {item.detail.length > 0 ?
                                    item.detail?.map((item2,index2) => (
                                        doneanswer ? 
                                            getTrueAns(item.code, 'answer') != item2.key_answer ?
                                                <Form.Group className="m-1" key={item2.code}
                                                    style={{
                                                        color: `${item2.right_answer == "true" ? "#45B537" : "black"}`
                                                    }}
                                                >   
                                                    <input 
                                                        type={tipePG(item.type)} 
                                                        className='me-2' 
                                                        name={"right_answer"+index} 
                                                        koreksi={item2.right_answer} 
                                                        abjad={item2.key_answer}
                                                        bobot={(item.weight/item.weight_total)*100}
                                                        jmlhbenar={item.rightanswer}
                                                        disabled
                                                    />
                                                    <label>{item2.key_answer+". "+item2.value_answer}</label>
                                                </Form.Group> :
                                                <Form.Group className="m-1" key={item2.code}
                                                    style={{
                                                        color: `${item2.right_answer == "true" ? "#45B537" : "red"}`
                                                    }}
                                                >
                                                    <input 
                                                        type={tipePG(item.type)} 
                                                        className='me-2' 
                                                        name={"right_answer"+index} 
                                                        koreksi={item2.right_answer} 
                                                        abjad={item2.key_answer}
                                                        bobot={(item.weight/item.weight_total)*100}
                                                        jmlhbenar={item.rightanswer}
                                                        checked
                                                        disabled
                                                    />
                                                    <label>{item2.key_answer+". "+item2.value_answer}</label>
                                                </Form.Group> : 
                                            <Form.Group className="m-1" key={item2.code}>
                                                <input 
                                                    type={tipePG(item.type)} 
                                                    className='me-2' 
                                                    name={"right_answer"+index} 
                                                    koreksi={item2.right_answer} 
                                                    abjad={item2.key_answer}
                                                    bobot={(item.weight/item.weight_total)*100}
                                                    jmlhbenar={item.rightanswer}
                                                />
                                                <label>{item2.key_answer+". "+item2.value_answer}</label>
                                            </Form.Group>
                                    )) :
                                    doneanswer ?
                                    <Form.Control as="textarea" rows="1" className='mt-3' name={"right_answer"+index} value={getTrueAns(item.code, 'answer')} disabled/> :
                                    <Form.Control as="textarea" rows="1" className='mt-3' name={"right_answer"+index}/>
                                }                                
                            </div>
                        </li>
                        </Form.Group>
                    ))}
                </ol>

                {cekUser != 'murid' ? '' :
                    doneanswer ? 
                    <Alert key="light" variant="light" 
                        style={{
                            width: "fit-content", 
                            textAlign: "center", 
                            position: "fixed",
                            top: "3px",
                            right: "5px",
                            borderRadius: "15px"
                        }}
                    >   
                        {waitcorrect ? 
                            <>
                            <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 64 64" width="50" id="checkout-time">
                                <circle cx="29" cy="29" r="27" fill="#e3fcfd"></circle>
                                <path fill="#42eaf5" d="M51.88346,42.34912C56.73515,39.98637,57.8,32,57.8,32H40.2s1.06485,7.98637,5.91654,10.34912a.7248.7248,0,0,1,0,1.30176C41.26485,46.01363,40.2,54,40.2,54H57.8s-1.06485-7.98637-5.91654-10.34912A.7248.7248,0,0,1,51.88346,42.34912Z"></path>
                                <rect width="26" height="8" x="36" y="24" fill="#e3fcfd" rx="2"></rect>
                                <rect width="26" height="8" x="36" y="54" fill="#e3fcfd" rx="2"></rect>
                                <path fill="#007da1" d="M59.44334,34a4.07713,4.07713,0,0,0,4.55658-4V26a4.00427,4.00427,0,0,0-4-4H38a4.00427,4.00427,0,0,0-4,4v4a4.07716,4.07716,0,0,0,4.55664,4c.55591,2.34955,1.91406,6.51984,4.91308,9-2.999,2.47986-4.35723,6.65033-4.91314,9a4.231,4.231,0,0,0-3.43084,1.22986A24.767,24.767,0,0,1,29,54,25,25,0,1,1,51.82317,18.814a2.00025,2.00025,0,0,0,3.6543-1.62792A29.03742,29.03742,0,1,0,34,57.555V60a4.00427,4.00427,0,0,0,4,4h22a4.00427,4.00427,0,0,0,4-4V56a4.07714,4.07714,0,0,0-4.55658-4c-.55591-2.34955-1.91412-6.51984-4.91314-9C57.52922,40.52014,58.88743,36.34967,59.44334,34ZM38,26h22l.00293,4H38Zm22.0029,34H38V56h22Zm-4.68359-8H42.68158c.67676-2.35937,2.03027-5.44043,4.30957-6.55078a2.72437,2.72437,0,0,0,.001-4.89795C44.71088,39.44043,43.35834,36.35791,42.68061,34H55.31829c-.67676,2.35889-2.0293,5.43994-4.30957,6.55078a2.72437,2.72437,0,0,0-.001,4.89795C53.289,46.55957,54.64153,49.64209,55.31926,52Z"></path>
                                <path fill="#007da1" d="M31,13a2,2,0,0,0-4,0V27H20a2,2,0,0,0,0,4h9a2.0001,2.0001,0,0,0,2-2Z"></path>
                                <circle cx="10" cy="29" r="2" fill="#007da1"></circle>
                                <circle cx="29" cy="48" r="2" fill="#007da1"></circle>
                            </svg>
                            <h5 className='mt-3'>Soal Sedang <br /> Diperiksa</h5>
                            </> :
                            <>
                            <h4>NILAI :</h4>
                            <span style={{fontSize: "50px"}}>{nilai}</span>
                            </>
                        }
                    </Alert> :
                    <Form.Group className='text-center'>
                        <hr />
                        <Button variant='success' title="Simpan" onClick={() => submitAnswer()}>
                            SIMPAN
                        </Button>   
                    </Form.Group>
                } 
            </div>
        }
        </div>

        <Modal show={koreksi} fullscreen={true} onHide={closeKoreksi}>
            <Modal.Header closeButton>
               <Modal.Title>Koreksi Tugas Siswa</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='p-3'>
                    <ol>
                        {apisoal?.map((item,index) => (
                            <Form.Group className='mt-3' key={item.code}>
                            <li style={{color: "black", fontWeight: "Bold"}}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <h6 style={{color: "black", fontWeight: "Bold", lineHeight: "25px"}}>{item.question} <br /> (Bobot : {item.weight} / Nilai : {(item.weight/item.weight_total)*100})</h6>
                                    {typesoal != "Essay" || nilai > 0 ? '' :
                                        <Form.Control 
                                            type="number"
                                            placeholder='Nilai'
                                            className='ms-3'
                                            style={{width: "100px"}}
                                            name={"nilaiguru"}
                                            soal={getTrueAns(item.code, 'code')}
                                            onChange={(e) => validasiNilai(e, e.target.value, ((item.weight/item.weight_total)*100))}
                                        />
                                    }
                                </div>
                                {item.detail.length > 0 ?
                                    item.detail?.map((item2,index2) => (
                                        getTrueAns(item.code, 'answer') != item2.key_answer ?
                                            <Form.Group className="m-1" key={item2.code}
                                                style={{
                                                    color: `${item2.right_answer == "true" ? "#45B537" : "black"}`
                                                }}
                                            >   
                                                <input 
                                                    type={tipePG(item.type)} 
                                                    className='me-2' 
                                                    name={"right_answer"+index} 
                                                    koreksi={item2.right_answer} 
                                                    abjad={item2.key_answer}
                                                    disabled
                                                />
                                                <label>{item2.key_answer+". "+item2.value_answer}</label>
                                            </Form.Group> :
                                            <Form.Group className="m-1" key={item2.code}
                                                style={{
                                                    color: `${item2.right_answer == "true" ? "#45B537" : "red"}`
                                                }}
                                            >
                                                <input 
                                                    type={tipePG(item.type)} 
                                                    className='me-2' 
                                                    name={"right_answer"+index} 
                                                    koreksi={item2.right_answer} 
                                                    abjad={item2.key_answer}
                                                    checked
                                                    disabled
                                                />
                                                <label>{item2.key_answer+". "+item2.value_answer}</label>
                                            </Form.Group>
                                    )) :
                                    <Form.Control as="textarea" rows="1" className='mt-3' name={"right_answer"+index} value={getTrueAns(item.code, 'answer')} disabled/> 
                                }
                            </li>
                            </Form.Group>
                        ))}
                    </ol>
                    
                    <div style={{
                        textAlign: "center",
                        width: "100%"
                    }}>
                        {typesoal == "PG" || nilai > 0 ?
                            <Alert key="light" variant="light" 
                                style={{
                                    width: "fit-content", 
                                    textAlign: "center", 
                                    position: "fixed",
                                    top: "75px",
                                    right: "5px",
                                    borderRadius: "15px"
                                }}
                            >   
                                <h4>NILAI :</h4>
                                <span style={{fontSize: "50px"}}>{nilai}</span>
                            </Alert> :
                            <Form.Group>
                                <hr />
                                <Button variant='success' onClick={submitKoreksi}>
                                    Simpan
                                </Button>
                            </Form.Group>
                        }
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        </>
    )
}

export default QuestionPage

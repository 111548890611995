import React, {useEffect, useState, useContext} from 'react'
import {Row,Col,Image,Form,Button,ListGroup,} from 'react-bootstrap'
import {Link,useHistory} from 'react-router-dom'
import Card from '../../../components/Card'
import logo from '../../../assets/images/Logo-6.png'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import Swal from 'sweetalert2'
import AuthContext from "../../../context/AuthContext"

const SignIn = () => {
   //define state
   const [username, setUsername] = useState("");
   const [password, setPassword] = useState("");

   //define state validation
   const [validation, setValidation] = useState([]);

   //define history
   const history = useHistory();
   var token = localStorage.getItem("token");

   //hook useEffect
   useEffect(() => {
      if (!token) {
         token = localStorage.setItem('token', 'initoken');
      }

      // alert('i '+token);
      //check token
      if(localStorage.getItem('token') !== 'initoken') {

         //redirect page dashboard
         history.push('/dashboard');
      }
   }, []);
   
   const { login } = useContext(AuthContext);

   //function "loginHanlder"
   const loginHandler = async (e) => {
      e.preventDefault();
      
      //initialize formData
      const formData = new FormData();

      //append data to formData
      formData.append('username', username);
      formData.append('password', password);

      //send data to server
      const response = await axios.post('https://api.alazhar2.com/node/auth/login', formData);
      if (response.data.error == false) {
         const token = response.data.data[0].token;
         const decode = jwtDecode(token);

         if (decode.role == 'admin' || decode.role == 'guru' || decode.role == 'murid') {
            //set token on localStorage
            localStorage.setItem('token', token);
            
            if (decode.role == 'murid') {
               history.push('/dashboard/ebook/ebook');
            } else {
               //redirect to dashboard
               history.push('/dashboard');
            }
            // login();
         } else {
            loginFailed();
         }
      } else {
         // setValidation(response.data);
         loginFailed(response.data.response);
      }
   };

   const loginFailed = (message = '') => {
      if (message == '') {
         message = "Only Admin Can Access This Website"
      }
      Swal.fire(
         'Failed !',
         message,
         'error'
      )
   }

   return (
      <>
         <section className='container-fluid'>
            <Row className="d-flex justify-content-center align-items-center h-100 mx-auto" style={{maxWidth:'600px'}}>            
               <Col md="12">
                  <Card className="card-transparent shadow-lg my-5 mx-auto">
                     <Card.Body className='p-5 d-flex flex-column align-items-center mx-auto w-100'>
                        <h1 className="mb-4 fw-bold">
                           <img src={logo} width="80" height="80" className='mr-3' /> 
                           Al - Azhar 
                        </h1>
                        <hr/>
                        {
                           validation.response && (
                              <div className="alert alert-bottom alert-danger alert-dismissible fade show w-100" role="alert">
                                 <span> {validation.response}</span>
                                 <button type="button" className="btn-close btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                              </div>
                           )
                        }
                        <Form onSubmit={loginHandler}>
                           <Row>
                              <Col lg="12">
                                 <Form.Group className="form-group">
                                    <Form.Label htmlFor="username" className="">Username</Form.Label>
                                    <Form.Control required type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Masukkan Username"/>
                                 </Form.Group >
                              </Col>
                              <Col lg="12" className="mb-4">
                                 <Form.Group className="form-group">
                                    <Form.Label htmlFor="password" className="">Password</Form.Label>
                                    <Form.Control required type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Masukkan Password"/>
                                 </Form.Group>
                              </Col>
                              <Col lg="12">
                                 <Button className='w-100' type="submit" variant="btn btn-primary">LOGIN</Button>
                              </Col>
                           </Row>
                           {/* <div className="d-flex justify-content-center">
                              <Button  onClick={() => history.push('/dashboard')} type="button" variant="btn btn-primary">Login </Button>
                           </div> */}
                        </Form>
                     </Card.Body>
                  </Card>
               </Col>
            </Row>
         </section>
        </>
    )
}

export default SignIn

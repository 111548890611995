import React,{useState,useEffect} from 'react'
import {Row,Col,Modal,Form,Button,Table} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import axios from 'axios'
import {bindActionCreators} from "redux"
import {useHistory} from 'react-router-dom'

//circular
import Circularprogressbar from '../../components/circularprogressbar.js'

// AOS
import AOS from 'aos'
import '../../../node_modules/aos/dist/aos'
import '../../../node_modules/aos/dist/aos.css'
//apexcharts
import Chart from "react-apexcharts";

//swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/components/navigation/navigation.scss';

//progressbar
import Progress from '../../components/progress.js'
//img
import shapes1 from '../../assets/images/shapes/01.png'
import shapes2 from '../../assets/images/shapes/02.png'
import shapes3 from '../../assets/images/shapes/03.png'
import shapes4 from '../../assets/images/shapes/04.png'
import shapes5 from '../../assets/images/shapes/05.png'

//Count-up
import CountUp from 'react-countup';
// store
import {NavbarstyleAction, getDirMode, getcustomizerMode, getcustomizerprimaryMode,  getcustomizerinfoMode,  SchemeDirAction, ColorCustomizerAction,  getNavbarStyleMode, getSidebarActiveMode, SidebarActiveStyleAction, getDarkMode, ModeAction,  SidebarColorAction, getSidebarColorMode, getSidebarTypeMode} from '../../store/setting/setting'
import {connect} from "react-redux"

  
// install Swiper modules
SwiperCore.use([Navigation]);

const mapStateToProps = (state) => {
    return {
        darkMode: getDarkMode(state),
        customizerMode: getcustomizerMode(state),
        cololrinfomode: getcustomizerinfoMode(state),
        colorprimarymode: getcustomizerprimaryMode(state),
        schemeDirMode: getDirMode(state),
        sidebarcolorMode: getSidebarColorMode(state),
        sidebarTypeMode: getSidebarTypeMode(state),
        sidebaractivestyleMode: getSidebarActiveMode(state),
        navbarstylemode: getNavbarStyleMode(state),
    };
}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            ModeAction,
            SchemeDirAction,
            SidebarColorAction,
            SidebarActiveStyleAction,
            NavbarstyleAction,
            ColorCustomizerAction,
        },
        dispatch
    )
})

const Index = (props) => {
    const token = localStorage.getItem("token");
    //define history
    const history = useHistory();
    useEffect(() => {

        if(!token || token == 'initoken') {
            //redirect login page
            history.push('/');
        } else {
            getData();
        }

        AOS.init({
            startEvent: 'DOMContentLoaded',
            disable:  function() {
            var maxWidth = 996;
            return window.innerWidth < maxWidth;
            },
            throttleDelay: 10,
            once: true,
            duration: 700,
            offset: 10
        });
        
        //   customizer
        const colorcustomizerMode = sessionStorage.getItem('color-customizer-mode');
        const colorcustomizerinfoMode = sessionStorage.getItem('colorcustominfo-mode');
        const colorcustomizerprimaryMode = sessionStorage.getItem('colorcustomprimary-mode');
        if(colorcustomizerMode===null){
            props.ColorCustomizerAction(props.customizerMode, props.cololrinfomode, props.colorprimarymode);
            document.documentElement.style.setProperty('--bs-info', props.cololrinfomode );
        }
        else{
            props.ColorCustomizerAction(colorcustomizerMode, colorcustomizerinfoMode, colorcustomizerprimaryMode);
            document.documentElement.style.setProperty('--bs-info', colorcustomizerinfoMode);
        }
    }, []);

    const [APIData, setAPIData] = useState([]); 
    const [kelas, setKelas] = useState(0);
    const [guru, setGuru] = useState(0);
    const [murid, setMurid] = useState(0);

    // Get Data
    const getData = async (e) => {
        const res = await axios.get('https://api.alazhar2.com/node/tools/log', { headers: {"Authorization" : `Bearer ${token}`}});
        setAPIData(res.data.data);

        const resKls = await axios.get('https://api.alazhar2.com/node/admin/class', { headers: {"Authorization" : `Bearer ${token}`}});
        setKelas(resKls.data.data.length);

        const resGuru = await axios.get('https://api.alazhar2.com/node/admin/user?role=guru', { headers: {"Authorization" : `Bearer ${token}`}});
        setGuru(resGuru.data.data.length);

        const resMurid = await axios.get('https://api.alazhar2.com/node/admin/user?role=murid', { headers: {"Authorization" : `Bearer ${token}`}});
        setMurid(resMurid.data.data.length);
    }

    const getTopLogs = (data) => {
        let content = [];
        for (let i = 0; i < 5; i++) {
            const item = data[i];
            content.push(<div className="mb-2 d-flex profile-media align-items-top"><div className="mt-1 profile-dots-pills border-primary"></div><div className="ms-4" key={item.code}><h6 className="mb-1">{item.action} {item.modul}</h6><span className="mb-0">{item.createdtime}</span></div></div>);
        }
        return content;
    };
    
    return (
        <>
            <Row>
                <Col md="12" lg="12">
                    <Row className="row-cols-1">
                        <div className="overflow-hidden d-flex ">
                            {/* <Swiper className="p-0 m-0 mb-2 list-inline "
                                slidesPerView={3}
                                spaceBetween={32}
                                navigation={{
                                    nextEl: '.swiper-button-next',
                                    prevEl: '.swiper-button-prev'
                                }}
                                data-aos="fade-up" data-aos-delay="700"
                            > */}
                                <Col md="4" className="card card-slide me-2" >
                                    <div className="card-body">
                                        <div className="progress-widget" >
                                            <Circularprogressbar stroke={props.colorprimarymode} width="60px" height="60px" Linecap='rounded' trailstroke='#ddd' strokewidth="4px" style={{width:60, height:60,}} value={100} id="circle-progress-01" >
                                                <h3>K</h3>
                                            </Circularprogressbar>
                                            <div className="progress-detail">
                                                <h2 className="counter"><CountUp start={0} end={kelas} duration={3}/></h2>
                                                <p  className="mb-2">Total Kelas</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="4" className="card card-slide me-2" >
                                    <div className="card-body">
                                        <div className="progress-widget" >
                                            <Circularprogressbar stroke={props.colorprimarymode} width="60px" height="60px" Linecap='rounded' trailstroke='#ddd' strokewidth="4px" style={{width:60, height:60,}} value={100} id="circle-progress-01" >
                                                <h3>G</h3>
                                            </Circularprogressbar>
                                            <div className="progress-detail">
                                                <h2 className="counter"><CountUp start={0} end={guru} duration={3}/></h2>
                                                <p  className="mb-2">Total Guru</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="4" className="card card-slide me-2" >
                                    <div className="card-body">
                                        <div className="progress-widget" >
                                            <Circularprogressbar stroke={props.colorprimarymode} width="60px" height="60px" Linecap='rounded' trailstroke='#ddd' strokewidth="4px" style={{width:60, height:60,}} value={100} id="circle-progress-01" >
                                                <h3>S</h3>
                                            </Circularprogressbar>
                                            <div className="progress-detail">
                                                <h2 className="counter"><CountUp start={0} end={murid} duration={3}/></h2>
                                                <p  className="mb-2">Total Siswa</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                {/* <div className="swiper-button swiper-button-next"></div>
                                <div className="swiper-button swiper-button-prev"></div> */}
                            {/* </Swiper> */}
                        </div>
                    </Row>
                </Col>
                
                <Col md="12">
                    <div className="card" data-aos="fade-up" data-aos-delay="600">
                        <div className="flex-wrap card-header d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="mb-2 card-title">Last & Updates</h4>
                            </div>
                        </div>
                        <div className="card-body">
                            {APIData?.map((item,index) => (
                                <>
                                {index < 5 ? <>
                                    <div className="mb-2 d-flex profile-media align-items-top">
                                        <div className="mt-1 profile-dots-pills border-primary"></div>
                                        <div className="ms-4" key={item.code}>
                                            <h6 className="mb-1">{item.action} {item.modul.replace("|", " - ")}</h6>
                                            <span className="mb-0">{item.createdtime}</span>
                                        </div>
                                    </div></> : 
                                <></>}
                                </>
                            ))}             
                        </div>
                    </div>
                </Col>
                    
            </Row>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
